import {
    AddCircleOutline,
    ArrowBack,
    ArrowDownward,
    ArrowUpward,
    CalendarMonth,
    Campaign,
    Close,
    Delete,
    Email,
    ImageOutlined,
    InfoOutlined,
    LocalOffer,
    LocationOn,
    Loop,
    MonetizationOn,
    NoteAlt,
    Payments,
    RemoveCircleOutline,
    ShoppingCart,
    Star,
    Store,
    Verified,
    WarningAmber,
} from "@mui/icons-material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Tab, Tabs as MaterialTabs, Checkbox, IconButton, Accordion, AccordionSummary, AccordionDetails, TextField, Tooltip, Tabs, Box, Button as MUIButton } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import { axiosPrivate } from "../../apis/axios"
import { Avatar, Modal } from "../components"
import CloseButton from "../Modal/CloseButton/CloseButton"
import { Map } from "../Map/Map"
import { Button, DetailedView, Input } from "../components"
import styles from "./Profile.module.scss"
import AdvancedDropDown from "../AdvancedDropDown/AdvancedDropDown"
import { Truncate } from "../../utils/Validation"
import { isActive } from "../../utils/permissions"
import { AvatarLabel } from "../Table/components/AvatarLabel/AvatarLabel"
import placeholderImage from "../../images/pet_placeholder.png"
import { shortDate } from "../../utils/DateFormatter"
import axios from "../../apis/axios"
import SlotSelector from "../SlotSelector/SlotSelector"
import moment from "moment"
import CustomerInvoiceList from "../ExtModals/CustomerInvoiceList"
import { formatToLocal, getDayName } from "../../utils/TimeHandler"
import { sendAppointmentStaticMessage, sendOutNotification } from "../../utils/Messaging"
import { createEvent } from "ics"
import ValidatePermission from "../ValidatePermission/ValidatePermission"
import useAuth from "../../hooks/useAuth"
import { validatePermission } from "../../utils/validatePermission"
import PetAlerts from "../ExtModals/PetAlerts"
import CustomerAlerts from "../ExtModals/CustomerAlerts"
import { ImgComparisonSlider } from "@img-comparison-slider/react"
import ViewGroomerNote from "../ExtModals/ViewGroomerNote"

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <p>{children}</p>}
        </div>
    )
}

export const ProfileAppointment = (props: {
    goBack?: CallableFunction
    servicePetsAll?
    type
    id
    petList?
    customerBooking?
    removeFromList?: CallableFunction
    allSlots?: CallableFunction
    pastSelectedAppointment?
    pastAppList?
    currentLoc?
}) => {
    const [currDate, setCurrDate] = useState<any>(moment().format("MM/DD/YYYY"))
    const [openModal, setOpenModal] = useState(false)
    const [lng, setLng] = useState<any>("")
    const [lat, setLat] = useState<any>("")
    const [locName, setLocName] = useState("")
    const [customerAddress, setCustomerAddress] = useState<any>([])
    const [selectedLocation, setSelectedLocation] = useState()
    const [store, setStores] = useState<any>([])
    const [storeDisplay, setStoresDisplay] = useState<any>([])
    const [currentLocationIndex, setCurrentLocationIndex] = useState<any>("")
    const [timeOfDayList, setTimeOfDayList] = useState<any>([])
    const [timeOfDay, setTimeOfDay] = useState<any>(9)
    const myRef = useRef<any>()
    const myRefTimeOFDay = useRef<any>()
    const [bookingLocation, setBookingLocation] = useState<any>("")
    const [slotStatus, setSlotStatus] = useState<any>("")

    const [groomerNotes, setGroomerNotes] = useState<any>([])

    const { auth } = useAuth() as any

    const [bookingTime, setBookingTime] = useState<any>("")
    const [bookingDate, setBookingDate] = useState<any>(moment().format("YYYY-MM-DD"))

    // Services States
    const [activeServicePet, setActiveServicePet] = useState<any>(0)
    const [activeProductPet, setActiveProductPet] = useState<any>(0)
    const [mainServiceList, setMainServiceList] = useState<any>([])
    const [additionalServiceList, setAdditionalServiceList] = useState<any>([])
    const [selectedAdditionalService, setSelectedAdditionalService] = useState<any>("")
    const [servicePets, setServicePets] = useState<any>([])

    /* Summary States */
    const [mainName, setMainName] = useState<any>("Main Service")
    const [mainPrice, setMainPrice] = useState<any>(0)
    const [additionalPrice, setAdditionalPrice] = useState<any>(0)
    const [beforeTax, setBeforeTax] = useState<any>(0)
    const [tax, setTax] = useState<any>(0)
    const [productTabIndex, setProductTabIndex] = useState(1)
    const [productTabIndexNoPet, setProductTabIndexNoPet] = useState(1)
    const [prodBeforeTax, setProdBeforeTax] = useState<any>(0)
    const [prodTax, setProdTax] = useState<any>(0)
    const [expanded, setExpanded] = useState<string | true>("panel1")
    const [expanded1, setExpanded1] = useState<string | true>("panel2")
    const [productList, setProductList] = useState<any>([])
    const [cartProductList, setCartProductList] = useState<any>([])
    const [productCartNoPetList, setProductCartNoPetList] = useState<any>([])
    const [productCartAmount, setProductCartAmount] = useState<any>(0)
    const [cartItemCount, setCartItemCount] = useState<any>(0)
    const [isOpenInvoiceModal, setIsOpenInvoiceModal] = useState<any>(false)
    const [invoiceServiceList, setInvoiceServiceList] = useState<any>([])
    const [invoiceProductList, setInvoiceProductList] = useState<any>([])
    const [isOpenAddProductNoPet, setIsOpenAddProductNoPet] = useState<any>(false)
    const [isOpenCustomerInvoicesDevModal, setIsOpenCustomerInvoicesDevModal] = useState(false)
    const [value, setValue] = useState(0)
    const [schvalue, setSchValue] = useState(0)
    const [list, setList] = useState(null) as any
    const [newHeaders, setNewHeaders] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [boarding, setBoarding] = useState(false)
    const [grooming, setGrooming] = useState(true)
    const [daycare, setDaycare] = useState(false)
    const [isTypeable, setIsTypeable] = useState(false)

    const [isOpenAddServices, setIsOpenAddServices] = useState(false)
    const [isOpenAddProduct, setIsOpenAddProduct] = useState(false)
    const [isOpenAddNotes, setIsOpenAddNotes] = useState(false)

    const [isOpenGroomerNote, setIsOpenGroomerNote] = useState(false)

    //Reservation States
    const [reservationPet, setReservationPet] = useState<any>("")
    const reservationPetRef = useRef<any>()

    const [slotObj, setSlotObj] = useState([]) as any
    const [selectedSlot, setSelectedSlot] = useState(0)

    const [isInvAvailable, setIsInvAvailable] = useState<any>(false)
    const [customerInvoiceID, setCustomerInvoiceID] = useState([]) as any
    const [filterText, setFilterText] = useState([]) as any

    const [slotEmployeeList, setSlotEmployeeList] = useState([]) as any
    const [selectedDate, setSelectedDate] = useState({ empIndex: "", dateIndex: "" }) as any
    const [slotMsg, setSlotMsg] = useState<any>("")
    const [isOpenSlotPicker, setIsOpenSlotPicker] = useState(false)
    const [resPetIndex, setResPetIndex] = useState<any>("")
    const [pastAppointmentList, setPastAppointmentList] = useState([]) as any
    const [selectedPastAppointment, setSelectedPastAppointment] = useState("") as any

    const [isOpenPetAlertsModal, setIsOpenPetAlertsModal] = useState(false)
    const [isOpenAppointmentPopup, setIsOpenAppointmentPopup] = useState(false)

    const [petPer, setPetPer] = useState<any>([])
    const [isOpenAlertsModal, setIsAlertsModal] = useState(false)

    const openGroomerNote = (notes) => {
        if (notes.length > 0) {
            setGroomerNotes(notes)
            setIsOpenGroomerNote(true)
        } else {
            toast.info("No groomer note added for the selected appointment")
        }
    }

    const [appointmentICal, setAppointmentICal] = useState({
        title: "",
        description: "",
        startDate: new Date(), // Initialize with current date and time
        endDate: new Date(), // Initialize with current date and time
    })

    useEffect(() => {
        getLocations()
            .then((res) => {
                const allLocations = [...store]
                res.map((location) => {
                    allLocations.push({
                        longitude: parseFloat(location.varLocationLongitude),
                        latitude: parseFloat(location.varLocationLatitude),
                        title: location.varLocationName,
                        distance: "",
                        duration: "",
                        value: 0,
                        img: location.varLocationImagePath,
                        id: location.numLocationID,
                        email: location.varLocationEmail,
                    })
                })
                setStores(allLocations)
                setIsLoading(false)
            })
            .catch((err) => {
                console.log(err)
            })

        getAllScheduleTimeOfDay()
            .then((response) => {
                setTimeOfDayList(response)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrongT!")
            })
        setSchValue(2)
    }, [])

    const searchLatAndLngByStreet = (street) => {
        const geocoder = new google.maps.Geocoder()
        geocoder.geocode({ address: street }, (res: any, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
                setLat(JSON.stringify(res[0]?.geometry.location.lat()))
                setLng(JSON.stringify(res[0]?.geometry.location.lng()))
                getDistance(JSON.stringify(res[0]?.geometry.location.lat()), JSON.stringify(res[0]?.geometry.location.lng()))
            }
        })
    }
    async function getLocations() {
        setIsLoading(true)
        const result = await axiosPrivate.get(`Location/GetAll`, {})
        return result.data.location
    }

    async function getAllScheduleTimeOfDay() {
        setIsLoading(true)
        const result = await axiosPrivate.get(`TimeOfDay/GetAll`, {})
        return result.data.timeOfDay
    }

    function checkCustomerAlerts(cusData) {
        const data = cusData
        const {
            bitCustomerAlertAlwaysLate,
            bitCustomerAlertDifficultToHandle,
            bitCustomerAlertDiscountOnFile,
            bitCustomerAlertNoShow,
            bitCustomerAlertOutstandingBill,
            bitCustomerAlertVerbalConfirmationNeeded,
            varCustomerComments,
        } = data

        const anyAlertsTrue =
            bitCustomerAlertAlwaysLate ||
            bitCustomerAlertDifficultToHandle ||
            bitCustomerAlertDiscountOnFile ||
            bitCustomerAlertNoShow ||
            bitCustomerAlertOutstandingBill ||
            bitCustomerAlertVerbalConfirmationNeeded

        const commentsNotNull = varCustomerComments !== null

        // Simplified condition logic
        if (anyAlertsTrue || commentsNotNull) {
            return true
        }

        return false
    }

    useEffect(() => {
        setReservationPet("")
        reservationPetRef.current?.handleClear()
        setServicePets([])
        if (props.customerBooking !== undefined) {
            setIsAlertsModal(checkCustomerAlerts(props?.customerBooking))
        }
    }, [props.id])

    function getDistance(lati, long) {
        var address = new google.maps.LatLng(lati, long)
        let arr: any = []

        store.map((location, index) => {
            if (!isNaN(location?.latitude) && !isNaN(location?.longitude)) {
                var locAddress = new google.maps.LatLng(location?.latitude, location?.longitude)
                arr.push(locAddress)
            }
        })

        let distanceService = new google.maps.DistanceMatrixService()
        distanceService.getDistanceMatrix(
            {
                origins: [address],
                destinations: arr,
                travelMode: google.maps.TravelMode.DRIVING,
                unitSystem: google.maps.UnitSystem.METRIC,
                drivingOptions: {
                    departureTime: new Date(),
                    trafficModel: google.maps.TrafficModel.BEST_GUESS,
                },
                avoidHighways: false,
                avoidTolls: false,
            },
            function (response, status) {
                if (status !== google.maps.DistanceMatrixStatus.OK) {
                    console.log("Error:", status)
                } else {
                    response?.rows[0].elements.map((item, index) => {
                        const editLoc = [...store]
                        let miles = item?.distance.value * 0.000621371192
                        let Display_distance = miles.toFixed(2) + " Miles"
                        editLoc[index].distance = Display_distance
                        editLoc[index].duration = item?.duration.text
                        editLoc[index].value = item?.distance.value
                        setStores(editLoc)
                    })
                }
            }
        )
    }
    const getDirections = (salon) => {
        let arr: any = []
        let obj: any = {}
        obj["lat"] = salon.latitude
        obj["lng"] = salon.longitude
        obj["title"] = salon.title
        obj["duration"] = salon.duration
        obj["distance"] = salon.distance
        obj["img"] = salon.img
        arr.push(obj)
        setSelectedLocation(arr)
    }

    const center = {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
        name: locName,
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    const handleServicesChange = (event: React.SyntheticEvent, newValue: number) => {
        setSchValue(newValue)
    }

    async function getList(objectName) {
        const result = await axiosPrivate.get(`${objectName}/GetByID/${props.id}`)
        return result
    }

    async function getUpdatedHeaders(objectName) {
        const result = await axiosPrivate.get(`Label/${objectName}`)
        return result
    }

    useEffect(() => {
        setIsLoading(true)
        if (props.type === "customer") {
            getList("Customer")
                .then((response) => {
                    console.log("setList", response?.data?.customer)
                    setList(response?.data?.customer)
                    /* Get Label Configs */
                    getUpdatedHeaders("Customer")
                        .then((responseCustomer) => {
                            let customerHeaders = responseCustomer?.data

                            getUpdatedHeaders("Pet")
                                .then((responsePet) => {
                                    const header = {
                                        customer: customerHeaders,
                                        pet: responsePet?.data,
                                    }
                                    setNewHeaders(header)
                                })
                                .catch((err) => {
                                    console.log(err)
                                    toast.error("Something went wrong!")
                                })
                        })
                        .catch((err) => {
                            console.log(err)
                            toast.error("Something went wrong!")
                        })
                    setIsLoading(false)
                })
                .catch((err) => {
                    console.log(err)
                    toast.error("Something went wrong!")
                    setIsLoading(false)
                })
        }

        if (props.type === "booking") {
            setList(props.customerBooking)
            console.log(
                "cussAd",
                props.customerBooking.customerAddress != undefined
                    ? props.customerBooking.customerAddress.length > 0
                        ? props.customerBooking.customerAddress[0].varCustomerAddress1 +
                          " " +
                          props.customerBooking.customerAddress[0].varCustomerCity +
                          ", " +
                          props.customerBooking.customerAddress[0].varCustomerState
                        : ""
                    : ""
            )
            setCustomerAddress(props.customerBooking.customerAddress)
            setLocName(
                props.customerBooking.customerAddress != undefined
                    ? props.customerBooking.customerAddress.length > 0
                        ? props.customerBooking.customerAddress[0].varCustomerAddress1 +
                          " " +
                          props.customerBooking.customerAddress[0].varCustomerCity +
                          ", " +
                          props.customerBooking.customerAddress[0].varCustomerState
                        : ""
                    : ""
            )
            searchLatAndLngByStreet(locName)

            /* Get Label Configs */
            getUpdatedHeaders("Customer")
                .then((responseCustomer) => {
                    let customerHeaders = responseCustomer?.data

                    getUpdatedHeaders("Pet")
                        .then((responsePet) => {
                            const header = {
                                customer: customerHeaders,
                                pet: responsePet?.data,
                            }

                            setNewHeaders(header)
                        })
                        .catch((err) => {
                            console.log(err)
                            toast.error("Something went wrong!")
                        })
                })
                .catch((err) => {
                    console.log(err)
                    toast.error("Something went wrong!")
                })

            setIsLoading(false)
        } else if (props.type === "petProfile") {
            getList("Pet")
                .then((response) => {
                    setList(response?.data?.pet)

                    /* Get Label Configs */
                    getUpdatedHeaders("Pet")
                        .then((response) => {
                            const header = {
                                pet: response?.data,
                            }

                            setNewHeaders(header)
                        })
                        .catch((err) => {
                            console.log(err)
                            toast.error("Something went wrong!")
                        })

                    setIsLoading(false)
                })
                .catch((err) => {
                    console.log(err)
                    toast.error("Something went wrong!")
                    setIsLoading(false)
                })
        } else if (props.type === "salon") {
            getList("Location")
                .then((response) => {
                    setList(response?.data?.location)

                    /* Get Label Configs */
                    getUpdatedHeaders("Location")
                        .then((response) => {
                            const header = {
                                salon: response?.data,
                            }

                            setNewHeaders(header)
                        })
                        .catch((err) => {
                            console.log(err)
                            toast.error("Something went wrong!")
                        })

                    setIsLoading(false)
                })
                .catch((err) => {
                    console.log(err)
                    toast.error("Something went wrong!")
                    setIsLoading(false)
                })
        } else if (props.type === "staff") {
            getList("Employee")
                .then((response) => {
                    setList(response?.data?.employee)

                    /* Get Label Configs */
                    getUpdatedHeaders("Employee")
                        .then((response) => {
                            const header = {
                                employee: response?.data,
                            }

                            setNewHeaders(header)
                        })
                        .catch((err) => {
                            console.log(err)
                            // toast.error("Something went wrong!");
                        })

                    setIsLoading(false)
                })
                .catch((err) => {
                    console.log(err)
                    toast.error("Something went wrong!")
                    setIsLoading(false)
                })
        }
        setIsTypeable(false)
    }, [props.id, props.customerBooking])

    const setLocationAsSelected = (loc) => {
        setLocName(loc)
    }
    const viewOnMap = () => {
        if (locName !== "") {
            if (isTypeable === true) {
                searchLatAndLngByStreet(locName)
            }
            setOpenModal(true)
        } else {
            toast.info("Please select or enter customers's address!")
        }
    }
    useEffect(() => {
        if (isTypeable === true) {
            setLocName("")
        } else {
            if (props.type === "booking") {
                setLocName(
                    props.customerBooking.customerAddress != undefined
                        ? props.customerBooking.customerAddress.length > 0
                            ? props.customerBooking.customerAddress[0].varCustomerAddress1 +
                              " " +
                              props.customerBooking.customerAddress[0].varCustomerCity +
                              ", " +
                              props.customerBooking.customerAddress[0].varCustomerState
                            : ""
                        : ""
                )
            }
        }
    }, [isTypeable])

    const handleEdit = () => {
        if (isTypeable === true) {
            setIsTypeable(false)
        } else {
            setIsTypeable(true)
        }
    }

    useEffect(() => {
        if (store.length > 0) {
            let list_without_current_location = store.filter((x) => x?.id !== parseInt(props.currentLoc))
            let current_location_index = store.findIndex((x) => x?.id === parseInt(props.currentLoc))
            setStoresDisplay(list_without_current_location)
            setCurrentLocationIndex(current_location_index)
        }

        if (!isNaN(props.currentLoc)) {
            setBookingLocation(parseInt(props.currentLoc))
            console.log("PList", props.petList)
        }
    }, [props.currentLoc])

    const fetch_directions = () => {
        if (locName != "") {
            searchLatAndLngByStreet(locName)
        } else {
            toast.info("Please select or enter customers's address!")
        }
    }

    useEffect(() => {
        if (isTypeable == false) {
            if (locName != "") {
                searchLatAndLngByStreet(locName)
            }
        }
    }, [locName])

    const sortLocationDropdown = (items) => {
        let current_location_index = store.findIndex((x) => x?.id === parseInt(props.currentLoc))
        const element = items.splice(current_location_index, 1)[0]
        items.splice(0, 0, element)
        return items
    }

    const BookingLocationSelect = (id) => {
        setBookingLocation(id)
    }

    const handleSelectLocation = () => {
        if (bookingLocation == "") {
            toast.info("Please select a location!")
        } else {
            setOpenModal(false)
        }
    }

    let tabIndex = -1

    //Services / Pet - Products / Products /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const getLocationServiceItemsPriceList = async (locationID, dateOfService, petBreedID, petSizeID, employeeID, employeeName, petID, petTypeID) => {
        const apiUrl = `${process.env.REACT_APP_API_URL}Payment/GetLocationServiceItemsPriceList`

        try {
            const response = await axios.get(apiUrl, {
                params: {
                    numLocationID: locationID,
                    dtDate_of_service: dateOfService,
                    numPetBreedID: petBreedID,
                    numPetSizeID: petSizeID,
                    numEmployeeID: employeeID,
                    varEmployeeName: employeeName,
                    numPetID: petID,
                    numPetTypeID: petTypeID,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    }

    const getPrimaryBreedByPetBreedID = async (breedID) => {
        const apiUrl = `${process.env.REACT_APP_API_URL}PetBreed/GetPrimaryBreedIDByPetBreedID/${breedID}`

        try {
            const response = await axios.get(apiUrl, {})
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    }

    const getLocationServiceSelectedItemsPriceList = async (locationID, dateOfService, petBreedID, petSizeID, employeeID, employeeName, petID, customerID, appointmentID, petType) => {
        console.log("service selected sent", locationID, dateOfService, petBreedID, petSizeID, employeeID, employeeName, petID, customerID, appointmentID, petType)
        const apiUrl = `${process.env.REACT_APP_API_URL}Payment/getLocationServiceSelectedItemsPriceList`

        try {
            const response = await axios.get(apiUrl, {
                params: {
                    numLocationID: locationID,
                    dtDate_of_service: dateOfService,
                    numPetBreedID: petBreedID,
                    numPetSizeID: petSizeID,
                    numEmployeeID: employeeID,
                    varEmployeeName: employeeName,
                    numPetID: petID,
                    numCustomerID: customerID,
                    numAppointmentID: appointmentID,
                    numPetTypeID: petType,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    }

    const getLocationProductItemsPriceList = async (locationID, dateOfService, employeeID, employeeName, petID) => {
        console.log("all product fetch - sent", locationID, dateOfService, employeeID, employeeName, petID)
        const apiUrl = `${process.env.REACT_APP_API_URL}Payment/GetLocationProductItemsPriceList`

        try {
            const response = await axios.get(apiUrl, {
                params: {
                    numLocationID: locationID,
                    dtDate_of_service: dateOfService,
                    numEmployeeID: employeeID,
                    varEmployeeName: employeeName,
                    numPetID: petID,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    }

    const getLocationProductSelectedItemsPriceList = async (locationID, dateOfService, employeeID, employeeName, petID, CustomerID, appointmentID) => {
        console.log("sent-selected - product", locationID, dateOfService, employeeID, employeeName, petID, CustomerID, appointmentID)
        const apiUrl = `${process.env.REACT_APP_API_URL}Payment/GetLocationProductSelectedItemsPriceList`

        try {
            const response = await axios.get(apiUrl, {
                params: {
                    numLocationID: locationID,
                    dtDate_of_service: dateOfService,
                    numEmployeeID: employeeID,
                    varEmployeeName: employeeName,
                    numPetID: petID,
                    numCustomerID: CustomerID,
                    numAppointmentID: appointmentID,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    }
    const GetAllInvoicesByCustomerID = async (petID, CustomerID) => {
        const apiUrl = `${process.env.REACT_APP_API_URL}Payment/GetAllInvoicesByCustomerID`

        try {
            const response = await axios.get(apiUrl, {
                params: {
                    numPetID: petID,
                    numCustomerID: CustomerID,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    }

    // On pet select load in selected services to display on new booking screen
    useEffect(() => {
        const fetchData = async () => {
            if (!props.petList || props.petList.length === 0) {
                return
            }
            console.log("prodData", cartProductList)
            const updatedPetList = await Promise.all(
                props.petList.map(async (pet) => {
                    const petCartProducts = cartProductList.filter((prod) => pet.id === prod.numPetID)

                    pet.selectedProducts = petCartProducts

                    try {
                        const data = await getLocationServiceSelectedItemsPriceList(
                            bookingLocation,
                            currDate,
                            pet.breedID,
                            pet.sizeID,
                            1,
                            "Ravin",
                            pet.id,
                            pet.customer.numCustomerID,
                            null,
                            pet.petType
                        )
                        console.log("serData", data)
                        data.forEach((service) => {
                            if (service.OrderSelected === true) {
                                const serviceToAdd = {
                                    id: service.numServiceItemID,
                                    name: service.varServiceItemName,
                                    price: service.numServiceItemSalesPrice,
                                    desc: service.varServiceItemDescription,
                                    orderID: service.numOrderID,
                                    taxAmount: 0,
                                    numLocationServiceProductItemID: service.numLocationServiceItemID,
                                    numSalesTaxPercentage: service.numSalesTaxPercentage,
                                }

                                const additionalServiceExist = pet.additionalService.some((x) => x.id === service.numServiceItemID)
                                if (!additionalServiceExist) {
                                    pet.additionalService.push(serviceToAdd)
                                }
                            }
                        })

                        return pet
                    } catch (error) {
                        console.log("error", error)
                        return pet
                    }
                })
            )

            if (updatedPetList.length > 0) {
                // getAppointmentsbyPetID(updatedPetList[0].id, 100, 1)
                //     .then((data) => {
                //         if (data.PastAppointmentData !== undefined) {
                //             console.log("res - pet-PastAppointmentData", data)
                //             setPastAppointmentList(data.PastAppointmentData)
                //             props.pastAppList(data.PastAppointmentData)
                //             setIsLoading(false)
                //         } else {
                //             setPastAppointmentList([])
                //             props.pastAppList()
                //             setIsLoading(false)
                //         }
                //     })
                //     .catch((error) => {
                //         console.log("error", error)
                //     })

                getAppointmentsbyPetID(updatedPetList[0].id, 100, 1)
                    .then((data) => {
                        if (data.PastAppointmentData !== undefined) {
                            console.log("res - pet-PastAppointmentData", data)

                            const removeDuplicatesAndSetState = (appointmentData) => {
                                const uniqueAppointments: any = []
                                const appointmentMap: any = {}

                                appointmentData.forEach((item) => {
                                    const { numAppointmentID, IsPreferred } = item

                                    if (appointmentMap[numAppointmentID]) {
                                        if (IsPreferred) {
                                            appointmentMap[numAppointmentID] = item
                                        }
                                    } else {
                                        appointmentMap[numAppointmentID] = item
                                    }
                                })

                                for (const key in appointmentMap) {
                                    if (Object.hasOwnProperty.call(appointmentMap, key)) {
                                        uniqueAppointments.push(appointmentMap[key])
                                    }
                                }
                                uniqueAppointments.sort((a, b) => b.IsPreferred - a.IsPreferred)

                                return uniqueAppointments
                            }

                            const uniquePastAppointments = removeDuplicatesAndSetState(data.PastAppointmentData)
                            console.log("uniquePastAppointments", uniquePastAppointments)
                            setPastAppointmentList(uniquePastAppointments)
                            props.pastAppList(uniquePastAppointments)
                            setIsLoading(false)
                        } else {
                            setPastAppointmentList([])
                            props.pastAppList([])
                            setIsLoading(false)
                        }
                    })
                    .catch((error) => {
                        console.log("error", error)
                    })

                setReservationPet(updatedPetList[0].id)
            }

            console.log("updated", updatedPetList)

            setServicePets(updatedPetList)
            props?.servicePetsAll(updatedPetList)
            setIsLoading(false)
        }

        fetchData()
    }, [props.petList])

    const getAppointmentsbyPetID = async (PetID, PageSize, PageNumber) => {
        const apiUrl = `${process.env.REACT_APP_API_URL}Appointment/GetAppointmentsbyPetID`

        try {
            const response = await axios.get(apiUrl, {
                params: {
                    PetID: PetID,
                    PageSize: PageSize,
                    PageNumber: PageNumber,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    }

    // On pet select load in selected products to display on new booking screen
    useEffect(() => {
        console.log("cuss change")
        if (props.id !== null && props.id !== undefined) {
            getLocationProductSelectedItemsPriceList(bookingLocation, currDate, 1, "Ravin", -1, props.id, null)
                .then((data) => {
                    console.log("SP-Prod-Cart- no pet onload", data)
                    setProductCartNoPetList(data)
                    setIsLoading(false)
                })
                .catch((error) => {
                    console.log("error", error)
                })

            getLocationProductSelectedItemsPriceList(bookingLocation, currDate, 1, "Ravin", null, props.id, null)
                .then((data) => {
                    setCartProductList(data)
                })
                .catch((error) => {
                    console.log("error", error)
                })

            GetAllInvoicesByCustomerID(0, props.id)
                .then((data) => {
                    console.log("customer inv", data)
                    if (data.length > 0) {
                        setIsInvAvailable(false)
                        setCustomerInvoiceID(data[0].numInvoiceID)
                    } else {
                        setIsInvAvailable(true)
                    }
                })
                .catch((error) => {
                    console.log("error", error)
                })
        }
    }, [props.id])

    //Services functions

    const openServicesModal = () => {
        setActiveServicePet(0)
        if (props.petList.length > 0) {
            setIsLoading(true)
            setIsOpenAddServices(true)
            if (props.petList.length > 0) {
                let servicePet = [...servicePets]
                servicePet[0].mainService.id = null
                servicePet[0].mainService.name = null
                servicePet[0].mainService.price = null
                servicePet[0].mainService.varServiceProductDescription = null
                servicePet[0].mainService.decUnit_Price = null
                servicePet[0].mainService.taxAmount = null
                servicePet[0].mainService.orderID = null
                servicePet[0].mainService.numLocationServiceProductItemID = null
                servicePet[0].mainService.numSalesTaxPercentage = null

                console.log("service-list-fetch- on modal open - sent", {
                    numLocationID: bookingLocation,
                    dtDate_of_service: currDate,
                    numPetBreedID: props.petList[0].breedID,
                    numPetSizeID: props.petList[0].sizeID,
                    numEmployeeID: 1,
                    varEmployeeName: "Ravin",
                    numPetID: props.petList[0].id,
                    numPetTypeID: props.petList[0].petType,
                })

                getLocationServiceItemsPriceList(bookingLocation, currDate, props.petList[0].breedID, props.petList[0].sizeID, 1, "Ravin", props.petList[0].id, props.petList[0].petType)
                    .then((data) => {
                        if (data.Services !== undefined) {
                            console.log("res - All services with SP-selected services", data)

                            const { additionalServiceList } = data.Services.reduce(
                                (lists, service) => {
                                    const existingIndex = lists.additionalServiceList.findIndex(
                                        (existingService) => existingService.id === service.numServiceItemID && existingService.orderID === service.numOrderID
                                    )

                                    if (existingIndex === -1) {
                                        lists.additionalServiceList.push({
                                            ...service,
                                            desc: service.varServiceItemDescription,
                                        })
                                    }

                                    return lists
                                },
                                { additionalServiceList: [] }
                            )

                            setAdditionalServiceList(additionalServiceList)

                            const updatedServicePets = [...servicePets]
                            //updatedServicePets[0].additionalService = [];

                            data.Services.forEach((service) => {
                                if (service.OrderSelected !== true) return

                                const updatedService = {
                                    id: service.numServiceItemID,
                                    name: service.varServiceItemName,
                                    price: service.numServiceItemSalesPrice,
                                    orderID: service.numOrderID,
                                    taxAmount: service.TaxAmount,
                                    numLocationServiceProductItemID: service.numLocationServiceItemID,
                                    numSalesTaxPercentage: service.numSalesTaxPercentage,
                                    numStorePromotionDiscount: service.numStorePromotionDiscount,
                                    numSeasonalPromotionDiscount: service.numSeasonalPromotionDiscount,
                                }

                                const existingAdditionalIndex = updatedServicePets[0].additionalService.findIndex(
                                    (existingService) => existingService.id === updatedService.id && existingService.orderID === updatedService.orderID
                                )

                                if (existingAdditionalIndex === -1) {
                                    updatedServicePets[0].additionalService.push({
                                        ...updatedService,
                                        desc: service.varServiceItemDescription,
                                    })
                                }
                            })

                            setServicePets(updatedServicePets)

                            calculateSummary(0)
                            setIsLoading(false)
                        } else {
                            setAdditionalServiceList([])
                            setIsLoading(false)
                        }
                    })
                    .catch((error) => {
                        console.log("error", error)
                    })
            }
        } else {
            toast.info("Please select pets from the side bar")
        }
    }

    const fetchServicePriceList = () => {
        setActiveServicePet(0)
        setIsLoading(true)
        if (props.petList.length > 0) {
            setIsOpenAddServices(true)
            if (props.petList.length > 0) {
                let servicePet = [...servicePets]
                servicePet[0].mainService.id = null
                servicePet[0].mainService.name = null
                servicePet[0].mainService.price = null
                servicePet[0].mainService.varServiceProductDescription = null
                servicePet[0].mainService.decUnit_Price = null
                servicePet[0].mainService.taxAmount = null
                servicePet[0].mainService.orderID = null
                servicePet[0].mainService.numLocationServiceProductItemID = null
                servicePet[0].mainService.numSalesTaxPercentage = null

                getLocationServiceItemsPriceList(bookingLocation, currDate, props.petList[0].breedID, props.petList[0].sizeID, 1, "Ravin", props.petList[0].id, props.petList[0].petType)
                    .then((data) => {
                        if (data.Services !== undefined) {
                            console.log("All services with SP-selected services", data.Services)

                            const { additionalServiceList } = data.Services.reduce(
                                (lists, service) => {
                                    const existingIndex = lists.additionalServiceList.findIndex(
                                        (existingService) => existingService.id === service.numServiceItemID && existingService.orderID === service.numOrderID
                                    )

                                    if (existingIndex === -1) {
                                        lists.additionalServiceList.push({
                                            ...service,
                                            desc: service.varServiceItemDescription,
                                        })
                                    }

                                    return lists
                                },
                                { additionalServiceList: [] }
                            )

                            setAdditionalServiceList(additionalServiceList)

                            const updatedServicePets = [...servicePets]
                            updatedServicePets[0].additionalService = []

                            data.Services.forEach((service) => {
                                if (service.OrderSelected !== true) return

                                const updatedService = {
                                    id: service.numServiceItemID,
                                    name: service.varServiceItemName,
                                    price: service.numServiceItemSalesPrice,
                                    orderID: service.numOrderID,
                                    taxAmount: service.TaxAmount,
                                    numLocationServiceProductItemID: service.numLocationServiceItemID,
                                    numSalesTaxPercentage: service.numSalesTaxPercentage,
                                    numStorePromotionDiscount: service.numStorePromotionDiscount,
                                    numSeasonalPromotionDiscount: service.numSeasonalPromotionDiscount,
                                }

                                const existingAdditionalIndex = updatedServicePets[0].additionalService.findIndex(
                                    (existingService) => existingService.id === updatedService.id && existingService.orderID === updatedService.orderID
                                )

                                if (existingAdditionalIndex === -1) {
                                    updatedServicePets[0].additionalService.push({
                                        ...updatedService,
                                        desc: service.varServiceItemDescription,
                                    })
                                }
                            })

                            setServicePets(updatedServicePets)

                            calculateSummary(0)
                            setIsLoading(false)
                        } else {
                            setAdditionalServiceList([])
                            setIsLoading(false)
                        }
                    })
                    .catch((error) => {
                        console.log("error", error)
                    })
            }
        } else {
            toast.info("Please select pets from the side bar")
        }
    }

    const selectAdditionalService = (index) => {
        setSelectedAdditionalService(index)
        const petList = [...servicePets]
        //Checking to avoid duplicates
        let matching = petList[activeServicePet].additionalService.some((x) => x.id === additionalServiceList[index].numServiceItemID)
        if (!matching) {
            let additional = {
                id: additionalServiceList[index].numServiceItemID,
                name: additionalServiceList[index].varServiceItemName,
                price: additionalServiceList[index].numServiceItemSalesPrice,
                desc: additionalServiceList[index].varServiceItemDescription,
                orderID: additionalServiceList[index].numOrderID,
                taxAmount: calcTax(
                    additionalServiceList[index].numStorePromotionDiscount,
                    additionalServiceList[index].numSeasonalPromotionDiscount,
                    additionalServiceList[index].numServiceItemSalesPrice,
                    additionalServiceList[index].numSalesTaxPercentage
                ),
                numLocationServiceProductItemID: additionalServiceList[index].numLocationServiceItemID,
                numSalesTaxPercentage: additionalServiceList[index].numSalesTaxPercentage,
                numSeasonalPromotionDiscount: additionalServiceList[index].numSeasonalPromotionDiscount,
                numStorePromotionDiscount: additionalServiceList[index].numStorePromotionDiscount,
            }
            petList[activeServicePet].additionalService.push(additional)
            setServicePets(petList)
        } else {
            //Remove on click
            let delIndex = petList[activeServicePet].additionalService.findIndex((x) => x.id === additionalServiceList[index].numServiceItemID)
            if (petList[activeServicePet].additionalService[delIndex].orderID === "") {
                /// needs to be changed to check null//////////////////
                petList[activeServicePet].additionalService.splice(delIndex, 1)
            } else {
                petList[activeServicePet].additionalService[delIndex].id = null
                petList[activeServicePet].additionalService[delIndex].name = null
                petList[activeServicePet].additionalService[delIndex].price = null
                petList[activeServicePet].additionalService[delIndex].desc = ""
                petList[activeServicePet].additionalService[delIndex].taxAmount = null
                petList[activeServicePet].additionalService[delIndex].numLocationServiceProductItemID = null
                petList[activeServicePet].additionalService[delIndex].numSalesTaxPercentage = null
                petList[activeServicePet].additionalService[delIndex].numSeasonalPromotionDiscount = null
                petList[activeServicePet].additionalService[delIndex].numStorePromotionDiscount = null
            }
            setServicePets(petList)
        }
    }

    const handleHoldingTank = () => {
        setSelectedDate({ empIndex: "", dateIndex: "" })
        setSlotEmployeeList([])

        setIsLoading(true)
        if (props?.petList.length > 0) {
            const inputData: any = servicePets
            const result: any = []
            inputData.forEach((pet) => {
                pet.additionalService.forEach((additional) => {
                    const additionalServiceObj: any = {
                        numOrderID: additional.orderID !== "" ? additional.orderID : null,
                        numInvoiceID: null,
                        numLocationID: bookingLocation,
                        numSchID: null,
                        numAppointmentID: null,
                        numSchEventID: null,
                        numCustomerID: pet.customerID,
                        numPetID: pet.id,
                        numPetTypeID: pet.petType,
                        numPetSizeID: pet.sizeID,
                        numPetBreedID: pet.breedID,
                        bitAddOnService: null,
                        varServiceOrProduct: "S",
                        numServiceProductItemID: additional.id,
                        numLocationServiceProductItemID: additional.numLocationServiceProductItemID,
                        varServiceProductDescription: additional.desc === null ? "" : additional.desc,
                        bitTaxableItem: additional.numSalesTaxPercentage === null ? false : additional.numSalesTaxPercentage > 0,
                        numSalesTaxPercentage: additional.numSalesTaxPercentage,
                        numGroomerID: 1,
                        numBatherID: null,
                        numSalesPersonID: 1,
                        numQuantity: 1,
                        decUnit_Price: additional.price,
                        decChargeAmount: additional.price,
                        decTaxAmt: parseFloat(additional.taxAmount),
                        dtBegin_date_of_service: null,
                        dtEnd_date_of_service: null,
                        varStatus: "",
                        numSeq_nbr: null,
                        numReferringCustomerID: 154,
                        varReferringCustomerName: "Kewin 3",
                        numCancelReasonID: null,
                        varCancelReason: "",
                        numReasonCodeID: null,
                        varReasonCodeDescription: "",
                        numEmployeeID: 1,
                        varEmployeeName: "Sean",
                        bitActive: true,
                        numStorePromotionDiscount: additional.numStorePromotionDiscount,
                        numSeasonalPromotionDiscount: additional.numSeasonalPromotionDiscount,
                    }

                    result.push(additionalServiceObj)
                })
            })
            console.log("sent-TVP-payload", result)
            addOrdersToHoldingTank(result, "service")
        }
    }

    function calculateSummary(activePetIndex) {
        let selectedPetCharges = servicePets[activePetIndex]
        let totalBeforeTax = 0
        let totalTax = 0
        let additionalTotal = 0

        if (selectedPetCharges !== undefined) {
            if (selectedPetCharges.additionalService.length > 0) {
                selectedPetCharges.additionalService.map((item) => {
                    if (item.id !== null) {
                        totalTax = totalTax + parseFloat(item.taxAmount)
                        totalBeforeTax = totalBeforeTax + parseFloat(item.price)
                        additionalTotal = additionalTotal + parseFloat(item.price)
                    }
                })
            }
        }
        if (selectedPetCharges !== undefined) {
            if (selectedPetCharges.mainService.id !== null) {
                totalTax = totalTax + parseFloat(selectedPetCharges.mainService.taxAmount)

                setMainName(selectedPetCharges.mainService.name)
                setMainPrice(selectedPetCharges.mainService.price)

                totalBeforeTax = totalBeforeTax + parseFloat(selectedPetCharges.mainService.price)
            } else {
                setMainName("Main Service")
                setMainPrice(0)
            }
        }

        setAdditionalPrice(Math.round(additionalTotal * 100) / 100)
        setBeforeTax(Math.round(totalBeforeTax * 100) / 100)
        setTax(Math.round(totalTax * 100) / 100)
    }

    const handleTabChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : true)
    }
    const handleTabChange2 = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded1(newExpanded ? panel : true)
    }

    useEffect(() => {
        setIsLoading(true)
        if (props.petList !== undefined) {
            if (props.petList.length > 0) {
                let servicePet = [...servicePets]
                servicePet[activeServicePet].mainService.id = null
                servicePet[activeServicePet].mainService.name = null
                servicePet[activeServicePet].mainService.price = null
                servicePet[activeServicePet].mainService.varServiceProductDescription = null
                servicePet[activeServicePet].mainService.decUnit_Price = null
                servicePet[activeServicePet].mainService.taxAmount = null
                servicePet[activeServicePet].mainService.orderID = null
                servicePet[activeServicePet].mainService.numLocationServiceProductItemID = null
                servicePet[activeServicePet].mainService.numSalesTaxPercentage = null

                console.log("service-list-fetch- on modal open", {
                    numLocationID: bookingLocation,
                    dtDate_of_service: currDate,
                    numPetBreedID: props.petList[activeServicePet].breedID, //1
                    numPetSizeID: props.petList[activeServicePet].sizeID, //3
                    numEmployeeID: 1,
                    varEmployeeName: "Ravin",
                    numPetID: props.petList[activeServicePet].id,
                })

                getLocationServiceItemsPriceList(
                    bookingLocation,
                    currDate,
                    props.petList[activeServicePet].breedID,
                    props.petList[activeServicePet].sizeID,
                    1,
                    "Ravin",
                    props.petList[activeServicePet].id,
                    props.petList[activeServicePet].petType
                )
                    .then((data) => {
                        if (data.Services !== undefined) {
                            console.log("All services with SP-selected services", data)

                            const { additionalServiceList } = data.Services.reduce(
                                (lists, service) => {
                                    const existingIndex = lists.additionalServiceList.findIndex(
                                        (existingService) => existingService.id === service.numServiceItemID && existingService.orderID === service.numOrderID
                                    )

                                    if (existingIndex === -1) {
                                        lists.additionalServiceList.push({
                                            ...service,
                                            desc: service.varServiceItemDescription,
                                        })
                                    }

                                    return lists
                                },
                                { additionalServiceList: [] }
                            )

                            setAdditionalServiceList(additionalServiceList)

                            const updatedServicePets = [...servicePets]

                            updatedServicePets[activeServicePet].additionalService = []

                            data.Services.forEach((service) => {
                                if (service.OrderSelected !== true) return

                                const updatedService = {
                                    id: service.numServiceItemID,
                                    name: service.varServiceItemName,
                                    price: service.numServiceItemSalesPrice,
                                    orderID: service.numOrderID,
                                    taxAmount: service.TaxAmount,
                                    numLocationServiceProductItemID: service.numLocationServiceItemID,
                                    numSalesTaxPercentage: service.numSalesTaxPercentage,
                                    numStorePromotionDiscount: service.numStorePromotionDiscount,
                                    numSeasonalPromotionDiscount: service.numSeasonalPromotionDiscount,
                                }

                                const existingAdditionalIndex = updatedServicePets[activeServicePet].additionalService.findIndex(
                                    (existingService) => existingService.id === updatedService.id && existingService.orderID === updatedService.orderID
                                )

                                if (existingAdditionalIndex === -1) {
                                    updatedServicePets[activeServicePet].additionalService.push({
                                        ...updatedService,
                                        desc: service.varServiceItemDescription,
                                    })
                                }
                            })

                            setServicePets(updatedServicePets)
                            setIsLoading(false)
                        } else {
                            setAdditionalServiceList([])
                            setIsLoading(false)
                        }
                    })
                    .catch((error) => {
                        console.log("error", error)
                    })
            }
            if (servicePets.length > 0) {
                calculateSummary(activeServicePet)
            }
        }
    }, [activeServicePet])

    async function addOrdersToHoldingTank(data, type) {
        console.log("holding sent", data)
        await axios
            .post(`${process.env.REACT_APP_API_URL}Payment/AddHoldingOrderForProductOrService`, JSON.stringify(data), {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
            .then((result) => {
                console.log("save-res", result)
                if (result.status === 200) {
                    toast.success("Changes successfully saved!")
                    setIsLoading(false)
                    if (type === "service") {
                        fetchServicePriceList()
                    } else if (type === "service") {
                        fetchProductPriceList()
                    } else {
                        fetchNoPetPriceList()
                    }
                    setIsOpenAddProductNoPet(false)
                    setIsOpenAddServices(false)
                    setIsOpenAddProduct(false)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    async function removeProductOrServiceFromHoldingTank(data) {
        setIsLoading(true)
        console.log("holding sent", data)
        await axios
            .post(`${process.env.REACT_APP_API_URL}Appointment/RemoveHoldingTankDataOnDeselect`, JSON.stringify(data), {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
            .then((result) => {
                console.log("save-res", result)
                if (result.status === 200) {
                    setIsLoading(false)
                    console.log("Items removed from holding tank")
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
                setIsLoading(false)
            })
    }

    const calcTax = (storeDiscount, seasonalDiscount, mainPrice, percentage) => {
        let finalTaxAmount = 0

        if (percentage !== null) {
            let adjustments = parseFloat(seasonalDiscount) + parseFloat(storeDiscount)
            let afterAdjustment = parseFloat(mainPrice) - adjustments
            finalTaxAmount = afterAdjustment * percentage
        }

        return finalTaxAmount.toFixed(2)
    }

    useEffect(() => {
        if (servicePets.length > 0) {
            calculateSummary(activeServicePet)
        }
        console.log("servicePets", servicePets)
    }, [servicePets])

    //End-Services functions
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //With-Pet Product functions

    const openProductModal = () => {
        if (servicePets[activeProductPet].selectedProducts.length > 0) {
            setProductTabIndex(1)
        } else {
            setProductTabIndex(0)
        }

        if (props.petList.length > 0) {
            setIsLoading(true)
            setIsOpenAddProduct(true)
            if (props.petList.length > 0) {
                getLocationProductItemsPriceList(bookingLocation, currDate, 1, "Ravin", props.petList[0].id)
                    .then((data) => {
                        console.log("res - All products list", data)
                        setProductList(data)
                        setIsLoading(false)
                    })
                    .catch((error) => {
                        console.log("error", error)
                    })

                getLocationProductSelectedItemsPriceList(bookingLocation, currDate, 1, "Ravin", null, props.petList[0].customer.numCustomerID, null)
                    .then((data) => {
                        console.log("res - selected products list", data)
                        const petProducts = [...servicePets]
                        servicePets.map((pet, petIndex) => {
                            let arr: any = []

                            data.map((prod, index) => {
                                if (pet.id === prod.numPetID) {
                                    arr.push(prod)
                                }
                            })
                            petProducts[petIndex].selectedProducts = arr
                        })
                        setServicePets(petProducts)
                        setIsLoading(false)
                    })
                    .catch((error) => {
                        console.log("error", error)
                    })
                calculateSummaryProd(0)
            }
        } else {
            toast.info("Please select pets from the side bar")
        }
    }

    const fetchProductPriceList = () => {
        setIsLoading(true)
        if (props.petList.length > 0) {
            getLocationProductItemsPriceList(bookingLocation, currDate, 1, "Ravin", props.petList[0].id)
                .then((data) => {
                    console.log("res - All products list", data)
                    setProductList(data)
                    setIsLoading(false)
                })
                .catch((error) => {
                    console.log("error", error)
                })

            getLocationProductSelectedItemsPriceList(bookingLocation, currDate, 1, "Ravin", null, props.petList[0].customer.numCustomerID, null)
                .then((data) => {
                    console.log("res - selected products list", data)
                    const petProducts = [...servicePets]
                    servicePets.map((pet, petIndex) => {
                        let arr: any = []
                        data.map((prod, index) => {
                            if (pet.id === prod.numPetID) {
                                arr.push(prod)
                            }
                        })
                        petProducts[petIndex].selectedProducts = arr
                    })
                    setServicePets(petProducts)
                    setIsLoading(false)
                })
                .catch((error) => {
                    console.log("error", error)
                })
            calculateSummaryProd(0)
        } else {
            toast.info("Please select pets from the side bar")
        }
    }

    const addToCart = (id, index) => {
        const cartList = [...servicePets]
        const pList = [...productList]
        let cartItemIndex = servicePets[activeProductPet].selectedProducts.findIndex((x) => x.numLocationProductID === id)

        if (cartItemIndex < 0) {
            if (productList[index].SelectedQuantity < 1) {
                toast.info("Please select quantity!")
            } else {
                cartList[activeProductPet].selectedProducts.push({
                    numOrderID: null,
                    numInvoiceID: null,
                    numLocationID: bookingLocation,
                    numSchID: null,
                    numAppointmentID: null,
                    numSchEventID: null,
                    numPetTypeID: props.petList[activeProductPet].petType,
                    numPetSizeID: props.petList[activeProductPet].sizeID,
                    numPetBreedID: props.petList[activeProductPet].breedID,
                    numGroomerID: 1,
                    numBatherID: null,
                    numSalesPersonID: 1,
                    decUnit_Price: productList[index].numProductSalesPrice,
                    decChargeAmount: productList[index].numProductSalesPrice,
                    decTaxAmt:
                        parseFloat(
                            calcTax(
                                productList[index].numStorePromotionDiscount,
                                productList[index].numSeasonalPromotionDiscount,
                                productList[index].numProductSalesPrice,
                                productList[index].numSalesTaxPercentage
                            )
                        ) * productList[index].SelectedQuantity,
                    dtBegin_date_of_service: null,
                    dtEnd_date_of_service: null,
                    varStatus: "",
                    numSeq_nbr: null,
                    numReferringCustomerID: 154,
                    varReferringCustomerName: "Kewin 3",
                    numCancelReasonID: null,
                    varCancelReason: "",
                    numReasonCodeID: null,
                    varReasonCodeDescription: "",
                    numEmployeeID: 1,
                    varEmployeeName: "Sean",
                    varServiceProductDescription: productList[index].varProductDescription === null ? "" : productList[index].varProductDescription,
                    numLocationProductID: productList[index].numLocationProductID,
                    varProductBarCode: productList[index].varProductBarCode,
                    numProductID: productList[index].numProductID,
                    varProductVendorID: productList[index].varProductVendorID,
                    varProductName: productList[index].varProductName,
                    varProductDescription: productList[index].varProductDescription,
                    LocID: productList[index].LocID,
                    numCustomerID: props.petList[activeProductPet].customerID,
                    ServiceType: productList[index].ServiceType,
                    dtEffectiveDate: productList[index].dtEffectiveDate,
                    dtExpiryDate: productList[index].dtExpiryDate,
                    numProductSalesPrice: productList[index].numProductSalesPrice,
                    varStorePromotionMsg: productList[index].varStorePromotionMsg,
                    numStorePromotionDiscount: productList[index].numStorePromotionDiscount,
                    varSeasonalPromotionMsg: productList[index].varSeasonalPromotionMsg,
                    numSeasonalPromotionDiscount: productList[index].numSeasonalPromotionDiscount,
                    bitTaxableItem: productList[index].bitTaxableItem,
                    numSalesTaxPercentage: productList[index].numSalesTaxPercentage,
                    TotalTaxAmount: productList[index].TotalTaxAmount,
                    numAvailableQuantity: productList[index].numAvailableQuantity,
                    numQuantity: productList[index].SelectedQuantity,
                    bitActivePromotionFlag: productList[index].bitActivePromotionFlag,
                    varLinkToPromotionMaterial: productList[index].varLinkToPromotionMaterial,
                    varLinkToGlobalCoupon: productList[index].varLinkToGlobalCoupon,
                    varMessageToEmployee: productList[index].varMessageToEmployee,
                    varPromotionalMessage: productList[index].varPromotionalMessage,
                    numLocationSpecificAdjustmentPercent: productList[index].numLocationSpecificAdjustmentPercent,
                    numLocationSpecificAdjustmentAmount: productList[index].numLocationSpecificAdjustmentAmount,
                    numDateSpecificAdjustmentPercent: productList[index].numDateSpecificAdjustmentPercent,
                    numDateSpecificAdjustmentAmount: productList[index].numDateSpecificAdjustmentAmount,
                    varSalesCommissionMessage: productList[index].varSalesCommissionMessage,
                    btSalesCommissionPercent: productList[index].btSalesCommissionPercent,
                    numSalesCommissionPercent: productList[index].numSalesCommissionPercent,
                    btSalesCommissionAmount: productList[index].btSalesCommissionAmount,
                    numSalesCommissionAmount: productList[index].numSalesCommissionAmount,
                    bitAutoReOrder: productList[index].bitAutoReOrder,
                    numMinimumQuantityReOrderLevel: productList[index].numMinimumQuantityReOrderLevel,
                    numMaximumLocationQuantity: productList[index].numMaximumLocationQuantity,
                    bitActive: productList[index].bitActive,
                    numServiceProductItemID: productList[index].numServiceProductItemID,
                    varServiceOrProduct: "P",
                    numPetID: props.petList[activeProductPet].id,
                    DOS: productList[index].DOS,
                    OrderSelected: productList[index].OrderSelected,
                    ChargeEntered: productList[index].ChargeEntered,
                    PaymentEntered: productList[index].PaymentEntered,
                    numLocationServiceProductItemID: productList[index].numLocationProductID,
                })
                //console.log("per pet cart push", cartList[activeProductPet].selectedProducts)
                productList[index].SelectedQuantity = 0
                pList[index].isAddedToCart = true
            }
        } else {
            if (productList[index].SelectedQuantity < 1) {
                toast.info("Please select quantity!")
            } else {
                cartList[activeProductPet].selectedProducts[cartItemIndex].bitActive = true
                cartList[activeProductPet].selectedProducts[cartItemIndex].numQuantity = cartList[activeProductPet].selectedProducts[cartItemIndex].numQuantity + productList[index].SelectedQuantity
                productList[index].SelectedQuantity = 0
                pList[index].isAddedToCart = true
            }
        }
        setServicePets(cartList)
        calculateSummaryProd(activeProductPet)
        setProductList(pList)
    }

    const incrementQty = (id, index) => {
        const productArr = [...productList]
        let selected = productArr[index].SelectedQuantity
        let totalAvailable = productArr[index].numAvailableQuantity
        if (selected >= totalAvailable) {
            toast.info("Maximum quantity!")
        } else {
            productArr[index].SelectedQuantity = productArr[index].SelectedQuantity + 1
            productArr[index].TempAvailableQuantity = productArr[index].TempAvailableQuantity - 1
        }
        setProductList(productArr)
        calculateSummaryProd(activeProductPet)
    }

    const decrementQty = (id, index) => {
        const productArr = [...productList]
        let selected = productArr[index].SelectedQuantity

        if (selected <= 0) {
            productArr[index].SelectedQuantity = 0
        } else {
            productArr[index].SelectedQuantity = productArr[index].SelectedQuantity - 1
            productArr[index].TempAvailableQuantity = productArr[index].TempAvailableQuantity + 1
        }
        setProductList(productArr)
        calculateSummaryProd(activeProductPet)
    }

    const incrementCartQty = (id, index) => {
        let productListIndex = productList.findIndex((x) => x.numLocationProductID === id)

        const serPet = [...servicePets]
        const productArr = [...productList]
        let selected = serPet[activeProductPet].selectedProducts[index].numQuantity
        let totalAvailable = productArr[productListIndex].numAvailableQuantity

        if (selected >= totalAvailable) {
            toast.info("Maximum quantity!")
        } else {
            serPet[activeProductPet].selectedProducts[index].numQuantity = serPet[activeProductPet].selectedProducts[index].numQuantity + 1
            productArr[productListIndex].TempAvailableQuantity = productArr[productListIndex].TempAvailableQuantity - 1
        }
        setServicePets(serPet)
        setProductList(productArr)
        calculateSummaryProd(activeProductPet)
    }

    const decrementCartQty = (id, index) => {
        const serPet = [...servicePets]
        const productArr = [...productList]
        let selected = serPet[activeProductPet].selectedProducts[index].numQuantity

        if (selected <= 1) {
            serPet[activeProductPet].selectedProducts[index].numQuantity = 1
        } else {
            serPet[activeProductPet].selectedProducts[index].numQuantity = serPet[activeProductPet].selectedProducts[index].numQuantity - 1
            let productListIndex = productList.findIndex((x) => x.numLocationProductID === id)
            productArr[productListIndex].TempAvailableQuantity = productArr[productListIndex].TempAvailableQuantity + 1
        }
        setServicePets(serPet)
        setProductList(productArr)
        calculateSummaryProd(activeProductPet)
    }

    const removeFromCart = (id, index) => {
        const serPet = [...servicePets]
        const productArr = [...productList]
        let productListIndex = productList.findIndex((x) => x.numLocationProductID === id)

        if (serPet[activeProductPet].selectedProducts[index].numOrderID !== null) {
            serPet[activeProductPet].selectedProducts[index].bitActive = false
            productArr[productListIndex].TempAvailableQuantity = productArr[productListIndex].TempAvailableQuantity + serPet[activeProductPet].selectedProducts[index].numQuantity
            serPet[activeProductPet].selectedProducts[index].numQuantity = 0
        } else {
            productArr[productListIndex].TempAvailableQuantity = productArr[productListIndex].TempAvailableQuantity + serPet[activeProductPet].selectedProducts[index].numQuantity
            serPet[activeProductPet].selectedProducts.splice(index, 1)
            productArr[productListIndex].isAddedToCart = false
        }
        setServicePets(serPet)
        setProductList(productArr)
        calculateSummaryProd(activeProductPet)
    }

    const handleProductSave = () => {
        if (servicePets[activeProductPet].selectedProducts.length > 0) {
            const combinedProducts = servicePets.reduce((combinedArray, pet) => {
                combinedArray.push(...pet.selectedProducts)
                return combinedArray
            }, [])

            console.log("product sent - holding tank", combinedProducts)
            addOrdersToHoldingTank(combinedProducts, "product")
        } else {
            toast.info("Cart is empty, Please add products to cart to proceed!")
        }
    }

    function calculateSummaryProd(activePetIndex) {
        let selectedPetCharges = servicePets[activePetIndex]
        let totalBeforeTax = 0
        let totalTax = 0
        let additionalTotal = 0

        if (selectedPetCharges !== undefined) {
            if (selectedPetCharges.selectedProducts.length > 0) {
                selectedPetCharges.selectedProducts.map((item) => {
                    if (item.id !== null) {
                        totalTax = totalTax + parseFloat(item.taxAmount)
                        totalBeforeTax = totalBeforeTax + parseFloat(item.price)
                        additionalTotal = additionalTotal + parseFloat(item.price)
                    }
                })
            }
        }
        setProdBeforeTax(Math.round(totalBeforeTax * 100) / 100)
        setProdTax(Math.round(totalTax * 100) / 100)
    }

    useEffect(() => {
        let amountT = 0
        let cartItem = 0
        if (servicePets[activeProductPet] !== undefined) {
            servicePets[activeProductPet].selectedProducts.map((product, index) => {
                if (product.numProductSalesPrice !== null && product.bitActive === true) {
                    amountT = amountT + Math.round(parseFloat(product.numProductSalesPrice) * product.numQuantity * 100) / 100
                }
                if (product.bitActive === true) {
                    cartItem = cartItem + product.numQuantity
                }
            })
            setProductCartAmount(amountT)
            setCartItemCount(cartItem)
        } else {
            setProductCartAmount(amountT)
            setCartItemCount(cartItem)
        }
    }, [activeProductPet])

    const selectActivePet = (index) => {
        setActiveProductPet(index)
    }

    const handleProductTabChange = (event, newTabIndex) => {
        setProductTabIndex(newTabIndex)
    }

    useEffect(() => {
        let amountT = 0
        let cartItem = 0
        if (servicePets[activeProductPet] !== undefined) {
            servicePets[activeProductPet].selectedProducts.map((product, index) => {
                if (product.numProductSalesPrice !== null && product.bitActive === true) {
                    amountT = amountT + Math.round(parseFloat(product.numProductSalesPrice) * product.numQuantity * 100) / 100
                }
                if (product.bitActive === true) {
                    cartItem = cartItem + product.numQuantity
                }
            })
            setProductCartAmount(amountT)
            setCartItemCount(cartItem)
        } else {
            setProductCartAmount(amountT)
            setCartItemCount(cartItem)
        }
    }, [servicePets])

    // End With-Pet Product functions
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //No-Pet Product functions

    const openProductModalNoPet = () => {
        if (productCartNoPetList.length > 0) {
            setProductTabIndexNoPet(1)
        } else {
            setProductTabIndexNoPet(0)
        }

        setIsOpenAddProductNoPet(true)
        getLocationProductItemsPriceList(bookingLocation, currDate, 1, "Ravin", null)
            .then((data) => {
                console.log("res - No pet modal open All products list", data)
                setProductList(data)
                setIsLoading(false)
            })
            .catch((error) => {
                console.log("error", error)
            })

        getLocationProductSelectedItemsPriceList(bookingLocation, currDate, 1, "Ravin", -1, props.id, null)
            .then((data) => {
                console.log("SP-Prod-Cart-Selected no pet onload", data)
                setProductCartNoPetList(data)
                setIsLoading(false)
            })
            .catch((error) => {
                console.log("error", error)
            })
    }

    const fetchNoPetPriceList = () => {
        getLocationProductItemsPriceList(bookingLocation, currDate, 1, "Ravin", null)
            .then((data) => {
                console.log("res - No pet modal open All products list", data)
                setProductList(data)
                setIsLoading(false)
            })
            .catch((error) => {
                console.log("error", error)
            })

        getLocationProductSelectedItemsPriceList(bookingLocation, currDate, 1, "Ravin", -1, props.id, null)
            .then((data) => {
                console.log("SP-Prod-Cart-Selected no pet onload", data)
                setProductCartNoPetList(data)
                setIsLoading(false)
            })
            .catch((error) => {
                console.log("error", error)
            })
    }

    const addToCartNoPet = (id, index) => {
        const cartList = [...productCartNoPetList]
        let cartItemIndex = productCartNoPetList.findIndex((x) => x.numLocationProductID === id)
        const pList = [...productList]

        if (cartItemIndex < 0) {
            if (productList[index].SelectedQuantity < 1) {
                toast.info("Please select quantity!")
            } else {
                cartList.push({
                    numOrderID: null,
                    numInvoiceID: null,
                    numLocationID: bookingLocation,
                    numSchID: null,
                    numAppointmentID: null,
                    numSchEventID: null,
                    numPetTypeID: null,
                    numPetSizeID: null,
                    numPetBreedID: null,
                    numGroomerID: 1,
                    numBatherID: null,
                    numSalesPersonID: 1,
                    decUnit_Price: productList[index].numProductSalesPrice,
                    decChargeAmount: productList[index].numProductSalesPrice,
                    decTaxAmt:
                        parseFloat(
                            calcTax(
                                productList[index].numStorePromotionDiscount,
                                productList[index].numSeasonalPromotionDiscount,
                                productList[index].numProductSalesPrice,
                                productList[index].numSalesTaxPercentage
                            )
                        ) * productList[index].SelectedQuantity,
                    dtBegin_date_of_service: null,
                    dtEnd_date_of_service: null,
                    varStatus: "",
                    numSeq_nbr: null,
                    numReferringCustomerID: 154,
                    varReferringCustomerName: "Kewin 3",
                    numCancelReasonID: null,
                    varCancelReason: "",
                    numReasonCodeID: null,
                    varReasonCodeDescription: "",
                    numEmployeeID: 1,
                    varEmployeeName: "Sean",
                    varServiceProductDescription: productList[index].varProductDescription,
                    numLocationProductID: productList[index].numLocationProductID,
                    varProductBarCode: productList[index].varProductBarCode,
                    numProductID: productList[index].numProductID,
                    varProductVendorID: productList[index].varProductVendorID,
                    varProductName: productList[index].varProductName,
                    varProductDescription: productList[index].varProductDescription,
                    LocID: productList[index].LocID,
                    numCustomerID: props.id,
                    ServiceType: productList[index].ServiceType,
                    dtEffectiveDate: productList[index].dtEffectiveDate,
                    dtExpiryDate: productList[index].dtExpiryDate,
                    numProductSalesPrice: productList[index].numProductSalesPrice,
                    varStorePromotionMsg: productList[index].varStorePromotionMsg,
                    numStorePromotionDiscount: productList[index].numStorePromotionDiscount,
                    varSeasonalPromotionMsg: productList[index].varSeasonalPromotionMsg,
                    numSeasonalPromotionDiscount: productList[index].numSeasonalPromotionDiscount,
                    bitTaxableItem: productList[index].bitTaxableItem,
                    numSalesTaxPercentage: productList[index].numSalesTaxPercentage,
                    TotalTaxAmount: productList[index].TotalTaxAmount,
                    numAvailableQuantity: productList[index].numAvailableQuantity,
                    numQuantity: productList[index].SelectedQuantity,
                    bitActivePromotionFlag: productList[index].bitActivePromotionFlag,
                    varLinkToPromotionMaterial: productList[index].varLinkToPromotionMaterial,
                    varLinkToGlobalCoupon: productList[index].varLinkToGlobalCoupon,
                    varMessageToEmployee: productList[index].varMessageToEmployee,
                    varPromotionalMessage: productList[index].varPromotionalMessage,
                    numLocationSpecificAdjustmentPercent: productList[index].numLocationSpecificAdjustmentPercent,
                    numLocationSpecificAdjustmentAmount: productList[index].numLocationSpecificAdjustmentAmount,
                    numDateSpecificAdjustmentPercent: productList[index].numDateSpecificAdjustmentPercent,
                    numDateSpecificAdjustmentAmount: productList[index].numDateSpecificAdjustmentAmount,
                    varSalesCommissionMessage: productList[index].varSalesCommissionMessage,
                    btSalesCommissionPercent: productList[index].btSalesCommissionPercent,
                    numSalesCommissionPercent: productList[index].numSalesCommissionPercent,
                    btSalesCommissionAmount: productList[index].btSalesCommissionAmount,
                    numSalesCommissionAmount: productList[index].numSalesCommissionAmount,
                    bitAutoReOrder: productList[index].bitAutoReOrder,
                    numMinimumQuantityReOrderLevel: productList[index].numMinimumQuantityReOrderLevel,
                    numMaximumLocationQuantity: productList[index].numMaximumLocationQuantity,
                    bitActive: productList[index].bitActive,
                    numServiceProductItemID: productList[index].numServiceProductItemID,
                    varServiceOrProduct: "P",
                    numPetID: -1,
                    DOS: productList[index].DOS,
                    OrderSelected: productList[index].OrderSelected,
                    ChargeEntered: productList[index].ChargeEntered,
                    PaymentEntered: productList[index].PaymentEntered,
                    numLocationServiceProductItemID: productList[index].numLocationProductID,
                })
                console.log("Quan", productList[index].SelectedQuantity)
                productList[index].SelectedQuantity = 0
                pList[index].isAddedToCart = true
            }
        } else {
            if (productList[index].SelectedQuantity < 1) {
                toast.info("Please select quantity!")
            } else {
                cartList[cartItemIndex].bitActive = true
                cartList[cartItemIndex].numQuantity = cartList[cartItemIndex].numQuantity + productList[index].SelectedQuantity
                productList[index].SelectedQuantity = 0
                pList[index].isAddedToCart = true
            }
        }
        console.log("cList", cartList)
        setProductCartNoPetList(cartList)
    }

    const incrementCartQtyNoPet = (id, index) => {
        let productListIndex = productList.findIndex((x) => x.numLocationProductID === id)
        const productCartArr = [...productCartNoPetList]
        const productArr = [...productList]
        let selected = productCartArr[index].numQuantity
        let totalAvailable = productArr[productListIndex].numAvailableQuantity

        if (selected >= totalAvailable) {
            toast.info("Maximum quantity!")
        } else {
            productCartArr[index].numQuantity = productCartArr[index].numQuantity + 1
            productArr[productListIndex].TempAvailableQuantity = productArr[productListIndex].TempAvailableQuantity - 1
        }
        setProductCartNoPetList(productCartArr)
        setProductList(productArr)
    }

    const decrementCartQtyNoPet = (id, index) => {
        const productCartArr = [...productCartNoPetList]
        const productArr = [...productList]
        let selected = productCartArr[index].numQuantity

        if (selected <= 1) {
            productCartArr[index].numQuantity = 1
        } else {
            productCartArr[index].numQuantity = productCartArr[index].numQuantity - 1
            let productListIndex = productList.findIndex((x) => x.numLocationProductID === id)
            productArr[productListIndex].TempAvailableQuantity = productArr[productListIndex].TempAvailableQuantity + 1
        }
        setProductCartNoPetList(productCartArr)
        setProductList(productArr)
    }

    const removeFromCartNoPet = (id, index) => {
        console.log(id, index)
        const productCartArr = [...productCartNoPetList]
        const productArr = [...productList]
        let productListIndex = productList.findIndex((x) => x.numLocationProductID === id)
        let productCartNoPetIndex = productCartNoPetList.findIndex((x) => x.numLocationProductID === id)
        console.log("productCartNoPetIndex", productCartNoPetIndex)

        if (productCartArr[productCartNoPetIndex].numOrderID !== null) {
            productCartArr[productCartNoPetIndex].bitActive = false
            productArr[productListIndex].TempAvailableQuantity = productArr[productListIndex].TempAvailableQuantity + productCartArr[productCartNoPetIndex].numQuantity
            productCartArr[productCartNoPetIndex].numQuantity = 0
            console.log("here")
        } else {
            productArr[productListIndex].TempAvailableQuantity = productArr[productListIndex].TempAvailableQuantity + productCartArr[productCartNoPetIndex].numQuantity
            productCartArr.splice(productCartNoPetIndex, 1)
            productArr[productListIndex].isAddedToCart = false
            console.log("here1")
        }
        setProductCartNoPetList(productCartArr)
        setProductList(productArr)
    }

    const handleProductSaveNoPet = () => {
        if (productCartNoPetList.length > 0) {
            console.log("no pet sent - list", productCartNoPetList)
            addOrdersToHoldingTank(productCartNoPetList, "product-no-pet")
        } else {
            toast.info("Cart is empty, Please add products to cart to proceed!")
        }
    }

    useEffect(() => {
        let amountT = 0
        let cartItem = 0
        if (productCartNoPetList.length > 0) {
            productCartNoPetList.map((product, index) => {
                if (product.numProductSalesPrice !== null && product.bitActive === true) {
                    amountT = amountT + Math.round(parseFloat(product.numProductSalesPrice) * product.numQuantity * 100) / 100
                }
                if (product.bitActive === true) {
                    cartItem = cartItem + product.numQuantity
                }
            })
            setProductCartAmount(amountT)
            setCartItemCount(cartItem)
        } else {
            setProductCartAmount(amountT)
            setCartItemCount(cartItem)
        }
    }, [productCartNoPetList])

    const handleProductTabChangeNoPet = (event, newTabIndex) => {
        setProductTabIndexNoPet(newTabIndex)
    }

    // End No-Pet Product functions

    const createInvoice = () => {
        setIsLoading(true)

        if (props.customerBooking.customerAddress.length > 0) {
            let billingAddress = props.customerBooking.customerAddress
                .filter((x) => x.bitIsBilling === true)
                .map((billingAddress, index) => {
                    return billingAddress
                })

            let InvoiceObj = {
                numSchID: 0,
                varScheduleType: 0,
                numPetID: 0,
                numSchedulingCustomerID: props.id,
                numSchEventID: 0,
                dtApptStartTime: new Date().toISOString(),
                numApptDuration: 0,
                numPrecedingScheduleID: 0,
                numPrecedingEventID: 0,
                numSucceedingEventID: 0,
                numSucceedingSceduleID: 0,
                numSchApptStatusID: 0,
                bitActive: true,
                numLocationID: bookingLocation,
                dtApptEndTime: new Date().toISOString(),
                apptStatus: "",
                numCustomerID: props.id,
                numBillToCustomerID: props.id,
                varBillToAddress1: billingAddress[0].varCustomerAddress1,
                varBillToAddress2: billingAddress[0].varCustomerAddress2,
                varBillToCity: billingAddress[0].varCustomerCity,
                varBillToState: billingAddress[0].varCustomerState,
                varBillToZip: billingAddress[0].varCustomerZip,
                varTerms: "'Due on Receipt'",
                numEmployeeID: 1,
                varEmployeeName: "Ravin",
            }
            console.log("sent-inv-object", InvoiceObj)
            axios
                .post(`${process.env.REACT_APP_API_URL}Appointment/PopulateInvoice`, InvoiceObj)
                .then(function (response) {
                    console.log("app_success", response.data)
                    if (response.status === 200) {
                        setIsLoading(false)
                        toast.success("Invoice successfully saved!")
                        GetAllInvoicesByCustomerID(0, props.id)
                            .then((data) => {
                                console.log("customer inv", data)
                                if (data.length > 0) {
                                    setIsInvAvailable(false)
                                    setCustomerInvoiceID(data[0].numInvoiceID)
                                } else {
                                    setIsInvAvailable(true)
                                }
                            })
                            .catch((error) => {
                                console.log("error", error)
                            })
                        setIsLoading(false)
                    }
                })
                .catch(function (error) {
                    console.log(error)
                    setIsLoading(false)
                })
        } else {
            toast.success("No addresses ware found for the selected customer")
        }
    }

    const makeTheBooking = () => {
        setIsLoading(true)
        if (reservationPet !== "") {
            let res_pet_index = servicePets.findIndex((x) => x.id === parseInt(reservationPet))
            if (Object.keys(servicePets[res_pet_index].slotInfo).length > 0) {
                if (servicePets[res_pet_index].mainService.id !== null || servicePets[res_pet_index].additionalService.length > 0) {
                    if (props.customerBooking.customerAddress.length > 0) {
                        let billingAddress = props.customerBooking.customerAddress
                            .filter((x) => x.bitIsBilling === true)
                            .map((billingAddress, index) => {
                                return billingAddress
                            })

                        let reservationObj = {} as any

                        if (servicePets[res_pet_index]?.slotInfo.slot.slots.length > 1) {
                            reservationObj = {
                                numSchID: 0,
                                varScheduleType: 0,
                                numPetID: servicePets[res_pet_index].id,
                                numSchedulingCustomerID: servicePets[res_pet_index].customer.numCustomerID,
                                numSchEventID: 0,
                                dtApptStartTime: require("moment")(servicePets[res_pet_index]?.slotInfo?.slot?.slots[0]?.start).format("YYYY-MM-DDTHH:mm:ss"),
                                numApptDuration: 0,
                                numPrecedingScheduleID: 0,
                                numPrecedingEventID: 0,
                                numSucceedingEventID: 0,
                                numSucceedingSceduleID: 0,
                                numSchApptStatusID: 0,
                                bitActive: true,
                                numLocationID: bookingLocation,
                                dtApptEndTime: require("moment")(servicePets[res_pet_index]?.slotInfo?.slot?.slots[0]?.end).format("YYYY-MM-DDTHH:mm:ss"),
                                apptStatus: "SCHEDULING",
                                numCustomerID: servicePets[res_pet_index].customer.numCustomerID,
                                numBillToCustomerID: servicePets[res_pet_index].customer.numCustomerID,
                                varBillToAddress1: billingAddress[0].varCustomerAddress1,
                                varBillToAddress2: billingAddress[0].varCustomerAddress2,
                                varBillToCity: billingAddress[0].varCustomerCity,
                                varBillToState: billingAddress[0].varCustomerState,
                                varBillToZip: billingAddress[0].varCustomerZip,
                                varTerms: "'Due on Receipt'",
                                numEmployeeID: 1,
                                varEmployeeName: "Ravin",
                                schedulingBookings: [
                                    {
                                        timeSlotID: servicePets[res_pet_index]?.slotInfo.slot.slots[2].slotID,
                                        serviceID: servicePets[res_pet_index].additionalService[0].id,
                                        startTime: require("moment")(servicePets[res_pet_index]?.slotInfo?.slot?.slots[2]?.start).format("YYYY-MM-DDTHH:mm:ss"),
                                        endTime: require("moment")(servicePets[res_pet_index]?.slotInfo?.slot?.slots[2]?.end).format("YYYY-MM-DDTHH:mm:ss"),
                                        appointmentID: 0,
                                        userID: 1,
                                        IsBather: true,
                                    },
                                    {
                                        timeSlotID: servicePets[res_pet_index]?.slotInfo.slot.slots[1].slotID,
                                        serviceID: servicePets[res_pet_index].additionalService[0].id,
                                        startTime: require("moment")(servicePets[res_pet_index]?.slotInfo?.slot?.slots[1]?.start).format("YYYY-MM-DDTHH:mm:ss"),
                                        endTime: require("moment")(servicePets[res_pet_index]?.slotInfo?.slot?.slots[1]?.end).format("YYYY-MM-DDTHH:mm:ss"),
                                        appointmentID: 0,
                                        userID: 1,
                                        IsBather: false,
                                    },
                                ],
                            }
                        } else {
                            reservationObj = {
                                numSchID: 0,
                                varScheduleType: 0,
                                numPetID: servicePets[res_pet_index].id,
                                numSchedulingCustomerID: servicePets[res_pet_index].customer.numCustomerID,
                                numSchEventID: 0,
                                dtApptStartTime: require("moment")(servicePets[res_pet_index]?.slotInfo?.slot?.slots[0]?.start).format("YYYY-MM-DDTHH:mm:ss"),
                                numApptDuration: 0,
                                numPrecedingScheduleID: 0,
                                numPrecedingEventID: 0,
                                numSucceedingEventID: 0,
                                numSucceedingSceduleID: 0,
                                numSchApptStatusID: 0,
                                bitActive: true,
                                numLocationID: bookingLocation,
                                dtApptEndTime: require("moment")(servicePets[res_pet_index]?.slotInfo?.slot?.slots[0]?.end).format("YYYY-MM-DDTHH:mm:ss"),
                                apptStatus: "SCHEDULING",
                                numCustomerID: servicePets[res_pet_index].customer.numCustomerID,
                                numBillToCustomerID: servicePets[res_pet_index].customer.numCustomerID,
                                varBillToAddress1: billingAddress[0].varCustomerAddress1,
                                varBillToAddress2: billingAddress[0].varCustomerAddress2,
                                varBillToCity: billingAddress[0].varCustomerCity,
                                varBillToState: billingAddress[0].varCustomerState,
                                varBillToZip: billingAddress[0].varCustomerZip,
                                varTerms: "'Due on Receipt'",
                                numEmployeeID: 1,
                                varEmployeeName: "Ravin",
                                schedulingBookings: [
                                    {
                                        timeSlotID: servicePets[res_pet_index]?.slotInfo.slot.slots[0].slotID,
                                        serviceID: servicePets[res_pet_index].additionalService[0].id,
                                        startTime: require("moment")(servicePets[res_pet_index]?.slotInfo?.slot?.slots[0]?.start).format("YYYY-MM-DDTHH:mm:ss"),
                                        endTime: require("moment")(servicePets[res_pet_index]?.slotInfo?.slot?.slots[0]?.end).format("YYYY-MM-DDTHH:mm:ss"),
                                        appointmentID: 0,
                                        userID: 1,
                                        IsBather: false,
                                    },
                                ],
                            }
                        }

                        console.log("sent-res-Populate - Appointment", reservationObj)
                        axios
                            .post(`${process.env.REACT_APP_API_URL}Appointment/PopulateAppointment`, reservationObj)
                            .then(function (response) {
                                console.log("app_success", response)
                                if (response.status === 200) {
                                    setIsLoading(false)
                                    toast.success("Appointment successfully saved!")

                                    //Upload ICS

                                    handleUploadICS(
                                        {
                                            title: "Appointment Confirmation REF# " + response.data?.outAppointmentID,
                                            description: `<p>Appointment successfully scheduled for <strong>${servicePets[res_pet_index].name}</strong> at our grooming salon <strong>${
                                                store.find((item) => item.id === bookingLocation)?.title
                                            }</strong>.</p>`,
                                            startTime: require("moment")(servicePets[res_pet_index]?.slotInfo?.slot?.slots[0]?.start).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"),
                                            endTime: require("moment")(servicePets[res_pet_index]?.slotInfo?.slot?.slots[0]?.end).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"),
                                            location: store.find((item) => item.id === bookingLocation)?.title,
                                            email: store.find((item) => item.id === bookingLocation)?.email,
                                            locationID: bookingLocation,
                                            recipientID: servicePets[res_pet_index]?.customer?.numCustomerID,
                                            recipientName: servicePets[res_pet_index]?.customer?.varCustomerFirstName + " " + servicePets[res_pet_index]?.customer?.varCustomerLastName,
                                        },
                                        `AppointmentConfirmationREF${response.data?.outAppointmentID}`,
                                        response.data?.outAppointmentID
                                    )

                                    const serPetList = [...servicePets]
                                    props.removeFromList?.(serPetList[res_pet_index].id)
                                    serPetList.splice(res_pet_index, 1)
                                    setServicePets(serPetList)
                                    if (servicePets.length > 0) {
                                        setReservationPet(servicePets[0].id)
                                    } else {
                                        setReservationPet("")
                                    }
                                    reservationPetRef.current?.handleClear()
                                    props.pastSelectedAppointment("")
                                    props.pastAppList([])
                                    setPastAppointmentList([])
                                    setSelectedPastAppointment("")

                                    setBookingDate(moment().format("YYYY-MM-DD"))
                                    setTimeOfDay(9)
                                    myRefTimeOFDay.current?.handleClear()
                                    setSlotEmployeeList([])
                                    setSelectedDate({ empIndex: "", dateIndex: "" })

                                    GetAllInvoicesByCustomerID(0, props.id)
                                        .then((data) => {
                                            console.log("customer inv", data)
                                            if (data.length > 0) {
                                                setIsInvAvailable(false)
                                                setCustomerInvoiceID(data[0].numInvoiceID)
                                            } else {
                                                setIsInvAvailable(true)
                                            }
                                        })
                                        .catch((error) => {
                                            console.log("error", error)
                                        })
                                    setIsLoading(false)
                                }
                            })
                            .catch(function (error) {
                                console.log(error)
                                setIsLoading(false)
                            })
                    } else {
                        toast.success("No addresses ware found for the selected customer")
                    }
                } else {
                    toast.info("Select main service or an additional service to continue!")
                    setIsLoading(false)
                }
            } else {
                toast.info("Please select a slot!")
                setIsLoading(false)
            }
        } else {
            toast.info("Please pick a pet to make reservation")
            setIsLoading(false)
        }
    }

    const handleClear = () => {
        setBookingTime("")
        setBookingDate("")
        myRefTimeOFDay.current?.handleClear()
        setTimeOfDay("")
        setSlotObj([])
        setSelectedSlot(0)
        reservationPetRef.current?.handleClear()
        if (servicePets.length > 0) {
            setReservationPet(servicePets[0].id)
        } else {
            setReservationPet("")
        }
    }

    useEffect(() => {
        if (props.petList !== undefined) {
            console.log("props-pets", props.petList, props.petList.length, "triggerd")
            if (props.petList.length === 0) {
                console.log("inn")
                setReservationPet("")
                reservationPetRef.current?.handleClear()
            }
        }
    }, [props.petList])

    const getSlots = () => {
        if (reservationPet !== "") {
            setIsLoading(true)
            let res_pet_index = servicePets.findIndex((x) => x.id === parseInt(reservationPet))
            getPrimaryBreedByPetBreedID(servicePets[res_pet_index].breedID)
                .then((dataBreed) => {
                    console.log("dataB", dataBreed)

                    setSelectedDate({ empIndex: 0, dateIndex: 0 })
                    const serPet = [...servicePets]
                    serPet[res_pet_index].slotInfo = {}
                    setServicePets(serPet)
                    if (servicePets[res_pet_index].additionalService.length > 0) {
                        if (servicePets[res_pet_index].additionalService[0].id !== null) {
                            if (bookingDate !== "") {
                                const selectedTimeOfDay = timeOfDayList.find((item) => item.numID === timeOfDay)
                                const { tmToTime, tmFromTime } = selectedTimeOfDay

                                let slotObj = [
                                    {
                                        petTypeID: servicePets[res_pet_index].petType,
                                        serviceList: [
                                            {
                                                serviceID: servicePets[res_pet_index].additionalService[0].id,
                                            },
                                        ],
                                        petSizeID: servicePets[res_pet_index].sizeID,
                                        petBreedID: dataBreed,
                                        prefferedEmpID: servicePets[res_pet_index].preferredGroomer === null ? 0 : servicePets[res_pet_index].preferredGroomer,
                                        locationID: bookingLocation,
                                        timeRanges: [
                                            {
                                                timeOfFromDate: tmFromTime,
                                                timeOfToDate: tmToTime,
                                            },
                                        ],
                                        customerID: servicePets[res_pet_index].customerID,
                                        petID: servicePets[res_pet_index].id,
                                        requestDate: bookingDate,
                                        userID: 1,
                                    },
                                ]

                                console.log("slotOBJ-sent - GetSlot", slotObj)

                                axios
                                    .post(`${process.env.REACT_APP_API_URL}Schedule/GetSlot`, slotObj)
                                    .then(function (response) {
                                        console.log("app_success_slots", response)
                                        let timeSlots: any = response.data

                                        timeSlots.forEach((slot) => {
                                            slot.AvailableStartTime = new Date(slot.AvailableStartTime)
                                            slot.AvailableEndTime = new Date(slot.AvailableEndTime)
                                        })

                                        const groupedSlots = [] as any
                                        let obj = [] as any

                                        for (let i = 0; i < timeSlots.length; i += 2) {
                                            if (timeSlots[i].IsPackage) {
                                                if (timeSlots[i]?.IsBath) {
                                                    console.log("timeSlotDDD", timeSlots[i]?.DealTimeSlotID)
                                                    if (timeSlots[i]?.TempEmpID === timeSlots[i]?.DealEmpID) {
                                                        const startTime = timeSlots[i].AvailableStartTime
                                                        const endTime = timeSlots[i + 1].AvailableEndTime

                                                        const durationMilliseconds = endTime - startTime
                                                        const durationMinutes = Math.floor(durationMilliseconds / (1000 * 60))

                                                        let objj = {
                                                            date: timeSlots[i].AvailableStartTime.toISOString().split("T")[0],
                                                            slot:
                                                                timeSlots[i].AvailableStartTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) +
                                                                " - " +
                                                                timeSlots[i + 1].AvailableEndTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
                                                            duration: durationMinutes,
                                                            slotID: timeSlots[i].TimeSlotID,
                                                            start: startTime,
                                                            end: endTime,
                                                        }

                                                        obj.push({
                                                            employeeID: timeSlots[i]?.TempEmpID,
                                                            name: `${timeSlots[i]?.varEmployeeFirstName}  ${timeSlots[i]?.varEmployeeLastName}`,
                                                            occupation: "Groomer",
                                                            rating: 4,
                                                            image: timeSlots[i]?.varEmployeeImagePath,
                                                            dates: [
                                                                {
                                                                    date: objj.date,
                                                                    slots: [objj],
                                                                },
                                                            ],
                                                        })
                                                    } else {
                                                        const batherStartTime = timeSlots[i].AvailableStartTime
                                                        const batherEndTime = timeSlots[i].AvailableEndTime

                                                        const batherDurationMilliseconds = batherEndTime - batherStartTime
                                                        const batherDurationMinutes = Math.floor(batherDurationMilliseconds / (1000 * 60))

                                                        let objjBather = {
                                                            date: timeSlots[i].AvailableStartTime.toISOString().split("T")[0],
                                                            slot:
                                                                timeSlots[i].AvailableStartTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) +
                                                                " - " +
                                                                timeSlots[i].AvailableEndTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
                                                            duration: batherDurationMinutes,
                                                            slotID: timeSlots[i].DealTimeSlotID,
                                                            start: batherStartTime,
                                                            end: batherEndTime,
                                                            type: "Bather",
                                                        }

                                                        // obj.push({
                                                        //     employeeID: timeSlots[i]?.TempEmpID,
                                                        //     name: `${timeSlots[i]?.varEmployeeFirstName}  ${timeSlots[i]?.varEmployeeLastName}`,
                                                        //     occupation: "Bather",
                                                        //     rating: 4,
                                                        //     image: timeSlots[i]?.varEmployeeImagePath,
                                                        //     dates: [
                                                        //         {
                                                        //             date: objjBather.date,
                                                        //             slots: [objjBather],
                                                        //         },
                                                        //     ],
                                                        // })

                                                        const groomerStartTime = timeSlots[i + 1].AvailableStartTime
                                                        const groomerEndTime = timeSlots[i + 1].AvailableEndTime

                                                        const groomerDurationMilliseconds = groomerEndTime - groomerStartTime
                                                        const groomerDurationMinutes = Math.floor(groomerDurationMilliseconds / (1000 * 60))

                                                        let objjGroomer = {
                                                            date: timeSlots[i + 1].AvailableStartTime.toISOString().split("T")[0],
                                                            slot:
                                                                timeSlots[i + 1].AvailableStartTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) +
                                                                " - " +
                                                                timeSlots[i + 1].AvailableEndTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
                                                            duration: groomerDurationMinutes,
                                                            slotID: timeSlots[i + 1].TimeSlotID,
                                                            start: groomerStartTime,
                                                            end: groomerEndTime,
                                                            type: "Groomer",
                                                        }

                                                        let objjMainSlot = {
                                                            date: timeSlots[i].AvailableStartTime.toISOString().split("T")[0],
                                                            slot:
                                                                timeSlots[i].AvailableStartTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) +
                                                                " - " +
                                                                timeSlots[i + 1].AvailableEndTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
                                                            duration: groomerDurationMinutes + batherDurationMinutes,
                                                            slotID: timeSlots[i].TimeSlotID,
                                                            start: batherStartTime,
                                                            end: groomerEndTime,
                                                            type: "Main",
                                                        }

                                                        obj.push({
                                                            employeeID: timeSlots[i + 1]?.TempEmpID,
                                                            name: `${timeSlots[i + 1]?.varEmployeeFirstName}  ${timeSlots[i + 1]?.varEmployeeLastName}`,
                                                            occupation: "Groomer",
                                                            rating: 4,
                                                            image: timeSlots[i + 1]?.varEmployeeImagePath,
                                                            dates: [
                                                                {
                                                                    date: objjGroomer.date,
                                                                    slots: [objjMainSlot, objjGroomer, objjBather],
                                                                },
                                                            ],
                                                        })
                                                    }
                                                }
                                            } else {
                                                const startTime = timeSlots[i].AvailableStartTime
                                                const endTime = timeSlots[i].AvailableEndTime

                                                const durationMilliseconds = endTime - startTime
                                                const durationMinutes = Math.floor(durationMilliseconds / (1000 * 60))

                                                let objj = {
                                                    date: timeSlots[i].AvailableStartTime.toISOString().split("T")[0],
                                                    slot:
                                                        timeSlots[i].AvailableStartTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) +
                                                        " - " +
                                                        timeSlots[i].AvailableEndTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
                                                    duration: durationMinutes,
                                                    slotID: timeSlots[i].TimeSlotID,
                                                    start: startTime,
                                                    end: endTime,
                                                }

                                                obj.push({
                                                    employeeID: timeSlots[i]?.TempEmpID,
                                                    name: `${timeSlots[i]?.varEmployeeFirstName}  ${timeSlots[i]?.varEmployeeLastName}`,
                                                    occupation: "Groomer",
                                                    rating: 4,
                                                    image: timeSlots[i]?.varEmployeeImagePath,
                                                    dates: [
                                                        {
                                                            date: objj.date,
                                                            slots: [objj],
                                                        },
                                                    ],
                                                })
                                            }
                                        }

                                        // Group objects by employeeID and date
                                        const groupedByEmployeeAndDate = {}

                                        obj.forEach((slot) => {
                                            const employeeIDKey = slot.employeeID
                                            const dateKey = slot.dates[0].date

                                            if (!groupedByEmployeeAndDate[employeeIDKey]) {
                                                groupedByEmployeeAndDate[employeeIDKey] = {}
                                            }

                                            if (!groupedByEmployeeAndDate[employeeIDKey][dateKey]) {
                                                groupedByEmployeeAndDate[employeeIDKey][dateKey] = []
                                            }

                                            groupedByEmployeeAndDate[employeeIDKey][dateKey].push(slot.dates[0])
                                        })

                                        console.log("grouped", groupedByEmployeeAndDate)

                                        // Convert the grouped data to the final format
                                        // const finalResult = Object.keys(groupedByEmployeeAndDate).map((employeeID) => {
                                        //     return {
                                        //         employeeID: employeeID,
                                        //         name: `${timeSlots.find((slot) => slot.TempEmpID.toString() === employeeID)?.varEmployeeFirstName} ${
                                        //             timeSlots.find((slot) => slot.TempEmpID.toString() === employeeID)?.varEmployeeLastName
                                        //         }`,
                                        //         occupation: "Groomer",
                                        //         rating: 4,
                                        //         image: timeSlots.find((slot) => slot.TempEmpID.toString() === employeeID)?.varEmployeeImagePath,
                                        //         dates: Object.keys(groupedByEmployeeAndDate[employeeID]).map((date) => {
                                        //             return {
                                        //                 date: date,
                                        //                 slots: groupedByEmployeeAndDate[employeeID][date],
                                        //             }
                                        //         }),
                                        //     }
                                        // })

                                        const finalResult = Object.keys(groupedByEmployeeAndDate).map((employeeID) => {
                                            // Map each employee to their respective object
                                            return {
                                                employeeID: employeeID,
                                                name: `${timeSlots.find((slot) => slot.TempEmpID.toString() === employeeID)?.varEmployeeFirstName} ${
                                                    timeSlots.find((slot) => slot.TempEmpID.toString() === employeeID)?.varEmployeeLastName
                                                }`,
                                                occupation: "Groomer",
                                                rating: 4,
                                                image: timeSlots.find((slot) => slot.TempEmpID.toString() === employeeID)?.varEmployeeImagePath,
                                                dates: Object.keys(groupedByEmployeeAndDate[employeeID])
                                                    // Sort dates in ascending order
                                                    .sort((a, b) => {
                                                        return new Date(a).getTime() - new Date(b).getTime()
                                                    })
                                                    .map((date) => {
                                                        // Map each date to their respective object
                                                        return {
                                                            date: date,
                                                            slots: groupedByEmployeeAndDate[employeeID][date],
                                                        }
                                                    }),
                                            }
                                        })

                                        console.log("final", finalResult)
                                        if (finalResult.length < 1) {
                                            setSlotMsg("No slots found..")
                                            setSelectedDate({ empIndex: "", dateIndex: "" })
                                            const serPet = [...servicePets]
                                            serPet[res_pet_index].slotInfo = {}
                                            setServicePets(serPet)
                                        } else {
                                            setSelectedDate({ empIndex: 0, dateIndex: 0 })
                                            const serPet = [...servicePets]
                                            serPet[res_pet_index].slotInfo = {
                                                slot: finalResult[0]?.dates?.[0]?.slots?.[0],
                                                empIndex: 0,
                                                dateIndex: 0,
                                                slotIndex: 0,
                                            }
                                            setServicePets(serPet)
                                        }
                                        console.log("slot employees", finalResult)
                                        setSlotEmployeeList(finalResult)
                                        props.allSlots?.(finalResult)

                                        setIsLoading(false)
                                    })
                                    .catch(function (error) {
                                        console.log(error)
                                        setIsLoading(false)
                                    })
                            } else {
                                toast.info("Please select a date!")
                                setIsLoading(false)
                            }
                        } else {
                            toast.info("Please select a service!")
                            setSlotEmployeeList([])
                            setIsLoading(false)
                        }
                    } else {
                        toast.info("Please select a service!")
                        setSlotEmployeeList([])
                        setIsLoading(false)
                    }
                })
                .catch((error) => {
                    console.log("error", error)
                })
        } else {
            toast.info("Please pick a pet")
            setIsLoading(false)
            setSlotEmployeeList([])
        }
    }

    useEffect(() => {
        if (reservationPet !== "") {
            let res_pet_index = servicePets.findIndex((x) => x.id === parseInt(reservationPet))
            setResPetIndex(res_pet_index)
        } else {
            setSelectedDate({ empIndex: "", dateIndex: "" })
            setSlotEmployeeList([])
        }
    }, [reservationPet])

    const selectSlot = (slot, empIndex, dateIndex, slotIndex) => {
        console.log(slot, empIndex, dateIndex, slotIndex)
        const serPet = [...servicePets]
        serPet[resPetIndex].slotInfo = {
            slot: slot,
            empIndex: empIndex,
            dateIndex: dateIndex,
            slotIndex: slotIndex,
        }
        setServicePets(serPet)
        setIsOpenSlotPicker(false)
    }

    const selectPrevAppointment = (index) => {
        const petList = [...servicePets]
        props.pastSelectedAppointment((prevIndex) => {
            if (prevIndex === index) {
                return ""
            } else {
                return index
            }
        })
        setSelectedPastAppointment((prevIndex) => {
            if (prevIndex === index) {
                let res_pet_index = servicePets.findIndex((x) => x.id === parseInt(reservationPet))
                console.log("resPet", res_pet_index)

                console.log("resPet", servicePets[res_pet_index].additionalService)
                let serviceProductData = [] as any

                servicePets[res_pet_index].additionalService.map((item, index) => {
                    if (item.orderID !== 0) {
                        serviceProductData.push({
                            serviceOrProductID: item.id,
                            type: "S",
                        })
                    }
                })

                let deSelectPayload = {
                    numCustomerID: servicePets[res_pet_index].customerID,
                    numPetID: servicePets[res_pet_index].id,
                    numLocationID: bookingLocation,
                    date: currDate,
                    serviceProductData: serviceProductData,
                }

                console.log("de", deSelectPayload)

                removeProductOrServiceFromHoldingTank(deSelectPayload)

                petList[0].additionalService = []
                setSlotEmployeeList([])
                // const prodList = [...productList] as any
                // petList.selectedProducts.map((prodSelected, prodSelectedIndex) => {
                //     const matchingMainProduct = productList.findIndex((mainProduct) => prodSelected.numProductID === mainProduct.numProductID)
                //     prodList[matchingMainProduct].TempAvailableQuantity = prodList[matchingMainProduct].TempAvailableQuantity + prodSelected.numQuantity
                //     prodList[matchingMainProduct].SelectedQuantity = 0
                //     prodList[matchingMainProduct].isAddedToCart = false
                //     setProductList(prodList)
                // })
                // petList.selectedProducts = []
                // petList.preferredLocation = props.customerData.customer?.customerPet[swiperSelectedPet].numPetDefaultGroomLocationID
                setServicePets(petList)

                setPetNotes([])

                return ""
            } else {
                getLocationServiceItemsPriceList(
                    pastAppointmentList[index].numLocationID,
                    currDate,
                    servicePets[0].breedID,
                    servicePets[0].sizeID,
                    servicePets[0].employeeID,
                    servicePets[0].employeeName,
                    servicePets[0].id,
                    servicePets[0].petType
                )
                    .then((data) => {
                        if (data.Services !== undefined) {
                            setAdditionalServiceList(data.Services)
                            console.log("res - All services with SP-selected services", data.Services)
                            pastAppointmentList[index].ServicesList.map((pastService, pastIndex) => {
                                data.Services.map((mainService, mainIndex) => {
                                    if (pastService.ServiceID === mainService.numServiceItemID) {
                                        let additional = {
                                            id: mainService.numServiceItemID,
                                            name: mainService.varServiceItemName,
                                            price: mainService.numServiceItemSalesPrice,
                                            desc: mainService.varServiceItemDescription,
                                            orderID: mainService.numOrderID,
                                            taxAmount: calcTax(
                                                mainService.numStorePromotionDiscount,
                                                mainService.numSeasonalPromotionDiscount,
                                                mainService.numServiceItemSalesPrice,
                                                mainService.numSalesTaxPercentage
                                            ),
                                            numLocationServiceProductItemID: mainService.numLocationServiceItemID,
                                            numSalesTaxPercentage: mainService.numSalesTaxPercentage,
                                            numSeasonalPromotionDiscount: mainService.numSeasonalPromotionDiscount,
                                            numStorePromotionDiscount: mainService.numStorePromotionDiscount,
                                        }
                                        petList[0].additionalService.push(additional)
                                    } else {
                                        //console.log("failed to add service ", pastService.ServiceID, " since its not available on", currDate)
                                    }
                                })
                            })

                            console.log()
                            setServicePets(petList)

                            getSlots()
                            handleHoldingTank()
                            setIsLoading(false)
                        } else {
                            setAdditionalServiceList([])
                            setIsLoading(false)
                        }
                    })
                    .catch((error) => {
                        console.log("error", error)
                    })
                // getLocationProductItemsPriceList(pastAppointmentList[index].numLocationID, currDate, servicePet.employeeID, servicePet.employeeName, servicePet.id)
                //     .then((data) => {
                //         console.log("res - All products list", data)
                //         const pList = [] as any
                //         const cartList = { ...servicePet }

                //         pastAppointmentList[index].ProductsList.forEach((pastProduct, pastIndex) => {
                //             const matchingMainProduct = data.find((mainProduct) => pastProduct.numProductID === mainProduct.numProductID)

                //             if (matchingMainProduct) {
                //                 matchingMainProduct.SelectedQuantity = pastProduct.numQuantity

                //                 let selected = matchingMainProduct.SelectedQuantity
                //                 let totalAvailable = matchingMainProduct.numAvailableQuantity

                //                 if (selected >= totalAvailable) {
                //                     console.log("failed to add product ", pastProduct.numProductID, " since it's low on quantity", currDate)
                //                 } else {
                //                     matchingMainProduct.TempAvailableQuantity = matchingMainProduct.TempAvailableQuantity - selected
                //                 }

                //                 cartList.selectedProducts.push({
                //                     numOrderID: null,
                //                     numInvoiceID: null,
                //                     numLocationID: servicePet?.preferredLocation,
                //                     numSchID: null,
                //                     numAppointmentID: null,
                //                     numSchEventID: null,
                //                     numPetTypeID: servicePet.petType,
                //                     numPetSizeID: servicePet.sizeID,
                //                     numPetBreedID: servicePet.breedID,
                //                     numGroomerID: 1,
                //                     numBatherID: null,
                //                     numSalesPersonID: 1,
                //                     decUnit_Price: matchingMainProduct.numProductSalesPrice,
                //                     decChargeAmount: matchingMainProduct.numProductSalesPrice,
                //                     decTaxAmt:
                //                         parseFloat(
                //                             calcTax(
                //                                 matchingMainProduct.numStorePromotionDiscount,
                //                                 matchingMainProduct.numSeasonalPromotionDiscount,
                //                                 matchingMainProduct.numProductSalesPrice,
                //                                 matchingMainProduct.numSalesTaxPercentage
                //                             )
                //                         ) * matchingMainProduct.SelectedQuantity,
                //                     dtBegin_date_of_service: null,
                //                     dtEnd_date_of_service: null,
                //                     varStatus: "",
                //                     numSeq_nbr: null,
                //                     numReferringCustomerID: 154,
                //                     varReferringCustomerName: "Kewin 3",
                //                     numCancelReasonID: null,
                //                     varCancelReason: "",
                //                     numReasonCodeID: null,
                //                     varReasonCodeDescription: "",
                //                     numEmployeeID: 1,
                //                     varEmployeeName: "Sean",
                //                     varServiceProductDescription: matchingMainProduct.varProductDescription === null ? "" : matchingMainProduct.varProductDescription,
                //                     numLocationProductID: matchingMainProduct.numLocationProductID,
                //                     varProductBarCode: matchingMainProduct.varProductBarCode,
                //                     numProductID: matchingMainProduct.numProductID,
                //                     varProductVendorID: matchingMainProduct.varProductVendorID,
                //                     varProductName: matchingMainProduct.varProductName,
                //                     varProductDescription: matchingMainProduct.varProductDescription,
                //                     LocID: matchingMainProduct.LocID,
                //                     numCustomerID: servicePet.customerID,
                //                     ServiceType: matchingMainProduct.ServiceType,
                //                     dtEffectiveDate: matchingMainProduct.dtEffectiveDate,
                //                     dtExpiryDate: matchingMainProduct.dtExpiryDate,
                //                     numProductSalesPrice: matchingMainProduct.numProductSalesPrice,
                //                     varStorePromotionMsg: matchingMainProduct.varStorePromotionMsg,
                //                     numStorePromotionDiscount: matchingMainProduct.numStorePromotionDiscount,
                //                     varSeasonalPromotionMsg: matchingMainProduct.varSeasonalPromotionMsg,
                //                     numSeasonalPromotionDiscount: matchingMainProduct.numSeasonalPromotionDiscount,
                //                     bitTaxableItem: matchingMainProduct.bitTaxableItem,
                //                     numSalesTaxPercentage: matchingMainProduct.numSalesTaxPercentage,
                //                     TotalTaxAmount: matchingMainProduct.TotalTaxAmount,
                //                     numAvailableQuantity: matchingMainProduct.numAvailableQuantity,
                //                     numQuantity: matchingMainProduct.SelectedQuantity,
                //                     bitActivePromotionFlag: matchingMainProduct.bitActivePromotionFlag,
                //                     varLinkToPromotionMaterial: matchingMainProduct.varLinkToPromotionMaterial,
                //                     varLinkToGlobalCoupon: matchingMainProduct.varLinkToGlobalCoupon,
                //                     varMessageToEmployee: matchingMainProduct.varMessageToEmployee,
                //                     varPromotionalMessage: matchingMainProduct.varPromotionalMessage,
                //                     numLocationSpecificAdjustmentPercent: matchingMainProduct.numLocationSpecificAdjustmentPercent,
                //                     numLocationSpecificAdjustmentAmount: matchingMainProduct.numLocationSpecificAdjustmentAmount,
                //                     numDateSpecificAdjustmentPercent: matchingMainProduct.numDateSpecificAdjustmentPercent,
                //                     numDateSpecificAdjustmentAmount: matchingMainProduct.numDateSpecificAdjustmentAmount,
                //                     varSalesCommissionMessage: matchingMainProduct.varSalesCommissionMessage,
                //                     btSalesCommissionPercent: matchingMainProduct.btSalesCommissionPercent,
                //                     numSalesCommissionPercent: matchingMainProduct.numSalesCommissionPercent,
                //                     btSalesCommissionAmount: matchingMainProduct.btSalesCommissionAmount,
                //                     numSalesCommissionAmount: matchingMainProduct.numSalesCommissionAmount,
                //                     bitAutoReOrder: matchingMainProduct.bitAutoReOrder,
                //                     numMinimumQuantityReOrderLevel: matchingMainProduct.numMinimumQuantityReOrderLevel,
                //                     numMaximumLocationQuantity: matchingMainProduct.numMaximumLocationQuantity,
                //                     bitActive: matchingMainProduct.bitActive,
                //                     numServiceProductItemID: matchingMainProduct.numServiceProductItemID,
                //                     varServiceOrProduct: "P",
                //                     numPetID: servicePet.id,
                //                     DOS: matchingMainProduct.DOS,
                //                     OrderSelected: matchingMainProduct.OrderSelected,
                //                     ChargeEntered: matchingMainProduct.ChargeEntered,
                //                     PaymentEntered: matchingMainProduct.PaymentEntered,
                //                     numLocationServiceProductItemID: matchingMainProduct.numLocationProductID,
                //                 })
                //                 matchingMainProduct.SelectedQuantity = 0
                //                 matchingMainProduct.isAddedToCart = true

                //                 pList.push(matchingMainProduct)
                //             } else {
                //                 console.log("Product not found in the main list for numProductID:", pastProduct.numProductID)
                //             }
                //         })

                //         const remainingProducts = data.filter((mainProduct) => !pastAppointmentList[index].ProductsList.some((pastProduct) => pastProduct.numProductID === mainProduct.numProductID))
                //         pList.push(...remainingProducts)

                //         setProductList(pList)
                //         setServicePet(cartList)
                //         setIsLoading(false)
                //     })
                //     .catch((error) => {
                //         console.log("error", error)
                //     })

                // setPetNotes([        {
                //     "pID": 1,
                //     "name": "Besterr",
                //     "breed": "Foxhound",
                //     "sendTo": [
                //         {
                //             id: 1,
                //             value: "Staff"
                //         },
                //         {
                //             id: 2,
                //             value: "Groomer"
                //         }
                //     ],
                //     "note": "Note from past appointment",
                //     "date": "2023-12-27T17:38:05.3319281",
                //     "img": "2088.jpg"
                // },
                // {
                //     "pID": 1,
                //     "name": "Besterr",
                //     "breed": "Foxhound",
                //     "sendTo": [
                //         {
                //             id: 1,
                //             value: "Staff"
                //         },
                //         {
                //             id: 2,
                //             value: "Bathers"
                //         }
                //     ],
                //     "note": "Note from past appointment",
                //     "date": "2023-12-26T17:38:05.3319281",
                //     "img": "2088.jpg"
                // }])

                toast.success("Appointment selected!")

                return index
            }
        })
    }

    const [petNotes, setPetNotes] = useState<any>([])

    const handleUploadICS = (event, fileName, appID) => {
        const icsData = createICSData(event)
        downloadICSFile(icsData, fileName, appID, event)
    }

    const downloadICSFile = (icsData, fileName, appID, event) => {
        const blob = new Blob([icsData], { type: "text/calendar" })
        const formData = new FormData()
        formData.append("folderpath", "email_attachments/ics_files")
        formData.append("method", "N")
        formData.append("deletingFileName", "-")
        formData.append("renameFileNames", "0")

        const blobname = fileName
        const renamedFile = new File([blob], `${blobname}.ics`, { type: blob.type })
        formData.append("files", renamedFile)

        axios
            .post(`${process.env.REACT_APP_API_URL}FileUpload/UploadFile`, formData)
            .then((response) => {
                console.log("Upload-Res", response.data.files[0])
                //Send Out Message
                let sendFiles = [
                    {
                        messageID: 1,
                        fileName: response.data.files[0]?.renamedFileName + ".ics",
                        filePath: `${process.env.REACT_APP_MEDIA_URL}email_attachments/ics_files/${response.data.files[0]?.renamedFileName}.ics`,
                    },
                ]
                sendAppointmentStaticMessage(appID, "Appointment", 1, "E", sendFiles)

                let fileAttachments = `<p>Click <a href="${process.env.REACT_APP_MEDIA_URL}email_attachments/ics_files/${response.data.files[0]?.renamedFileName}.ics" download="appointment.ics">here</a> to download the ICS file for your appointment.</p>`
                //Notification
                let notificationPostBody = {
                    notificationType: "success",
                    subject: "Appointment Confirmation Ref#:" + appID,
                    notificationMessage: event?.description + fileAttachments,
                    locationID: event?.locationID,
                    locationName: event?.location,
                    recipientType: "C",
                    recipientID: event?.recipientID,
                    recipientName: event?.recipientName,
                    isSeen: false,
                }
                sendOutNotification(notificationPostBody)
            })
            .catch((error) => {
                console.error(error)
            })
        // const link = document.createElement('a');
        // link.href = URL.createObjectURL(blob);
        // link.download = `${fileName}.ics`;
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
    }

    const createICSData = (event) => {
        const { title, description, startTime, endTime, location, email } = event
        const startDate = formatICSDate(new Date(startTime))
        const endDate = formatICSDate(new Date(endTime))

        return [
            "BEGIN:VCALENDAR",
            "VERSION:2.0",
            "BEGIN:VEVENT",
            `UID:${createUUID()}`,
            `SUMMARY:${title}`,
            `DESCRIPTION:${description}`,
            `DTSTART:${startDate}`,
            `DTEND:${endDate}`,
            `LOCATION:${location}`,
            `ORGANIZER:mailto:${email}`,
            "END:VEVENT",
            "END:VCALENDAR",
        ].join("\n")
    }

    const formatICSDate = (date) => {
        return date.toISOString().replace(/-|:|\.\d{3}/g, "") + "Z"
    }

    const createUUID = () => {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
            const r = (Math.random() * 16) | 0,
                v = c === "x" ? r : (r & 0x3) | 0x8
            return v.toString(16)
        })
    }
    useEffect(() => {
        console.log("st", store)
    }, [store])

    const showPetPer = (index) => {
        setPetPer(props?.petList[index]?.petPersonality)
        setIsOpenPetAlertsModal(true)
    }
    return (
        <>
            <ToastContainer />
            <div>
                <CustomerAlerts isOpenAlertsModal={isOpenAlertsModal} setIsAlertsModal={(value) => setIsAlertsModal(value)} data={props?.customerBooking} />
                <div>
                    {props?.type != "booking" && props?.type != "bookingBlank" ? (
                        <Button variant="text" color="default" className={styles.backButton} onClick={() => props.goBack?.(false)}>
                            <ArrowBack style={{ color: "#000" }} />
                            Back
                        </Button>
                    ) : null}
                    {/* {props?.type === "customer" ? (
                        <div>
                            <div style={{ width: "100%", borderRadius: "5px" }}>
                                <div style={{ padding: "0 2rem", backgroundColor: "#fff", border: "1px solid #F3F4F6 !important", height: "45px !important" }}>
                                    <MaterialTabs value={value} onChange={handleChange}>
                                        <Tab style={{ textTransform: "capitalize", padding: 0, margin: "0 1rem", fontWeight: 600, color: "#2076FF" }} label="Customer Profile" />
                                        <Tab style={{ textTransform: "capitalize", padding: 0, margin: "0 1rem", fontWeight: 600, color: "#2076FF" }} label="Pet Profile" />
                                    </MaterialTabs>
                                </div>

                                <TabPanel value={value} index={0}>
                                    {isLoading ? <p>Loading...</p> : list != undefined && newHeaders != undefined ? <DetailedView type={"customer"} labels={newHeaders} data={list} /> : null}
                                </TabPanel>

                                <TabPanel value={value} index={1}>
                                    {isLoading ? <p>Loading...</p> : list != undefined ? <DetailedView type={"pet"} labels={newHeaders} data={list} /> : null}
                                </TabPanel>
                            </div>
                        </div>
                    ) : props?.type === "petProfile" ? (
                        <div>
                            <div style={{ width: "100%", borderRadius: "5px" }}>
                                <div style={{ padding: "0 2rem", backgroundColor: "#fff" }}>
                                    <MaterialTabs value={value} onChange={handleChange}>
                                        <Tab style={{ textTransform: "capitalize", padding: 0, margin: "0 1rem", fontWeight: 600, color: "#2076FF" }} label="Pet Profile" />
                                    </MaterialTabs>
                                </div>

                                <TabPanel value={value} index={0}>
                                    {isLoading ? <p>Loading...</p> : list != undefined ? <DetailedView type={"petProfile"} labels={newHeaders} data={list} /> : null}
                                </TabPanel>
                            </div>
                        </div>
                    ) : props?.type === "salon" ? (
                        <div>
                            <div style={{ width: "100%", borderRadius: "5px" }}>
                                <div style={{ padding: "0 2rem", backgroundColor: "#fff" }}>
                                    <MaterialTabs value={value} onChange={handleChange}>
                                        <Tab style={{ textTransform: "capitalize", padding: 0, margin: "0 1rem", fontWeight: 600, color: "#2076FF" }} label="Salon Details" />
                                    </MaterialTabs>
                                </div>

                                <TabPanel value={value} index={0}>
                                    {isLoading ? <p>Loading...</p> : list != undefined ? <DetailedView type={"salon"} labels={newHeaders} data={list} /> : null}
                                </TabPanel>
                            </div>
                        </div>
                    ) :  */}
                    {props?.type === "booking" ? (
                        <div>
                            <div style={{ width: "100%", borderRadius: "5px" }}>
                                {/* <div style={{ padding: "0 2rem", backgroundColor: "#fff" }}>
                                    <MaterialTabs value={value} onChange={handleChange}>
                                        <Tab
                                            disabled={!validatePermission(auth, ["02.01 - Schedule / New Booking"])}
                                            style={{ textTransform: "capitalize", padding: 0, margin: "0 1rem", fontWeight: 600, color: "#2076FF" }}
                                            label="New Booking"
                                        />

                                        <Tab
                                            disabled={!validatePermission(auth, ["02.02 - Schedule / Customer Profile"])}
                                            style={{ textTransform: "capitalize", padding: 0, margin: "0 1rem", fontWeight: 600, color: "#2076FF" }}
                                            label="Customer Profile"
                                        />

                                        <Tab
                                            disabled={!validatePermission(auth, ["02.03 - Schedule / Pet Profile"])}
                                            style={{ textTransform: "capitalize", padding: 0, margin: "0 1rem", fontWeight: 600, color: "#2076FF" }}
                                            label="Pet Profile"
                                        />
                                    </MaterialTabs>
                                </div> */}
                                <div style={{ display: "flex", gap: "1rem", overflowX: "scroll", maxWidth: "1080px" }}>
                                    {props.petList.map((pet, index) => {
                                        return (
                                            <div
                                                style={{
                                                    borderRadius: "8px",
                                                    padding: "0 .6rem ",
                                                    boxShadow: "0px 2px 8px rgba(218, 218, 218, 0.1), 0px 1px 4px rgba(97, 97, 97, 0.1)",
                                                    backgroundColor: "#fff",
                                                    border: "1px solid #ededed",
                                                }}
                                            >
                                                <div style={{ display: "flex", alignItems: "center", gap: "1rem", padding: "5px" }}>
                                                    <div
                                                        style={{ fontSize: "12px", padding: "0.5rem 0.8rem", backgroundColor: "#EFF5FF", borderRadius: "5px", fontWeight: "600", whiteSpace: "nowrap" }}
                                                    >
                                                        {pet?.size}
                                                    </div>
                                                    <div style={{ display: "flex" }}>
                                                        <div>
                                                            <p style={{ margin: "0", fontWeight: "600", textTransform: "capitalize" }}>{Truncate(pet?.name, 10)}</p>
                                                            <p className={styles.petMiniCardPBreed}>{Truncate(pet?.breed, 10)}</p>
                                                        </div>

                                                        {pet.petPersonality.length > 0 ? (
                                                            <IconButton
                                                                onClick={() => {
                                                                    showPetPer(index)
                                                                }}
                                                                style={{ margin: "0" }}
                                                            >
                                                                <WarningAmber style={{ color: "red", fontSize: "20px" }} />
                                                            </IconButton>
                                                        ) : null}

                                                        <PetAlerts isOpenPetAlertsModal={isOpenPetAlertsModal} setIsOpenPetAlertsModal={(value) => setIsOpenPetAlertsModal(value)} data={petPer} />
                                                    </div>
                                                    <IconButton
                                                        onClick={() => {
                                                            props.removeFromList?.(pet.id)
                                                            setPastAppointmentList([])
                                                            props.pastAppList()
                                                            props.pastSelectedAppointment("")
                                                            setSlotEmployeeList([])
                                                            if (servicePets[0].id === pet.id) {
                                                                setSelectedPastAppointment("")
                                                            }
                                                        }}
                                                    >
                                                        <Close style={{ color: "#EB1A1A" }} />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <Button color="primary" variant="contained" onClick={() => setIsOpenAppointmentPopup(true)}>
                                        View Details
                                    </Button>
                                </div>

                                <ViewGroomerNote isOpenViewModal={isOpenGroomerNote} setIsOpenViewModal={(value) => setIsOpenGroomerNote(value)} notes={groomerNotes} />

                                <Modal open={isOpenAppointmentPopup}>
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                            <p style={{ color: "#002867", fontSize: "18px", fontWeight: "600", width: "100%" }}>Appointment Details</p>
                                            <CloseButton
                                                onClick={() => {
                                                    setIsOpenAppointmentPopup(false)
                                                }}
                                            />
                                        </div>

                                        <div style={{ backgroundColor: "#fff", width: "100%" }}>
                                            <ValidatePermission allowedModules={["02.01 - Schedule / New Booking"]}>
                                                <TabPanel value={value} index={(tabIndex = 1 && tabIndex + 1)}>
                                                    <div style={{ padding: "10px", overflowY: "scroll", height: "500px" }}>
                                                        {/* <div style={{ overflowX: "scroll", width: "100%", display: "flex", gap: "15px", marginTop: "20px" }}>
                                                {pastAppointmentList.length > 0 ? (
                                                    pastAppointmentList
                                                        .sort((a, b) => b.numAppointmentID - a.numAppointmentID)
                                                        .map((app, index) => {
                                                            return (
                                                                <div
                                                                    className={selectedPastAppointment === index ? styles.pre_app_active : styles.pre_app}
                                                                    onClick={() => {
                                                                        selectPrevAppointment(index)
                                                                    }}
                                                                >
                                                                    <div style={{ width: "250px" }}>
                                                                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                                                                            <p style={{ margin: "0 0 5px 0", fontWeight: 600, fontSize: "16px", color: "#1976d2" }}>#{app?.numAppointmentID}</p>
                                                                            <span style={{ color: "black", fontSize: "14px", fontWeight: "500" }}>({formatToLocal(app?.dtApptStartTime)})</span>
                                                                        </div>

                                                                        <p style={{ margin: "0 ", fontSize: "13px" }}>
                                                                            <b>Location: </b>
                                                                            {Truncate(app?.varLocationName, 22)}
                                                                        </p>
                                                                        <p style={{ margin: "0 ", fontSize: "13px" }}>
                                                                            <b>Groomer: </b>
                                                                            {app.ServicesList[0]?.varEmployeeFirstName + " " + app.ServicesList[0]?.varEmployeeLastName}
                                                                        </p>
                                                                        {app.ServicesList[0]?.DealerFirstName !== null ? (
                                                                            <p style={{ margin: "0 ", fontSize: "13px" }}>
                                                                                <b>Bather: </b>
                                                                                {app.ServicesList[0]?.DealerFirstName + " " + app.ServicesList[0]?.DealerLastName}
                                                                            </p>
                                                                        ) : null}
                                                                        <div style={{ overflowX: "hidden", overflowY: "hidden", width: "calc(100%)", display: "flex", gap: "5px", marginTop: "10px" }}>
                                                                            {app.ServicesList.map((service, appIndex) => (
                                                                                <div key={appIndex} style={{ cursor: "pointer" }}>
                                                                                    <div
                                                                                        style={{
                                                                                            backgroundColor: "#DCFCE7",
                                                                                            border: "1px solid #86EFAC",
                                                                                            borderRadius: "16px",
                                                                                            color: "#14532D",
                                                                                            fontSize: "13px",
                                                                                            fontWeight: "500",
                                                                                            display: "flex",
                                                                                            justifyContent: "space-between",
                                                                                            alignItems: "center",
                                                                                            padding: "0 10px 0 10px",
                                                                                            gap: ".2rem",
                                                                                            width: "max-content",
                                                                                        }}
                                                                                    >
                                                                                        {Truncate(service?.varServiceItemName, 20)}
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>

                                                                        <div style={{ overflowX: "hidden", overflowY: "hidden", width: "calc(100%)", display: "flex", gap: "5px", marginTop: "5px" }}>
                                                                            {app.ProductsList.map((product, proIndex) => (
                                                                                <div key={proIndex} style={{ cursor: "pointer" }}>
                                                                                    <div
                                                                                        style={{
                                                                                            backgroundColor: "#DCFCE7",
                                                                                            border: "1px solid #86EFAC",
                                                                                            borderRadius: "16px",
                                                                                            color: "#14532D",
                                                                                            fontSize: "13px",
                                                                                            fontWeight: "500",
                                                                                            display: "flex",
                                                                                            justifyContent: "space-between",
                                                                                            alignItems: "center",
                                                                                            padding: "0 10px 0 10px",
                                                                                            gap: ".2rem",
                                                                                            width: "max-content",
                                                                                        }}
                                                                                    >
                                                                                        {Truncate(product?.varProductName, 15)}{" "}
                                                                                        <span style={{ margin: "0", borderRight: "1px solid #86efac" }}>&nbsp;</span> {product?.numQuantity}
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                ) : props.petList.length > 0 ? (
                                                    <p style={{ color: "#777E90", fontWeight: "400", fontSize: "13px" }}>No previous appointments</p>
                                                ) : null}
                                            </div> */}
                                                        <h3 style={{ color: "rgb(0, 40, 103)", margin: "1.5rem 1.5rem 0.5rem" }}>Past Appointments</h3>
                                                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", gap: "15px" }}>
                                                            <div style={{ overflowX: "scroll", width: "8 00px", display: "flex", gap: "15px", marginTop: "20px", alignItems: "flex-start" }}>
                                                                {pastAppointmentList.length > 0 ? (
                                                                    pastAppointmentList.map((app, index) => {
                                                                        return (
                                                                            <div
                                                                                className={selectedPastAppointment === index ? styles.pre_app_active : styles.pre_app}
                                                                                onClick={() => {
                                                                                    selectPrevAppointment(index)
                                                                                }}
                                                                            >
                                                                                <div style={{ width: "250px" }}>
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            width: "100%",
                                                                                            justifyContent: "space-between",
                                                                                            alignItems: "center",
                                                                                            backgroundColor: app?.IsPreferred ? "#fffee6" : undefined,
                                                                                            borderRadius: "8px",
                                                                                            border: app?.IsPreferred ? "1px solid #fffee6" : undefined,
                                                                                            padding: "0px 1px 0px 1px",
                                                                                        }}
                                                                                    >
                                                                                        <div style={{ display: "flex", gap: "2px", alignItems: "center" }}>
                                                                                            <p style={{ fontWeight: 600, fontSize: "16px", color: "#1976d2" }}>#{app?.numAppointmentID}</p>
                                                                                            {app?.IsPreferred ? (
                                                                                                <>
                                                                                                    <Verified style={{ color: "#edb80b" }} />
                                                                                                    <p style={{ color: "#d3a71a", fontWeight: "600" }}>Customer's Pick</p>
                                                                                                </>
                                                                                            ) : null}
                                                                                        </div>
                                                                                        <span style={{ color: "black", fontSize: "14px", fontWeight: "500" }}>
                                                                                            ({formatToLocal(app?.dtApptStartTime)})
                                                                                        </span>
                                                                                    </div>

                                                                                    <p style={{ margin: "0 ", fontSize: "13px" }}>
                                                                                        <b>Location: </b>
                                                                                        {Truncate(app?.varLocationName, 22)}
                                                                                    </p>
                                                                                    <p style={{ margin: "0 ", fontSize: "13px" }}>
                                                                                        <b>Groomer: </b>
                                                                                        {app.ServicesList[0]?.varEmployeeFirstName + " " + app.ServicesList[0]?.varEmployeeLastName}
                                                                                    </p>
                                                                                    {app.ServicesList[0]?.DealerFirstName !== null ? (
                                                                                        <p style={{ margin: "0 ", fontSize: "13px" }}>
                                                                                            <b>Bather: </b>
                                                                                            {app.ServicesList[0]?.DealerFirstName + " " + app.ServicesList[0]?.DealerLastName}
                                                                                        </p>
                                                                                    ) : null}
                                                                                    <div
                                                                                        style={{
                                                                                            overflowX: "hidden",
                                                                                            overflowY: "hidden",
                                                                                            width: "calc(100%)",
                                                                                            display: "flex",
                                                                                            gap: "5px",
                                                                                            marginTop: "10px",
                                                                                        }}
                                                                                    >
                                                                                        {app.ServicesList.map((service, appIndex) => (
                                                                                            <div key={appIndex} style={{ cursor: "pointer" }}>
                                                                                                <div
                                                                                                    style={{
                                                                                                        backgroundColor: "#DCFCE7",
                                                                                                        border: "1px solid #86EFAC",
                                                                                                        borderRadius: "16px",
                                                                                                        color: "#14532D",
                                                                                                        fontSize: "13px",
                                                                                                        fontWeight: "500",
                                                                                                        display: "flex",
                                                                                                        justifyContent: "space-between",
                                                                                                        alignItems: "center",
                                                                                                        padding: "0 10px 0 10px",
                                                                                                        gap: ".2rem",
                                                                                                        width: "max-content",
                                                                                                    }}
                                                                                                >
                                                                                                    {Truncate(service?.varServiceItemName, 20)}
                                                                                                </div>
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>

                                                                                    <div
                                                                                        style={{
                                                                                            overflowX: "hidden",
                                                                                            overflowY: "hidden",
                                                                                            width: "calc(100%)",
                                                                                            display: "flex",
                                                                                            gap: "5px",
                                                                                            marginTop: "5px",
                                                                                        }}
                                                                                    >
                                                                                        {app.ProductsList.map((product, proIndex) => (
                                                                                            <div key={proIndex} style={{ cursor: "pointer" }}>
                                                                                                <div
                                                                                                    style={{
                                                                                                        backgroundColor: "#DCFCE7",
                                                                                                        border: "1px solid #86EFAC",
                                                                                                        borderRadius: "16px",
                                                                                                        color: "#14532D",
                                                                                                        fontSize: "13px",
                                                                                                        fontWeight: "500",
                                                                                                        display: "flex",
                                                                                                        justifyContent: "space-between",
                                                                                                        alignItems: "center",
                                                                                                        padding: "0 10px 0 10px",
                                                                                                        gap: ".2rem",
                                                                                                        width: "max-content",
                                                                                                    }}
                                                                                                >
                                                                                                    {Truncate(product?.varProductName, 15)}{" "}
                                                                                                    <span style={{ margin: "0", borderRight: "1px solid #86efac" }}>&nbsp;</span> {product?.numQuantity}
                                                                                                </div>
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                ) : props.petList.length > 0 ? (
                                                                    <p style={{ color: "#777E90", fontWeight: "400", fontSize: "13px" }}>No previous appointments</p>
                                                                ) : null}
                                                            </div>
                                                            <div>
                                                                {selectedPastAppointment === "" ? null : pastAppointmentList[selectedPastAppointment].BeforeImgPath === null &&
                                                                  pastAppointmentList[selectedPastAppointment].AfterImagePath === null ? (
                                                                    <p style={{ color: "gray", textAlign: "center" }}>No images from past appointments</p>
                                                                ) : (
                                                                    <div style={{ backgroundColor: "#fff" }}>
                                                                        <div style={{ width: "100%", borderRadius: ".5rem" }}>
                                                                            {pastAppointmentList.length > 0 ? (
                                                                                <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                                                                    <ImgComparisonSlider style={{ width: "90%", height: "auto" }}>
                                                                                        <img
                                                                                            style={{ height: "200px", width: "100%", objectFit: "cover" }}
                                                                                            alt="404"
                                                                                            slot="first"
                                                                                            src={`${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${pastAppointmentList[selectedPastAppointment]?.BeforeImgPath}`}
                                                                                        />
                                                                                        <img
                                                                                            style={{ height: "200px", width: "100%", objectFit: "cover" }}
                                                                                            alt="404"
                                                                                            slot="second"
                                                                                            src={`${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${pastAppointmentList[selectedPastAppointment]?.AfterImagePath}`}
                                                                                        />
                                                                                    </ImgComparisonSlider>
                                                                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                                                                        <Button
                                                                                            color="default"
                                                                                            variant="outlined"
                                                                                            text="View groomer note"
                                                                                            onClick={() => {
                                                                                                openGroomerNote(pastAppointmentList[selectedPastAppointment]?.GroomerNoteSummary)
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <div style={{ display: "flex", alignItems: "center", padding: "2rem", gap: "1rem", justifyContent: "center" }}>
                                                                                    <p>--</p>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <h3 style={{ color: "#002867", margin: "1.5rem 1.5rem .5rem 1.5rem" }}>Location</h3>
                                                        <div style={{ backgroundColor: "#fff" }}>
                                                            <div style={{ width: "100%", borderRadius: ".5rem", border: "1px solid #F3F4F6" }}>
                                                                <div style={{ padding: "1rem" }}>
                                                                    <div style={{ display: "flex", gap: "2rem", alignItems: "end" }}>
                                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                                            <Checkbox checked={isTypeable} color="primary" onChange={(e) => handleEdit()} />
                                                                            <p>Edit</p>
                                                                        </div>
                                                                        <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                                                                            {customerAddress !== undefined && customerAddress !== null ? (
                                                                                <Input
                                                                                    value={locName}
                                                                                    size={"small"}
                                                                                    outline={true}
                                                                                    variant={"outlined"}
                                                                                    label={"Customer location"}
                                                                                    items={
                                                                                        isTypeable
                                                                                            ? null
                                                                                            : customerAddress.map((i) => ({
                                                                                                  text:
                                                                                                      i.varCustomerAddress1 +
                                                                                                      " " +
                                                                                                      i.varCustomerCity +
                                                                                                      ", " +
                                                                                                      i.varCustomerState +
                                                                                                      " " +
                                                                                                      i.varCustomerZip,
                                                                                                  value: i.varCustomerAddress1 + " " + i.varCustomerCity + ", " + i.varCustomerState,
                                                                                              }))
                                                                                    }
                                                                                    onChange={(e) => {
                                                                                        setLocationAsSelected(e)
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                "Loading.."
                                                                            )}
                                                                            {isTypeable ? (
                                                                                <IconButton
                                                                                    onClick={() => {
                                                                                        fetch_directions()
                                                                                    }}
                                                                                >
                                                                                    <Loop style={{ fontSize: "16px", color: "rgb(37, 105, 241)" }} />
                                                                                </IconButton>
                                                                            ) : null}
                                                                        </div>

                                                                        <div style={{ width: "100%" }}>
                                                                            {store !== undefined && store !== null ? (
                                                                                <AdvancedDropDown
                                                                                    data={sortLocationDropdown(store.sort(({ value: a }, { value: b }) => a - b)).map((i) => ({
                                                                                        label: i?.title + " (D: " + i?.distance + "| T: " + i?.duration + ")",
                                                                                        value: i?.id,
                                                                                    }))}
                                                                                    ref={myRef}
                                                                                    placeHolder={"Salons"}
                                                                                    value={bookingLocation}
                                                                                    passByID={true}
                                                                                    onChange={(e) => {
                                                                                        setBookingLocation(e.value)
                                                                                    }}
                                                                                />
                                                                            ) : null}
                                                                        </div>
                                                                        <ValidatePermission allowedModules={["02.01 - Schedule / New Booking"]} allowedSubRoutes={["View Map"]}>
                                                                            <div style={{ width: "100%" }}>
                                                                                <Button
                                                                                    variant="contained"
                                                                                    color="primary"
                                                                                    onClick={() => {
                                                                                        viewOnMap()
                                                                                    }}
                                                                                >
                                                                                    <LocationOn style={{ fontSize: "16px" }} /> &nbsp; View Map
                                                                                </Button>
                                                                            </div>
                                                                        </ValidatePermission>

                                                                        <Modal open={openModal} borderless={true}>
                                                                            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem" }}>
                                                                                <div style={{ backgroundColor: "#fff", borderRadius: "12px" }}>
                                                                                    <div style={{ display: "flex" }}>
                                                                                        <div style={{ width: "267px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                                                                            <div>
                                                                                                <div
                                                                                                    style={{
                                                                                                        padding: "1rem",
                                                                                                        display: "flex",
                                                                                                        justifyContent: "flex-start",
                                                                                                        flexDirection: "column",
                                                                                                        gap: "0.5rem",
                                                                                                    }}
                                                                                                >
                                                                                                    <p
                                                                                                        style={{
                                                                                                            margin: "0",
                                                                                                            fontWeight: "500",
                                                                                                            fontSize: "12px",
                                                                                                            lineHeight: "20px",
                                                                                                            color: "#777E90",
                                                                                                        }}
                                                                                                    >
                                                                                                        Customer Location
                                                                                                    </p>
                                                                                                    <p
                                                                                                        style={{
                                                                                                            margin: "0",
                                                                                                            fontWeight: "400",
                                                                                                            fontSize: "14px",
                                                                                                            lineHeight: "20px",
                                                                                                            color: "#23262F",
                                                                                                            borderBottom: "1px solid #E5E7EB",
                                                                                                            paddingBottom: "5px",
                                                                                                        }}
                                                                                                    >
                                                                                                        {locName}
                                                                                                    </p>
                                                                                                    {currentLocationIndex !== undefined &&
                                                                                                    currentLocationIndex !== null &&
                                                                                                    currentLocationIndex !== "" &&
                                                                                                    currentLocationIndex >= 0 ? (
                                                                                                        <>
                                                                                                            <p
                                                                                                                style={{
                                                                                                                    margin: "0",
                                                                                                                    fontWeight: "500",
                                                                                                                    fontSize: "12px",
                                                                                                                    lineHeight: "20px",
                                                                                                                    color: "#777E90",
                                                                                                                    borderBottom: "1px solid #E5E7EB",
                                                                                                                    paddingBottom: "5px",
                                                                                                                }}
                                                                                                            >
                                                                                                                Current location
                                                                                                            </p>
                                                                                                            <div
                                                                                                                className={
                                                                                                                    store[currentLocationIndex].id === bookingLocation
                                                                                                                        ? styles.locationContainerSelected
                                                                                                                        : styles.locationContainer
                                                                                                                }
                                                                                                                style={{
                                                                                                                    border: "1px solid #E5E7EB",
                                                                                                                    borderRadius: "8px",
                                                                                                                    width: "194px",
                                                                                                                    height: "100%",
                                                                                                                    background: "#F9FAFB",
                                                                                                                    textAlign: "center",
                                                                                                                }}
                                                                                                                onClick={() => {
                                                                                                                    {
                                                                                                                        getDirections(store[currentLocationIndex])
                                                                                                                    }
                                                                                                                    {
                                                                                                                        BookingLocationSelect(store[currentLocationIndex].id)
                                                                                                                    }
                                                                                                                }}
                                                                                                            >
                                                                                                                <p
                                                                                                                    style={{
                                                                                                                        margin: "0",
                                                                                                                        fontWeight: "600",
                                                                                                                        fontSize: "14px",
                                                                                                                        lineHeight: "20px",
                                                                                                                        color: "#000000",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {store[currentLocationIndex].title}
                                                                                                                </p>
                                                                                                                <p
                                                                                                                    style={{
                                                                                                                        margin: "0",
                                                                                                                        fontWeight: "400",
                                                                                                                        fontSize: "14px",
                                                                                                                        lineHeight: "20px",
                                                                                                                        color: "#9CA3AF",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {store[currentLocationIndex].distance}
                                                                                                                </p>
                                                                                                                <p
                                                                                                                    style={{
                                                                                                                        margin: "0",
                                                                                                                        fontWeight: "400",
                                                                                                                        fontSize: "14px",
                                                                                                                        lineHeight: "20px",
                                                                                                                        color: "#9CA3AF",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {store[currentLocationIndex].duration}
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </>
                                                                                                    ) : null}
                                                                                                    <p
                                                                                                        style={{
                                                                                                            margin: "0",
                                                                                                            fontWeight: "500",
                                                                                                            fontSize: "12px",
                                                                                                            lineHeight: "20px",
                                                                                                            color: "#777E90",
                                                                                                            borderBottom: "1px solid #E5E7EB",
                                                                                                            paddingBottom: "5px",
                                                                                                        }}
                                                                                                    >
                                                                                                        Closest Salons
                                                                                                    </p>
                                                                                                </div>
                                                                                                <div></div>
                                                                                                <div className={styles.scrollViewCardContainer}>
                                                                                                    {storeDisplay
                                                                                                        .sort(({ value: a }, { value: b }) => a - b)
                                                                                                        .map((salon, index) => {
                                                                                                            return (
                                                                                                                <div
                                                                                                                    className={
                                                                                                                        salon?.id === bookingLocation
                                                                                                                            ? styles.locationContainerSelected
                                                                                                                            : styles.locationContainer
                                                                                                                    }
                                                                                                                    key={index}
                                                                                                                    style={{
                                                                                                                        border: "1px solid #E5E7EB",
                                                                                                                        borderRadius: "8px",
                                                                                                                        width: "194px",
                                                                                                                        height: "100%",
                                                                                                                        background: "#F9FAFB",
                                                                                                                        textAlign: "center",
                                                                                                                    }}
                                                                                                                    onClick={() => {
                                                                                                                        {
                                                                                                                            getDirections(salon)
                                                                                                                        }
                                                                                                                        {
                                                                                                                            BookingLocationSelect(salon?.id)
                                                                                                                        }
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <p
                                                                                                                        style={{
                                                                                                                            margin: "0",
                                                                                                                            fontWeight: "600",
                                                                                                                            fontSize: "14px",
                                                                                                                            lineHeight: "20px",
                                                                                                                            color: "#000000",
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {salon?.title}
                                                                                                                    </p>
                                                                                                                    <p
                                                                                                                        style={{
                                                                                                                            margin: "0",
                                                                                                                            fontWeight: "400",
                                                                                                                            fontSize: "14px",
                                                                                                                            lineHeight: "20px",
                                                                                                                            color: "#9CA3AF",
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {salon?.distance}
                                                                                                                    </p>
                                                                                                                    <p
                                                                                                                        style={{
                                                                                                                            margin: "0",
                                                                                                                            fontWeight: "400",
                                                                                                                            fontSize: "14px",
                                                                                                                            lineHeight: "20px",
                                                                                                                            color: "#9CA3AF",
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {salon?.duration}
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            )
                                                                                                        })}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                style={{
                                                                                                    padding: "2rem 1.5rem",
                                                                                                    height: "fit-content",
                                                                                                    background: "#F3F4F6",
                                                                                                    borderRadius: "0 0 0 12px",
                                                                                                }}
                                                                                            >
                                                                                                <Button
                                                                                                    className={styles.btnWidth}
                                                                                                    variant="contained"
                                                                                                    color="primary"
                                                                                                    onClick={() => {
                                                                                                        handleSelectLocation()
                                                                                                    }}
                                                                                                >
                                                                                                    Select Location
                                                                                                </Button>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div style={{ height: "700px", width: "778px" }}>
                                                                                            <Map
                                                                                                location={center}
                                                                                                salons={store}
                                                                                                directions={true}
                                                                                                getRoutes={true}
                                                                                                border={true}
                                                                                                showBaseMarker={true}
                                                                                                getDirection={selectedLocation}
                                                                                                zoom={20}
                                                                                                isSub={false}
                                                                                            >
                                                                                                <div className={styles.closeButton}>
                                                                                                    <CloseButton onClick={() => setOpenModal(!openModal)} />
                                                                                                </div>
                                                                                            </Map>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Modal>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {props.petList.length > 0 ? (
                                                            <>
                                                                <ValidatePermission allowedModules={["02.01 - Schedule / New Booking"]} allowedSubRoutes={["Add/Edit Services"]}>
                                                                    <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                                                                        <h3 style={{ color: "#002867", margin: "1.5rem 1.5rem .5rem 1.5rem" }}>Services</h3>
                                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                                            <p
                                                                                style={{
                                                                                    color: "#005DF1",
                                                                                    margin: "1.5rem 1.5rem .5rem 1.5rem",
                                                                                    fontSize: "14px",
                                                                                    fontWeight: "400",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                                onClick={() => {
                                                                                    openServicesModal()
                                                                                }}
                                                                            >
                                                                                Add / Edit Service
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ backgroundColor: "#fff" }}>
                                                                        <div
                                                                            style={{
                                                                                width: "100%",
                                                                                borderRadius: ".5rem",
                                                                                border: "1px solid #F3F4F6",
                                                                                padding: "0 0 10px 0",
                                                                            }}
                                                                        >
                                                                            {
                                                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                                                    {servicePets.map((pet, index) => {
                                                                                        return (
                                                                                            <div style={{ display: "flex", padding: "1rem 1rem 0 1rem", gap: "20px" }}>
                                                                                                <div
                                                                                                    key={index}
                                                                                                    style={{
                                                                                                        borderRadius: "8px",
                                                                                                        padding: ".6rem ",
                                                                                                        boxShadow: "0px 2px 8px rgba(218, 218, 218, 0.1), 0px 1px 4px rgba(97, 97, 97, 0.1)",
                                                                                                        width: "165px",
                                                                                                        height: "100%",
                                                                                                    }}
                                                                                                >
                                                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                                                        <AvatarLabel
                                                                                                            isVip={false}
                                                                                                            img={
                                                                                                                pet?.img == "" || pet?.img == null
                                                                                                                    ? placeholderImage
                                                                                                                    : `${process.env.REACT_APP_MEDIA_URL}directories/pets/` + pet?.img
                                                                                                            }
                                                                                                            name=""
                                                                                                        />
                                                                                                        <div>
                                                                                                            <p style={{ margin: "0", fontWeight: "600", textTransform: "capitalize" }}>
                                                                                                                {Truncate(pet?.name, 20)}
                                                                                                            </p>
                                                                                                            <p className={styles.petMiniCardPBreed}>{Truncate(pet?.breed, 10)}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <div
                                                                                                        style={{
                                                                                                            backgroundColor: "#F3F4F6",
                                                                                                            padding: "5px 15px 15px 15px",
                                                                                                            borderRadius: "8px",
                                                                                                            display: "flex",
                                                                                                            justifyContent: "space-between",
                                                                                                            width: "100%",
                                                                                                        }}
                                                                                                    >
                                                                                                        <div>
                                                                                                            <p style={{ fontWeight: "500", fontSize: "14px", color: "#6B7280", marginBottom: ".5rem" }}>
                                                                                                                Services
                                                                                                            </p>
                                                                                                            <div style={{ display: "flex", gap: ".5rem", maxWidth: "620px", flexWrap: "wrap" }}>
                                                                                                                {pet.additionalService.length > 0 ? (
                                                                                                                    pet.additionalService.map((addService) => {
                                                                                                                        if (addService.id !== null) {
                                                                                                                            return (
                                                                                                                                <Tooltip
                                                                                                                                    title={
                                                                                                                                        <>
                                                                                                                                            <p
                                                                                                                                                style={{
                                                                                                                                                    fontSize: "14px",
                                                                                                                                                    color: "#fff",
                                                                                                                                                    fontWeight: "600",
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                Additional Service Name
                                                                                                                                            </p>
                                                                                                                                            <p style={{ fontSize: "12px", color: "#fff" }}>
                                                                                                                                                {addService.name}
                                                                                                                                            </p>
                                                                                                                                        </>
                                                                                                                                    }
                                                                                                                                >
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            backgroundColor: "#DCFCE7",
                                                                                                                                            border: "1px solid #86EFAC",
                                                                                                                                            borderRadius: "16px",
                                                                                                                                            color: "#14532D",
                                                                                                                                            fontSize: "14px",
                                                                                                                                            fontWeight: "500",
                                                                                                                                            display: "flex",
                                                                                                                                            justifyContent: "space-between",
                                                                                                                                            alignItems: "center",
                                                                                                                                            padding: "0 10px 0 10px",
                                                                                                                                            gap: ".2rem",
                                                                                                                                            width: "max-content",
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        {Truncate(addService.name, 20)}{" "}
                                                                                                                                        <span style={{ margin: "0", borderRight: "1px solid #86efac" }}>
                                                                                                                                            &nbsp;
                                                                                                                                        </span>{" "}
                                                                                                                                        ${addService.price}
                                                                                                                                    </div>
                                                                                                                                </Tooltip>
                                                                                                                            )
                                                                                                                        } else {
                                                                                                                            return null
                                                                                                                        }
                                                                                                                    })
                                                                                                                ) : (
                                                                                                                    <p style={{ color: "#14532D", fontSize: "14px", fontWeight: "500", margin: "0" }}>
                                                                                                                        --
                                                                                                                    </p>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </ValidatePermission>
                                                            </>
                                                        ) : null}
                                                        {props.petList.length > 0 ? (
                                                            <>
                                                                <ValidatePermission allowedModules={["02.01 - Schedule / New Booking"]} allowedSubRoutes={["Add/Edit Products"]}>
                                                                    <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                                                                        <h3 style={{ color: "#002867", margin: "1.5rem 1.5rem .5rem 1.5rem" }}>Pet Products</h3>
                                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                                            <p
                                                                                style={{
                                                                                    color: "#005DF1",
                                                                                    margin: "1.5rem 1.5rem .5rem 1.5rem",
                                                                                    fontSize: "14px",
                                                                                    fontWeight: "400",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                                onClick={() => {
                                                                                    openProductModal()
                                                                                }}
                                                                            >
                                                                                Add / Edit Products
                                                                            </p>
                                                                        </div>
                                                                    </div>

                                                                    <div style={{ backgroundColor: "#fff" }}>
                                                                        <div
                                                                            style={{
                                                                                boxShadow: "0px 4px 0px #EDF3F9",
                                                                                width: "100%",
                                                                                borderRadius: ".5rem",
                                                                                border: "1px solid #F3F4F6",
                                                                                padding: "0 0 10px 0",
                                                                            }}
                                                                        >
                                                                            {
                                                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                                                    {servicePets.map((pet, index) => {
                                                                                        return (
                                                                                            <div style={{ display: "flex", padding: "1rem 1rem 0 1rem", gap: "20px" }}>
                                                                                                <div
                                                                                                    key={index}
                                                                                                    style={{
                                                                                                        borderRadius: "8px",
                                                                                                        padding: ".6rem ",
                                                                                                        boxShadow: "0px 2px 8px rgba(218, 218, 218, 0.1), 0px 1px 4px rgba(97, 97, 97, 0.1)",
                                                                                                        width: "165px",
                                                                                                        height: "100%",
                                                                                                    }}
                                                                                                >
                                                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                                                        <AvatarLabel
                                                                                                            isVip={false}
                                                                                                            img={
                                                                                                                pet?.img == "" || pet?.img == null
                                                                                                                    ? placeholderImage
                                                                                                                    : `${process.env.REACT_APP_MEDIA_URL}directories/pets/` + pet?.img
                                                                                                            }
                                                                                                            name=""
                                                                                                        />
                                                                                                        <div>
                                                                                                            <p style={{ margin: "0", fontWeight: "600", textTransform: "capitalize" }}>
                                                                                                                {Truncate(pet?.name, 20)}
                                                                                                            </p>
                                                                                                            <p className={styles.petMiniCardPBreed}>{Truncate(pet?.breed, 10)}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <div
                                                                                                        style={{
                                                                                                            backgroundColor: "#F3F4F6",
                                                                                                            padding: "5px 15px 15px 15px",
                                                                                                            borderRadius: "8px",
                                                                                                            display: "flex",
                                                                                                            justifyContent: "space-between",
                                                                                                            width: "100%",
                                                                                                        }}
                                                                                                    >
                                                                                                        <div>
                                                                                                            <p style={{ fontWeight: "500", fontSize: "14px", color: "#6B7280", marginBottom: ".5rem" }}>
                                                                                                                Products
                                                                                                            </p>
                                                                                                            <div style={{ display: "flex", gap: ".5rem", maxWidth: "620px", flexWrap: "wrap" }}>
                                                                                                                {pet.selectedProducts.length > 0 ? (
                                                                                                                    pet.selectedProducts
                                                                                                                        .filter((x) => x.bitActive === true)
                                                                                                                        .map((product) => {
                                                                                                                            return (
                                                                                                                                <Tooltip
                                                                                                                                    title={
                                                                                                                                        <>
                                                                                                                                            <p
                                                                                                                                                style={{
                                                                                                                                                    fontSize: "14px",
                                                                                                                                                    color: "#fff",
                                                                                                                                                    fontWeight: "600",
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                Product Name
                                                                                                                                            </p>
                                                                                                                                            <p style={{ fontSize: "12px", color: "#fff" }}>
                                                                                                                                                {product.varProductName}
                                                                                                                                            </p>
                                                                                                                                        </>
                                                                                                                                    }
                                                                                                                                >
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            backgroundColor: "#DCFCE7",
                                                                                                                                            border: "1px solid #86EFAC",
                                                                                                                                            borderRadius: "16px",
                                                                                                                                            color: "#14532D",
                                                                                                                                            fontSize: "14px",
                                                                                                                                            fontWeight: "500",
                                                                                                                                            display: "flex",
                                                                                                                                            justifyContent: "space-between",
                                                                                                                                            alignItems: "center",
                                                                                                                                            padding: "0 10px 0 10px",
                                                                                                                                            gap: ".2rem",
                                                                                                                                            width: "max-content",
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        {Truncate(product.varProductName, 20)}{" "}
                                                                                                                                        <span style={{ margin: "0", borderRight: "1px solid #86efac" }}>
                                                                                                                                            &nbsp;
                                                                                                                                        </span>
                                                                                                                                        Qty:{product.numQuantity}
                                                                                                                                        <span style={{ margin: "0", borderRight: "1px solid #86efac" }}>
                                                                                                                                            &nbsp;
                                                                                                                                        </span>
                                                                                                                                        $
                                                                                                                                        {(product.numProductSalesPrice * product.numQuantity).toFixed(
                                                                                                                                            2
                                                                                                                                        )}
                                                                                                                                    </div>
                                                                                                                                </Tooltip>
                                                                                                                            )
                                                                                                                        })
                                                                                                                ) : (
                                                                                                                    <p style={{ color: "#14532D", fontSize: "14px", fontWeight: "500", margin: "0" }}>
                                                                                                                        --
                                                                                                                    </p>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </ValidatePermission>
                                                            </>
                                                        ) : null}
                                                        {/* 
                                            <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                                                <h3 style={{ color: "#002867", margin: "1.5rem 1.5rem .5rem 1.5rem" }}>General products</h3>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <p
                                                        style={{ color: "#005DF1", margin: "1.5rem 1.5rem .5rem 1.5rem", fontSize: "14px", fontWeight: "400", cursor: "pointer" }}
                                                        onClick={() => {
                                                            openProductModalNoPet()
                                                        }}
                                                    >
                                                        Add / Edit Products
                                                    </p>
                                                </div>
                                            </div>

                                            <div style={{ backgroundColor: "#fff" }}>
                                                <div style={{ boxShadow: "0px 4px 0px #EDF3F9", borderRadius: ".5rem", border: "1px solid #F3F4F6", padding: "20px" }}>
                                                    {
                                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                                            {
                                                                <div
                                                                    style={{
                                                                        backgroundColor: "#F3F4F6",
                                                                        padding: "15px 15px 15px 15px",
                                                                        borderRadius: "8px",
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                    }}
                                                                >
                                                                    <div>
                                                                        <div style={{ display: "flex", gap: ".5rem", width: "100%", flexWrap: "wrap" }}>
                                                                            {productCartNoPetList.length > 0 ? (
                                                                                productCartNoPetList
                                                                                    .filter((x) => x.bitActive === true)
                                                                                    .map((product) => {
                                                                                        return (
                                                                                            <Tooltip
                                                                                                title={
                                                                                                    <>
                                                                                                        <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Product Name</p>
                                                                                                        <p style={{ fontSize: "12px", color: "#fff" }}>{product.varProductName}</p>
                                                                                                    </>
                                                                                                }
                                                                                            >
                                                                                                <div
                                                                                                    style={{
                                                                                                        backgroundColor: "#DCFCE7",
                                                                                                        border: "1px solid #86EFAC",
                                                                                                        borderRadius: "16px",
                                                                                                        color: "#14532D",
                                                                                                        fontSize: "14px",
                                                                                                        fontWeight: "500",
                                                                                                        display: "flex",
                                                                                                        justifyContent: "space-between",
                                                                                                        alignItems: "center",
                                                                                                        padding: "0 10px 0 10px",
                                                                                                        gap: ".2rem",
                                                                                                        width: "max-content",
                                                                                                    }}
                                                                                                >
                                                                                                    {Truncate(product.varProductName, 20)}{" "}
                                                                                                    <span style={{ margin: "0", borderRight: "1px solid #86efac" }}>&nbsp;</span>Qty:
                                                                                                    {product.numQuantity}
                                                                                                    <span style={{ margin: "0", borderRight: "1px solid #86efac" }}>&nbsp;</span>$
                                                                                                    {(product.numProductSalesPrice * product.numQuantity).toFixed(2)}
                                                                                                </div>
                                                                                            </Tooltip>
                                                                                        )
                                                                                    })
                                                                            ) : (
                                                                                <p style={{ color: "#14532D", fontSize: "14px", fontWeight: "500", margin: "0" }}>--</p>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </div> */}

                                                        <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
                                                            <div style={{ display: "flex", width: "100%" }}>
                                                                <h3 style={{ color: "#002867", margin: "1.5rem 1.5rem .5rem 1.5rem" }}>List of Slots</h3>
                                                            </div>

                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    width: "100%",
                                                                    backgroundColor: "#fff",
                                                                    borderRadius: "0.5rem",
                                                                    border: "1px solid rgb(243, 244, 246)",
                                                                }}
                                                            >
                                                                <div style={{ display: "flex", width: "100%", gap: "1rem", flexDirection: "column" }}>
                                                                    <div style={{ padding: "16px" }}>
                                                                        <div
                                                                            style={{
                                                                                width: "100%",
                                                                                display: "flex",
                                                                                flexDirection: "column",
                                                                                gap: "15px",
                                                                                justifyContent: "flex-start",
                                                                                alignItems: "flex-start",
                                                                            }}
                                                                        >
                                                                            <div style={{ display: "flex", gap: ".5rem", width: "100%", alignItems: "center", marginBottom: "10px" }}>
                                                                                <div style={{ width: "100%" }}>
                                                                                    <AdvancedDropDown
                                                                                        data={servicePets.map((i) => ({ label: i.name, value: i.id }))}
                                                                                        onChange={(e) => {
                                                                                            setReservationPet(e?.value)
                                                                                        }}
                                                                                        ref={reservationPetRef}
                                                                                        placeHolder={"Select Pet"}
                                                                                        passByID={true}
                                                                                        value={reservationPet}
                                                                                    />
                                                                                </div>

                                                                                <TextField
                                                                                    size="small"
                                                                                    fullWidth
                                                                                    required={true}
                                                                                    type={"date"}
                                                                                    variant="outlined"
                                                                                    onChange={(e) => {
                                                                                        setBookingDate(e.target.value)
                                                                                    }}
                                                                                    value={bookingDate}
                                                                                    inputProps={{
                                                                                        min: shortDate(new Date()),
                                                                                    }}
                                                                                />

                                                                                <div style={{ width: "100%" }}>
                                                                                    {timeOfDayList !== undefined && timeOfDayList !== null ? (
                                                                                        <AdvancedDropDown
                                                                                            data={timeOfDayList.map((i) => ({ label: i.varTimeOfDay, value: i.numID }))}
                                                                                            ref={myRefTimeOFDay}
                                                                                            placeHolder={"Select Time Of Day"}
                                                                                            onChange={(e) => {
                                                                                                setTimeOfDay(e.value)
                                                                                            }}
                                                                                            passByID={true}
                                                                                            value={timeOfDay}
                                                                                        />
                                                                                    ) : null}
                                                                                </div>
                                                                                <div style={{ width: "fit-content", display: "flex", gap: "10px" }}>
                                                                                    <Button variant="contained" color="discard" loading={isLoading} onClick={() => getSlots()}>
                                                                                        <p style={{ whiteSpace: "nowrap", color: "#fff", fontWeight: "500", fontSize: "14px", margin: "0" }}>
                                                                                            Generate Available Slots
                                                                                        </p>
                                                                                    </Button>
                                                                                    <Button
                                                                                        variant="outlined"
                                                                                        color="default"
                                                                                        onClick={() => {
                                                                                            handleClear()
                                                                                        }}
                                                                                    >
                                                                                        Clear
                                                                                    </Button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/* <div style={{ borderRadius: "8px", backgroundColor: "#f1f1f1", width: "100%" }}>
                                                                            <div style={{ padding: "15px" }}>
                                                                                <div style={{ display: "flex", flexDirection: "column", gap: "15px", overflowY: "scroll", height: "calc(100% - 1px)" }}>
                                                                                    {isLoading ? (
                                                                                        <p style={{ color: "#777E90", fontWeight: "400", fontSize: "13px" }}>Loading...</p>
                                                                                    ) : slotEmployeeList.length > 0 ? (
                                                                                        slotEmployeeList?.map((emp, empIndex) => {
                                                                                            return (
                                                                                                <div style={{ backgroundColor: "#fff", padding: "20px", borderRadius: "8px" }}>
                                                                                                    <div style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "24px" }}>
                                                                                                        <Avatar
                                                                                                            img={`${process.env.REACT_APP_MEDIA_URL}directories/employees/` + emp?.image}
                                                                                                            isVip={false}
                                                                                                            className={"medium"}
                                                                                                            labelType={"largeLabel"}
                                                                                                        />
                                                                                                        <div>
                                                                                                            <p style={{ margin: "0", fontWeight: "600", fontSize: "18px" }}>{emp?.name}</p>
                                                                                                            <p style={{ margin: "0" }}>{emp?.occupation}</p>
                                                                                                            <div style={{ display: "flex", alignItems: "center", gap: ".1rem" }}>
                                                                                                                <Star style={{ color: "#F7AE32", fontSize: "20px" }} />
                                                                                                                <p style={{ margin: "0", fontWeight: "500" }}>{emp?.rating}</p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div style={{ display: "flex", width: "calc(100% - 1px)", gap: "15px", overflowX: "scroll" }}>
                                                                                                        {emp?.dates
                                                                                                            ?.slice()
                                                                                                            .sort((a, b) => {
                                                                                                                const dateA = a.date ? new Date(a.date) : null
                                                                                                                const dateB = b.date ? new Date(b.date) : null

                                                                                                                if (dateA && dateB) {
                                                                                                                    return dateA.getTime() - dateB.getTime()
                                                                                                                }

                                                                                                                return 0 // Handle the case where either date is undefined
                                                                                                            })
                                                                                                            .map((date, dateIndex) => {
                                                                                                                return (
                                                                                                                    <div
                                                                                                                        onClick={() => {
                                                                                                                            setSelectedDate({ empIndex: empIndex, dateIndex: dateIndex })
                                                                                                                            setIsOpenSlotPicker(true)
                                                                                                                        }}
                                                                                                                        style={{
                                                                                                                            flexShrink: 0,
                                                                                                                            backgroundColor:
                                                                                                                                selectedDate?.empIndex === empIndex &&
                                                                                                                                selectedDate?.dateIndex === dateIndex
                                                                                                                                    ? "#1e5721"
                                                                                                                                    : "#3f9544",
                                                                                                                            padding: "10px 15px",
                                                                                                                            display: "flex",
                                                                                                                            alignItems: "center",
                                                                                                                            justifyContent: "center",
                                                                                                                            height: "40px",
                                                                                                                            borderRadius: "8px",
                                                                                                                            fontWeight: "600",
                                                                                                                            color: "#fff",
                                                                                                                            width: "fit-content",
                                                                                                                            cursor: "pointer",
                                                                                                                            flexDirection: "column",
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <p style={{ margin: 0, color: "white", fontWeight: "600" }}>{date?.date}</p>
                                                                                                                      
                                                                                                                        <p style={{ margin: 0, color: "white", fontWeight: "600" }}>
                                                                                                                            ({date.slots.length}) ({getDayName(date?.date)})
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            })}
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    ) : (
                                                                                        <p style={{ color: "#777E90", fontWeight: "400", fontSize: "13px" }}>{slotMsg}</p>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div> */}

                                                                        <Modal open={isOpenSlotPicker}>
                                                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                                <div style={{ backgroundColor: "#fff", width: "100%", borderRadius: "10px" }}>
                                                                                    <div style={{ display: "flex", flexDirection: "column", gap: "20px", padding: "20px" }}>
                                                                                        <div style={{ display: "flex", justifyContent: "end" }}>
                                                                                            <CloseButton onClick={() => setIsOpenSlotPicker(false)}></CloseButton>
                                                                                        </div>

                                                                                        <div>
                                                                                            <p style={{ fontWeight: "600", fontSize: "18px" }}>Slots</p>
                                                                                            <div style={{ display: "flex", gap: "15px", flexWrap: "wrap", justifyContent: "flex-start" }}>
                                                                                                {selectedDate?.empIndex !== ""
                                                                                                    ? slotEmployeeList[selectedDate?.empIndex]?.dates
                                                                                                          ?.slice()
                                                                                                          .sort((a, b) => {
                                                                                                              const dateA = a.date ? new Date(a.date) : null
                                                                                                              const dateB = b.date ? new Date(b.date) : null

                                                                                                              if (dateA && dateB) {
                                                                                                                  return dateA.getTime() - dateB.getTime()
                                                                                                              }

                                                                                                              return 0 // Handle the case where either date is undefined
                                                                                                          })
                                                                                                          [selectedDate?.dateIndex]?.slots?.map((slots, slotIndex) => {
                                                                                                              console.log("selected ", selectedDate?.empIndex, selectedDate?.dateIndex)
                                                                                                              return (
                                                                                                                  <div
                                                                                                                      style={{
                                                                                                                          flexShrink: 0,
                                                                                                                          backgroundColor:
                                                                                                                              selectedDate?.empIndex === servicePets[resPetIndex]?.slotInfo?.empIndex &&
                                                                                                                              selectedDate?.dateIndex ===
                                                                                                                                  servicePets[resPetIndex]?.slotInfo?.dateIndex &&
                                                                                                                              slotIndex === servicePets[resPetIndex]?.slotInfo?.slotIndex
                                                                                                                                  ? "#1a641f"
                                                                                                                                  : "#3f9544",
                                                                                                                          padding: "10px 15px",
                                                                                                                          display: "flex",
                                                                                                                          alignItems: "center",
                                                                                                                          justifyContent: "center",
                                                                                                                          height: "40px",
                                                                                                                          borderRadius: "8px",
                                                                                                                          fontWeight: "600",
                                                                                                                          color: "#fff",
                                                                                                                          width: "fit-content",
                                                                                                                          cursor: "pointer",
                                                                                                                          flexDirection: "column",
                                                                                                                      }}
                                                                                                                      onClick={() => {
                                                                                                                          selectSlot(slots, selectedDate?.empIndex, selectedDate?.dateIndex, slotIndex)
                                                                                                                      }}
                                                                                                                  >
                                                                                                                      <p style={{ margin: 0, color: "white", fontWeight: "600" }}>
                                                                                                                          {slots?.slots[0]?.slot}
                                                                                                                      </p>
                                                                                                                      <p style={{ margin: 0, color: "white", fontWeight: "600" }}>
                                                                                                                          (~{slots?.slots[0]?.duration} min)
                                                                                                                      </p>
                                                                                                                  </div>
                                                                                                              )
                                                                                                          })
                                                                                                    : "Loading slots..."}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Modal>

                                                                        {/* <div>
                                                                                    {
                                                                                        (slotObj.length > 0)?
                                                                                        slotObj.map((slot, slotIndex) => {
                                                                                            return <>
                                                                                                <div style={{ display: 'flex', width: '100%', gap: '1rem' }} className={(selectedSlot === slotIndex) ? styles.selectedSlot : ""}>
                                                                                                    <div style={{ width: '40%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '1rem', boxShadow: 'rgb(218 218 218 / 10%) 0px 2px 8px, rgb(97 97 97 / 10%) 0px 1px 4px', padding: '.8rem 1.5rem', borderRadius: '8px' }}>
                                                                                                        <Avatar

                                                                                                            isVip={false}
                                                                                                            img={`${process.env.REACT_APP_MEDIA_URL}directories/employees/` + slot?.employee?.varEmployeeImagePath}
                                                                                                            className={'medium'}
                                                                                                        />
                                                                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                                                            <p style={{ fontSize: '14px', fontWeight: '600' }}>{slot?.employee.varEmployeeFirstName} {slot?.employee.varEmployeeLastName}</p>
                                                                                                            <Chip color={'primary'} label={slot?.location?.varLocationName}/>
                                                                                                        </div>                                                                                
                                                                                                    </div>

                                                                                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '.5rem', boxShadow: 'rgb(218 218 218 / 10%) 0px 2px 8px, rgb(97 97 97 / 10%) 0px 1px 4px', padding: '.5rem .9rem', borderRadius: '8px' }}>
                                                                                                        <div>
                                                                                                            <p style={{ fontSize: '14px', fontWeight: '600' }}>{slot?.serviceItemsLibrary.varServiceItemName}</p>
                                                                                                            <div style={{display:"flex", gap:"10px"}}>
                                                                                                                <div style={{display:"flex", gap:"5px"}}>
                                                                                                                    <Chip label={`Start: ${slot?.dtDateTimeFrom}`}/>
                                                                                                                    <Chip label={`End: ${slot?.dtDateTimeTo}`}/>
                                                                                                                </div>                                                                                    
                                                                                                            </div>
                                                                                                        </div>               
                                                                                                        <IconButton onClick={() => setSelectedSlot(slotIndex)}><Add></Add></IconButton>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        })
                                                                                        : <p style={{fontWeight:"500", color:"gray"}}>{slotStatus}</p>
                                                                                    }
                                                                                </div>                     */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                                                                    <h3 style={{ color: '#002867', margin: '1.5rem 1.5rem .5rem 1.5rem' }}>Notes</h3>
                                                                    {
                                                                        (petNotes.length > 0) ? <p style={{ color: '#005DF1', margin: '1.5rem 1.5rem .5rem 1.5rem', fontSize: "16px", fontWeight: "400", cursor: "pointer" }} onClick={() => { setIsOpenAddNotes(true) }}>Add new note</p> : ""
                                                                    }

                                                                </div> */}

                                                        {/* <div style={{ backgroundColor: '#fff' }}>
                                                                    <div style={{ boxShadow: '0px 4px 0px #EDF3F9', width: '100%', borderRadius: '.5rem', border: '1px solid #F3F4F6' }}>
                                                                        {
                                                                            (petNotes.length > 0) ?
                                                                                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 0fr)', gridColumnGap: '1rem', rowGap: "1rem", marginTop: "0.6rem", marginLeft: "0.8rem" }}>
                                                                                    {
                                                                                        petNotes.map((note, index) => {
                                                                                            return <div className={styles.pet_notes_card}>
                                                                                                <div style={{ border: '1px solid #E5E7EB', borderRadius: 8, padding: '0 12px 12px 12px', backgroundColor: '#ffffff', paddingTop: "10px", width: "330px" }}>
                                                                                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: "1rem" }}>
                                                                                                        <div style={{ display: 'flex', justifyContent: 'space-between', gap: '.6rem' }}>
                                                                                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                                                                                                <div style={{ fontWeight: 600, fontSize: "16px" }}>{note.name}</div>
                                                                                                                <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
                                                                                                                    <div style={{ fontWeight: 400, fontSize: 12, color: "#6B7280" }}>{note.breed}</div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div style={{ display: 'flex', gap: '.2rem' }}>
                                                                                                            <Avatar shape="rounded" img={(note.img === "" || note.img === null) ? placeholderImage : `${process.env.REACT_APP_MEDIA_URL}directories/pets/` + note.img} isVip={false} className={'medium'} />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div style={{ backgroundColor: "#EFF5FF", display: 'grid', gridTemplateColumns: 'repeat(2, 0fr)', gridColumnGap: '1rem', rowGap: "1rem", padding: "10px" }}>
                                                                                                        {
                                                                                                            note.sendTo.map((groups) => {
                                                                                                                return <p style={{ color: "#fff", backgroundColor: "#005DF1", borderRadius: "16px", padding: "6px 10px 6px 10px" }}>{groups.value}</p>
                                                                                                            })
                                                                                                        }
                                                                                                    </div>
                                                                                                    <p style={{ fontSize: "14px", fontWeight: "600", marginTop: ".5rem" }}>Note</p>
                                                                                                    <p style={{ fontSize: "14px", fontWeight: "400", marginTop: ".5rem", marginBottom: "2rem" }}>{note.note}</p>
                                                                                                   <div style={{width:"100%", display:"flex", justifyContent:"space-between"}}>
                                                                                                   <p style={{ fontSize: "12px", fontWeight: "400", color: "#6B7280" }}>Mary Jo</p>
                                                                                                   <p style={{ fontSize: "12px", fontWeight: "400", color: "#6B7280" }}>{shortDate(note.date)}</p>
                                                                                                   </div>
                                                                                                    <div style={{ display: "flex", gap: ".5rem", justifyContent: "space-between" }}>

                                                                                                        <MUIButton variant="outlined" color="error">Remove</MUIButton>
                                                                                                        <Button
                                                                                                            variant='contained'
                                                                                                            color='primary'
                                                                                                            onClick={() => { }}
                                                                                                        >
                                                                                                            Edit
                                                                                                        </Button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                                : 
                                                                                <div style={{ display: 'flex', alignItems: 'center', padding: '2rem', gap: '1rem', justifyContent: 'center' }}>
                                                                                    <Button
                                                                                        variant='contained'
                                                                                        color='primary'
                                                                                        onClick={() => setIsOpenAddNotes(true)}
                                                                                    >
                                                                                        <NoteAlt style={{ fontSize: "18px", marginRight: '.5rem' }} />
                                                                                        Add Notes
                                                                                    </Button>
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                </div> */}
                                                    </div>
                                                </TabPanel>
                                            </ValidatePermission>

                                            <ValidatePermission allowedModules={["02.02 - Schedule / Customer Profile"]}>
                                                <TabPanel value={value} index={(tabIndex = 1 && tabIndex + 1)}>
                                                    {isLoading ? <p>Loading...</p> : list != undefined ? <DetailedView labels={newHeaders} type={"customer"} data={list} /> : null}
                                                </TabPanel>
                                            </ValidatePermission>

                                            <ValidatePermission allowedModules={["02.03 - Schedule / Pet Profile"]}>
                                                <TabPanel value={value} index={(tabIndex = 1 && tabIndex + 1)}>
                                                    {isLoading ? <p>Loading...</p> : list != undefined ? <DetailedView labels={newHeaders} type={"pet"} data={list} /> : null}
                                                </TabPanel>
                                            </ValidatePermission>
                                        </div>
                                    </div>
                                    {/* TODO: */}
                                    {/* <ValidatePermission allowedModules={["02.01 - Schedule / New Booking"]}>
                                        {value === 0 ? (
                                            <div style={{ padding: "1.5rem" }}>
                                                <div>
                                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                                        <div
                                                            style={{
                                                                flexShrink: 0,
                                                                border: "1px solid #3f9544",
                                                                padding: "10px 15px",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                height: "40px",
                                                                borderRadius: "8px",
                                                                fontWeight: "600",
                                                                width: "fit-content",
                                                                color: "#3f9544",
                                                                flexDirection: "column",
                                                                background: "white",
                                                                marginBottom: "10px",
                                                            }}
                                                        >
                                                            <p style={{ margin: 0 }}>
                                                                {slotEmployeeList[selectedDate?.empIndex]?.dates[selectedDate?.dateIndex]?.date} :{" "}
                                                                {
                                                                    slotEmployeeList[selectedDate?.empIndex]?.dates[selectedDate?.dateIndex]?.slots[servicePets[activeServicePet]?.slotInfo?.slotIndex]
                                                                        ?.slots[0]?.slot
                                                                }
                                                            </p>
                                                            <p style={{ margin: 0 }}>
                                                                (~
                                                                {
                                                                    slotEmployeeList[selectedDate?.empIndex]?.dates[selectedDate?.dateIndex]?.slots[servicePets[activeServicePet]?.slotInfo?.slotIndex]
                                                                        ?.slots[0]?.duration
                                                                }{" "}
                                                                min)
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", gap: "20px" }}>
                                                    <ValidatePermission allowedModules={["02.01 - Schedule / New Booking"]} allowedSubRoutes={["Make Reservation"]}>
                                                        <Button variant="contained" color="primary" loading={isLoading} onClick={() => makeTheBooking()} disabled={isLoading}>
                                                            Make Reservation
                                                        </Button>
                                                    </ValidatePermission>
                                                    <ValidatePermission allowedModules={["02.01 - Schedule / New Booking"]} allowedSubRoutes={["Customer Invoices"]}>
                                                        <Button variant="contained" color="primary" loading={isLoading} onClick={() => createInvoice()} disabled={false}>
                                                            Create Invoice
                                                        </Button>
                                                        <Button variant="contained" color="primary" loading={isLoading} onClick={() => setIsOpenCustomerInvoicesDevModal(true)}>
                                                            Customer Invoices
                                                        </Button>
                                                    </ValidatePermission>
                                                </div>
                                            </div>
                                        ) : null}
                                    </ValidatePermission> */}
                                    {/* <CustomerInvoices appointmentDetails={ {numInvoiceID: customerInvoiceID} } isOpenCustomerInvoicesModal={isOpenCustomerInvoicesModal} setIsOpenCustomerInvoicesModal={(value) => setIsOpenCustomerInvoicesModal(value)} isPassedInvoice={true} isSuccess={(e)=>{
                                                        if(e === true){
                                                            console.log("payment made")
                                                            setIsOpenCustomerInvoicesModal(false)
                                                        }
                                                    }}/> */}
                                    <CustomerInvoiceList
                                        isOpenCustomerInvoicesModal={isOpenCustomerInvoicesDevModal}
                                        setIsOpenCustomerInvoicesModal={(value) => setIsOpenCustomerInvoicesDevModal(value)}
                                        customerID={props.id}
                                    />
                                </Modal>
                            </div>

                            <Modal open={isOpenAddServices}>
                                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem" }}>
                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                        <p style={{ color: "#002867", fontSize: "18px", fontWeight: "600", width: "100%" }}>Select Services</p>
                                        <CloseButton
                                            onClick={() => {
                                                setIsOpenAddServices(false)
                                            }}
                                        />
                                    </div>

                                    <div style={{ backgroundColor: "#fff", width: "100%" }}>
                                        <div style={{ display: "flex", gap: "20px" }}>
                                            <div style={{ width: "250px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                                <div style={{ overflowY: "scroll", maxHeight: "280px" }}>
                                                    {props.petList.map((pet, index) => {
                                                        return (
                                                            <div
                                                                key={index}
                                                                onClick={() => {
                                                                    setActiveServicePet(index)
                                                                }}
                                                                className={activeServicePet === index ? styles.service_pet_card_active : styles.service_pet_card}
                                                            >
                                                                <div style={{ display: "flex", alignItems: "center", padding: "0.5rem 0" }}>
                                                                    <div>
                                                                        <AvatarLabel
                                                                            isVip={false}
                                                                            img={
                                                                                pet?.img == "" || pet?.img == null ? placeholderImage : `${process.env.REACT_APP_MEDIA_URL}directories/pets/` + pet?.img
                                                                            }
                                                                            name=""
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <p style={{ margin: "0", fontWeight: "600", textTransform: "capitalize" }}>
                                                                            {Truncate(pet?.name, 20)}{" "}
                                                                            <span
                                                                                style={{
                                                                                    padding: "1px 5px",
                                                                                    color: "#fff",
                                                                                    background: "#2076FF",
                                                                                    margin: "0",
                                                                                    borderRadius: "50px",
                                                                                    fontWeight: "600",
                                                                                }}
                                                                            >
                                                                                {pet?.size[0]}
                                                                            </span>
                                                                        </p>
                                                                        <p className={styles.petMiniCardPBreed}>{Truncate(pet?.breed, 29)}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>

                                                <div style={{ marginBottom: "5px" }}>
                                                    <Accordion expanded={true} style={{ boxShadow: "none", border: "1px solid #F3F4F6" }}>
                                                        <AccordionSummary
                                                            sx={{ minHeight: "45px" }}
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            style={{ backgroundColor: "#F9FAFB", margin: "0" }}
                                                        >
                                                            <p style={{ margin: "0", color: "#002867" }}>Show summary</p>
                                                        </AccordionSummary>

                                                        <AccordionDetails>
                                                            <table style={{ fontSize: "14px", borderBottom: "1px solid #E5E7EB", paddingBottom: ".25rem", marginBottom: ".25rem" }}>
                                                                <tr>
                                                                    <td style={{ width: "100%", fontWeight: "500" }}>Total Service Cost</td>
                                                                    <td style={{ fontWeight: "500", textAlign: "right" }}>${beforeTax}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ color: "#16A34A" }}>Discount</td>
                                                                    <td style={{ color: "#16A34A", textAlign: "right" }}>-$0.00</td>
                                                                </tr>
                                                            </table>

                                                            <table style={{ fontSize: "14px" }}>
                                                                <tr>
                                                                    <td>Tax</td>
                                                                    <td style={{ textAlign: "right" }}>${tax}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ width: "100%", fontWeight: "500" }}>Grand total</td>
                                                                    <td style={{ fontWeight: "500", textAlign: "right" }}>${Math.round((tax + beforeTax) * 100) / 100}</td>
                                                                </tr>
                                                            </table>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                            </div>

                                            <div style={{ height: "100%", width: "700px" }}>
                                                <div style={{ height: "100%", width: "100%" }}>
                                                    <MaterialTabs style={{ height: "100%", width: "100%", borderBottom: "1px solid #E5E7EB" }} value={schvalue} onChange={handleServicesChange}>
                                                        <Tab disabled={true} style={{ width: "33.33%" }} label="Boarding" />
                                                        <Tab disabled={true} style={{ width: "33.33%" }} label="Daycare" />
                                                        <Tab style={{ width: "33.33%" }} label="Grooming" />
                                                    </MaterialTabs>
                                                </div>

                                                <TabPanel value={schvalue} index={0}></TabPanel>

                                                <TabPanel value={schvalue} index={1}></TabPanel>

                                                <TabPanel value={schvalue} index={2} style={{ marginTop: "10px" }}>
                                                    <div>
                                                        <Accordion expanded={expanded1 === "panel2"} onChange={handleTabChange2("panel2")} style={{ boxShadow: "none" }}>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                                style={{ backgroundColor: "#fff", padding: "0", marginTop: "-10px", marginBottom: "-10px", height: "50px", minHeight: "50px" }}
                                                            >
                                                                <p style={{ margin: "0", color: "#002867", fontSize: "14px", fontWeight: "600" }}>Services</p>
                                                            </AccordionSummary>

                                                            <AccordionDetails style={{ padding: "10px 0" }}>
                                                                <div style={{ overflowY: "scroll", height: "400px", paddingRight: "5px" }}>
                                                                    {isLoading ? (
                                                                        <p>Loading...</p>
                                                                    ) : additionalServiceList.length > 0 ? (
                                                                        additionalServiceList
                                                                            .sort((a, b) => {
                                                                                if (a.bitIsAdditional === false && b.bitIsAdditional === true) {
                                                                                    return -1
                                                                                } else if (a.bitIsAdditional === true && b.bitIsAdditional === false) {
                                                                                    return 1
                                                                                } else {
                                                                                    return 0
                                                                                }
                                                                            })
                                                                            .map((service, index) => {
                                                                                let matching
                                                                                if (servicePets[activeServicePet] !== undefined) {
                                                                                    matching = servicePets[activeServicePet].additionalService.some((x) => x.id === service.numServiceItemID)
                                                                                }
                                                                                return (
                                                                                    <>
                                                                                        {service.numServiceItemPreviousSalesPrice === 0 ? (
                                                                                            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                                                                                <Checkbox
                                                                                                    style={{ padding: "0" }}
                                                                                                    key={index}
                                                                                                    checked={matching ? true : false}
                                                                                                    onClick={() => {
                                                                                                        selectAdditionalService(index)
                                                                                                    }}
                                                                                                />
                                                                                                <div
                                                                                                    style={{ width: "100%" }}
                                                                                                    className={matching ? styles.main_service_accordion_active : styles.main_service_accordion}
                                                                                                    onClick={() => {
                                                                                                        selectAdditionalService(index)
                                                                                                    }}
                                                                                                >
                                                                                                    <div>
                                                                                                        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                                                                                            {service.bitIsAdditional === false ? (
                                                                                                                <Tooltip
                                                                                                                    title={
                                                                                                                        <>
                                                                                                                            <div style={{ padding: "5px" }}>
                                                                                                                                <p
                                                                                                                                    style={{
                                                                                                                                        fontSize: "14px",
                                                                                                                                        color: "white",
                                                                                                                                        fontWeight: "600",
                                                                                                                                        marginBottom: "5px",
                                                                                                                                    }}
                                                                                                                                ></p>
                                                                                                                                <p style={{ fontSize: "14px", color: "white", fontWeight: "600" }}>
                                                                                                                                    {service.varServiceItemName}
                                                                                                                                </p>
                                                                                                                                <div
                                                                                                                                    style={{
                                                                                                                                        display: "flex",
                                                                                                                                        flexDirection: "column",
                                                                                                                                        flexWrap: "wrap",
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    {service.SubServices.length > 0
                                                                                                                                        ? service.SubServices.map((sub, index) => {
                                                                                                                                              return (
                                                                                                                                                  <p
                                                                                                                                                      key={index}
                                                                                                                                                      style={{
                                                                                                                                                          whiteSpace: "pre-wrap",
                                                                                                                                                          fontWeight: "500",
                                                                                                                                                          color: "white",
                                                                                                                                                      }}
                                                                                                                                                  >
                                                                                                                                                      - {sub.SubServiceName}
                                                                                                                                                  </p>
                                                                                                                                              )
                                                                                                                                          })
                                                                                                                                        : null}
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </>
                                                                                                                    }
                                                                                                                >
                                                                                                                    <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>
                                                                                                                        (M)
                                                                                                                    </p>
                                                                                                                </Tooltip>
                                                                                                            ) : (
                                                                                                                <Tooltip
                                                                                                                    style={{ paddingLeft: "5px" }}
                                                                                                                    title={
                                                                                                                        <>
                                                                                                                            <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>
                                                                                                                                Additional Service
                                                                                                                            </p>
                                                                                                                            <p style={{ fontSize: "12px", color: "#fff", fontWeight: "500" }}>
                                                                                                                                {service.varServiceItemName}
                                                                                                                            </p>
                                                                                                                        </>
                                                                                                                    }
                                                                                                                >
                                                                                                                    <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>
                                                                                                                        (A)
                                                                                                                    </p>
                                                                                                                </Tooltip>
                                                                                                            )}
                                                                                                            <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>
                                                                                                                {service.varServiceItemName}
                                                                                                            </p>
                                                                                                            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                                                                                                {service.varMessageToEmployee !== null ? (
                                                                                                                    <Tooltip
                                                                                                                        title={
                                                                                                                            <>
                                                                                                                                <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>
                                                                                                                                    Message to the Employee
                                                                                                                                </p>
                                                                                                                                <p style={{ fontSize: "12px", color: "#fff" }}>
                                                                                                                                    {service.varMessageToEmployee}
                                                                                                                                </p>
                                                                                                                            </>
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <Email style={{ fontSize: "17px", color: "#979797" }} />
                                                                                                                    </Tooltip>
                                                                                                                ) : null}

                                                                                                                {service.varSalesCommissionMessage !== null ? (
                                                                                                                    <Tooltip
                                                                                                                        title={
                                                                                                                            <>
                                                                                                                                <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>
                                                                                                                                    Sales Commission Message
                                                                                                                                </p>
                                                                                                                                <p style={{ fontSize: "12px", color: "#fff" }}>
                                                                                                                                    {service.varSalesCommissionMessage}
                                                                                                                                </p>
                                                                                                                            </>
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <MonetizationOn style={{ fontSize: "17px", color: "#979797" }} />
                                                                                                                    </Tooltip>
                                                                                                                ) : null}

                                                                                                                {service.ChargeEntered !== null ? (
                                                                                                                    <Tooltip
                                                                                                                        title={
                                                                                                                            <>
                                                                                                                                <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>
                                                                                                                                    Charge Entered
                                                                                                                                </p>
                                                                                                                            </>
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <Payments style={{ fontSize: "18px", color: "#979797" }} />
                                                                                                                    </Tooltip>
                                                                                                                ) : null}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <p
                                                                                                            style={{
                                                                                                                color: "#4A5163",
                                                                                                                fontWeight: "400",
                                                                                                                fontSize: "12px",
                                                                                                                margin: "0",
                                                                                                                lineHeight: "1.25em",
                                                                                                            }}
                                                                                                        >
                                                                                                            {service.varServiceItemDescription}

                                                                                                            {service.varPriceChangeComment !== null ? (
                                                                                                                <span> | {service.varPriceChangeComment}</span>
                                                                                                            ) : null}
                                                                                                        </p>
                                                                                                    </div>

                                                                                                    <div style={{ borderRadius: "4px", padding: "0 5px 0 5px", backgroundColor: "#EFF5FF" }}>
                                                                                                        <p
                                                                                                            style={{
                                                                                                                color: "#2630B9",
                                                                                                                fontSize: "16px",
                                                                                                                fontWeight: "400",
                                                                                                                margin: "0",
                                                                                                                padding: "0 5px",
                                                                                                                height: "100%",
                                                                                                                display: "flex",
                                                                                                                alignItems: "center",
                                                                                                                whiteSpace: "nowrap",
                                                                                                            }}
                                                                                                        >
                                                                                                            <b>${service.numServiceItemSalesPrice}</b>
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : service.numServiceItemPreviousSalesPrice === service.numServiceItemSalesPrice ? (
                                                                                            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                                                                                <Checkbox
                                                                                                    style={{ padding: "0" }}
                                                                                                    key={index}
                                                                                                    checked={matching ? true : false}
                                                                                                    onClick={() => {
                                                                                                        selectAdditionalService(index)
                                                                                                    }}
                                                                                                />
                                                                                                <div
                                                                                                    style={{ width: "100%" }}
                                                                                                    className={matching ? styles.main_service_accordion_active : styles.main_service_accordion}
                                                                                                    onClick={() => {
                                                                                                        selectAdditionalService(index)
                                                                                                    }}
                                                                                                >
                                                                                                    <div>
                                                                                                        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                                                                                            {service.bitIsAdditional === false ? (
                                                                                                                <Tooltip
                                                                                                                    title={
                                                                                                                        <>
                                                                                                                            <div style={{ padding: "5px" }}>
                                                                                                                                <p
                                                                                                                                    style={{
                                                                                                                                        fontSize: "14px",
                                                                                                                                        color: "white",
                                                                                                                                        fontWeight: "600",
                                                                                                                                        marginBottom: "5px",
                                                                                                                                    }}
                                                                                                                                ></p>
                                                                                                                                <p style={{ fontSize: "14px", color: "white", fontWeight: "600" }}>
                                                                                                                                    {service.varServiceItemName}
                                                                                                                                </p>
                                                                                                                                <div
                                                                                                                                    style={{
                                                                                                                                        display: "flex",
                                                                                                                                        flexDirection: "column",
                                                                                                                                        flexWrap: "wrap",
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    {service.SubServices.length > 0
                                                                                                                                        ? service.SubServices.map((sub, index) => {
                                                                                                                                              return (
                                                                                                                                                  <p
                                                                                                                                                      key={index}
                                                                                                                                                      style={{
                                                                                                                                                          whiteSpace: "pre-wrap",
                                                                                                                                                          fontWeight: "500",
                                                                                                                                                          color: "white",
                                                                                                                                                      }}
                                                                                                                                                  >
                                                                                                                                                      - {sub.SubServiceName}
                                                                                                                                                  </p>
                                                                                                                                              )
                                                                                                                                          })
                                                                                                                                        : null}
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </>
                                                                                                                    }
                                                                                                                >
                                                                                                                    <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>
                                                                                                                        (M)
                                                                                                                    </p>
                                                                                                                </Tooltip>
                                                                                                            ) : (
                                                                                                                <Tooltip
                                                                                                                    style={{ paddingLeft: "5px" }}
                                                                                                                    title={
                                                                                                                        <>
                                                                                                                            <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>
                                                                                                                                Additional Service
                                                                                                                            </p>
                                                                                                                            <p style={{ fontSize: "12px", color: "#fff", fontWeight: "500" }}>
                                                                                                                                {service.varServiceItemName}
                                                                                                                            </p>
                                                                                                                        </>
                                                                                                                    }
                                                                                                                >
                                                                                                                    <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>
                                                                                                                        (A)
                                                                                                                    </p>
                                                                                                                </Tooltip>
                                                                                                            )}
                                                                                                            <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>
                                                                                                                {service.varServiceItemName}
                                                                                                            </p>
                                                                                                            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                                                                                                {service.varMessageToEmployee !== null ? (
                                                                                                                    <Tooltip
                                                                                                                        title={
                                                                                                                            <>
                                                                                                                                <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>
                                                                                                                                    Message to the Employee
                                                                                                                                </p>
                                                                                                                                <p style={{ fontSize: "12px", color: "#fff" }}>
                                                                                                                                    {service.varMessageToEmployee}
                                                                                                                                </p>
                                                                                                                            </>
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <Email style={{ fontSize: "17px", color: "#979797" }} />
                                                                                                                    </Tooltip>
                                                                                                                ) : null}

                                                                                                                {service.varSalesCommissionMessage !== null ? (
                                                                                                                    <Tooltip
                                                                                                                        title={
                                                                                                                            <>
                                                                                                                                <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>
                                                                                                                                    Sales Commission Message
                                                                                                                                </p>
                                                                                                                                <p style={{ fontSize: "12px", color: "#fff" }}>
                                                                                                                                    {service.varSalesCommissionMessage}
                                                                                                                                </p>
                                                                                                                            </>
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <MonetizationOn style={{ fontSize: "17px", color: "#979797" }} />
                                                                                                                    </Tooltip>
                                                                                                                ) : null}

                                                                                                                {service.ChargeEntered !== null ? (
                                                                                                                    <Tooltip
                                                                                                                        title={
                                                                                                                            <>
                                                                                                                                <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>
                                                                                                                                    Charge Entered
                                                                                                                                </p>
                                                                                                                            </>
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <Payments style={{ fontSize: "18px", color: "#979797" }} />
                                                                                                                    </Tooltip>
                                                                                                                ) : null}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <p
                                                                                                            style={{
                                                                                                                color: "#4A5163",
                                                                                                                fontWeight: "400",
                                                                                                                fontSize: "12px",
                                                                                                                margin: "0",
                                                                                                                lineHeight: "1.25em",
                                                                                                            }}
                                                                                                        >
                                                                                                            {service.varServiceItemDescription}
                                                                                                            {service.varPriceChangeComment !== null ? (
                                                                                                                <span> | {service.varPriceChangeComment}</span>
                                                                                                            ) : null}
                                                                                                        </p>
                                                                                                    </div>

                                                                                                    <div style={{ borderRadius: "4px", padding: "0 5px 0 5px", backgroundColor: "#EFF5FF" }}>
                                                                                                        <p
                                                                                                            style={{
                                                                                                                color: "#2630B9",
                                                                                                                fontSize: "16px",
                                                                                                                fontWeight: "400",
                                                                                                                margin: "0",
                                                                                                                padding: "0 5px",
                                                                                                                height: "100%",
                                                                                                                display: "flex",
                                                                                                                alignItems: "center",
                                                                                                                whiteSpace: "nowrap",
                                                                                                            }}
                                                                                                        >
                                                                                                            <b>${service.numServiceItemSalesPrice}</b>
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : service.numServiceItemSalesPrice > service.numServiceItemPreviousSalesPrice ? (
                                                                                            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                                                                                <Checkbox
                                                                                                    style={{ padding: "0" }}
                                                                                                    key={index}
                                                                                                    checked={matching ? true : false}
                                                                                                    onClick={() => {
                                                                                                        selectAdditionalService(index)
                                                                                                    }}
                                                                                                />
                                                                                                <div
                                                                                                    style={{ backgroundColor: "#FEE8E7", width: "100%", display: "flex", flexDirection: "column" }}
                                                                                                    className={matching ? styles.main_service_accordion_active : styles.main_service_accordion}
                                                                                                    onClick={() => {
                                                                                                        selectAdditionalService(index)
                                                                                                    }}
                                                                                                >
                                                                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                                        <div>
                                                                                                            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                                                                                                {service.bitIsAdditional === false ? (
                                                                                                                    <Tooltip
                                                                                                                        title={
                                                                                                                            <>
                                                                                                                                <div style={{ padding: "5px" }}>
                                                                                                                                    <p
                                                                                                                                        style={{
                                                                                                                                            fontSize: "14px",
                                                                                                                                            color: "white",
                                                                                                                                            fontWeight: "600",
                                                                                                                                            marginBottom: "5px",
                                                                                                                                        }}
                                                                                                                                    ></p>
                                                                                                                                    <p style={{ fontSize: "14px", color: "white", fontWeight: "600" }}>
                                                                                                                                        {service.varServiceItemName}
                                                                                                                                    </p>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            display: "flex",
                                                                                                                                            flexDirection: "column",
                                                                                                                                            flexWrap: "wrap",
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        {service.SubServices.length > 0
                                                                                                                                            ? service.SubServices.map((sub, index) => {
                                                                                                                                                  return (
                                                                                                                                                      <p
                                                                                                                                                          key={index}
                                                                                                                                                          style={{
                                                                                                                                                              whiteSpace: "pre-wrap",
                                                                                                                                                              fontWeight: "500",
                                                                                                                                                              color: "white",
                                                                                                                                                              margin: "0",
                                                                                                                                                          }}
                                                                                                                                                      >
                                                                                                                                                          {sub.SubServiceName}
                                                                                                                                                      </p>
                                                                                                                                                  )
                                                                                                                                              })
                                                                                                                                            : null}
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </>
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <p
                                                                                                                            style={{
                                                                                                                                margin: "0",
                                                                                                                                fontWeight: "600",
                                                                                                                                color: "#002867",
                                                                                                                                fontSize: "14px",
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            (M)
                                                                                                                        </p>
                                                                                                                    </Tooltip>
                                                                                                                ) : (
                                                                                                                    <Tooltip
                                                                                                                        style={{ paddingLeft: "5px" }}
                                                                                                                        title={
                                                                                                                            <>
                                                                                                                                <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>
                                                                                                                                    Additional Service
                                                                                                                                </p>
                                                                                                                                <p style={{ fontSize: "12px", color: "#fff", fontWeight: "500" }}>
                                                                                                                                    {service.varServiceItemName}
                                                                                                                                </p>
                                                                                                                            </>
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <p
                                                                                                                            style={{
                                                                                                                                margin: "0",
                                                                                                                                fontWeight: "600",
                                                                                                                                color: "#002867",
                                                                                                                                fontSize: "14px",
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            (A)
                                                                                                                        </p>
                                                                                                                    </Tooltip>
                                                                                                                )}
                                                                                                                <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>
                                                                                                                    {service.varServiceItemName}
                                                                                                                </p>
                                                                                                                <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                                                                                                    {service.varMessageToEmployee !== null ? (
                                                                                                                        <Tooltip
                                                                                                                            title={
                                                                                                                                <>
                                                                                                                                    <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>
                                                                                                                                        Message to the Employee
                                                                                                                                    </p>
                                                                                                                                    <p style={{ fontSize: "12px", color: "#fff" }}>
                                                                                                                                        {service.varMessageToEmployee}
                                                                                                                                    </p>
                                                                                                                                </>
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <Email style={{ fontSize: "17px", color: "#979797" }} />
                                                                                                                        </Tooltip>
                                                                                                                    ) : null}

                                                                                                                    {service.varSalesCommissionMessage !== null ? (
                                                                                                                        <Tooltip
                                                                                                                            title={
                                                                                                                                <>
                                                                                                                                    <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>
                                                                                                                                        Sales Commission Message
                                                                                                                                    </p>
                                                                                                                                    <p style={{ fontSize: "12px", color: "#fff" }}>
                                                                                                                                        {service.varSalesCommissionMessage}
                                                                                                                                    </p>
                                                                                                                                </>
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <MonetizationOn style={{ fontSize: "17px", color: "#979797" }} />
                                                                                                                        </Tooltip>
                                                                                                                    ) : null}

                                                                                                                    {service.ChargeEntered !== null ? (
                                                                                                                        <Tooltip
                                                                                                                            title={
                                                                                                                                <>
                                                                                                                                    <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>
                                                                                                                                        Charge Entered
                                                                                                                                    </p>
                                                                                                                                </>
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <Payments style={{ fontSize: "18px", color: "#979797" }} />
                                                                                                                        </Tooltip>
                                                                                                                    ) : null}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <p
                                                                                                                style={{
                                                                                                                    color: "#4A5163",
                                                                                                                    fontWeight: "400",
                                                                                                                    fontSize: "12px",
                                                                                                                    margin: "0",
                                                                                                                    lineHeight: "1.25em",
                                                                                                                }}
                                                                                                            >
                                                                                                                {service.varServiceItemDescription}
                                                                                                                {service.varPriceChangeComment !== null ? (
                                                                                                                    <span> | {service.varPriceChangeComment}</span>
                                                                                                                ) : null}
                                                                                                            </p>
                                                                                                        </div>

                                                                                                        <div style={{ display: "flex", gap: "5px" }}>
                                                                                                            <div style={{ borderRadius: "4px", padding: "0 5px 0 5px", backgroundColor: "#FCDADA" }}>
                                                                                                                <p
                                                                                                                    style={{
                                                                                                                        color: "#802B2B",
                                                                                                                        fontSize: "16px",
                                                                                                                        fontWeight: "400",
                                                                                                                        margin: "0",
                                                                                                                        padding: "0 5px",
                                                                                                                        height: "100%",
                                                                                                                        display: "flex",
                                                                                                                        alignItems: "center",
                                                                                                                        whiteSpace: "nowrap",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <ArrowUpward style={{ color: "#EB1A1A", fontSize: "18px" }} />
                                                                                                                </p>
                                                                                                            </div>

                                                                                                            <div
                                                                                                                style={{
                                                                                                                    display: "flex",
                                                                                                                    alignItems: "center",
                                                                                                                    minWidth: "110px",
                                                                                                                    border: "1px solid #F9B5B5",
                                                                                                                    borderRadius: "4px",
                                                                                                                    padding: "0 5px 0 5px",
                                                                                                                    backgroundColor: "#FCDADA",
                                                                                                                }}
                                                                                                            >
                                                                                                                <Tooltip
                                                                                                                    style={{ paddingLeft: "5px" }}
                                                                                                                    title={
                                                                                                                        <>
                                                                                                                            <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>
                                                                                                                                Price Change Comment
                                                                                                                            </p>
                                                                                                                            <p style={{ fontSize: "12px", color: "#fff" }}>
                                                                                                                                {service.varPriceChangeComment}
                                                                                                                            </p>
                                                                                                                        </>
                                                                                                                    }
                                                                                                                >
                                                                                                                    <InfoOutlined style={{ fontSize: "18px", color: "#802B2B" }} />
                                                                                                                </Tooltip>

                                                                                                                <p
                                                                                                                    style={{
                                                                                                                        color: "#802B2B",
                                                                                                                        fontSize: "16px",
                                                                                                                        fontWeight: "400",
                                                                                                                        margin: "0",
                                                                                                                        padding: "0 5px",
                                                                                                                        height: "100%",
                                                                                                                        display: "flex",
                                                                                                                        alignItems: "center",
                                                                                                                        whiteSpace: "nowrap",
                                                                                                                        justifyContent: "center",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <del>${service.numServiceItemPreviousSalesPrice}</del>&nbsp;/&nbsp;
                                                                                                                    <b>${service.numServiceItemSalesPrice}</b>
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    {service.varSeasonalPromotionMsg !== "" ? (
                                                                                                        <p
                                                                                                            style={{
                                                                                                                display: "flex",
                                                                                                                gap: "5px",
                                                                                                                alignItems: "center",
                                                                                                                padding: "4px",
                                                                                                                borderRadius: "5px",
                                                                                                                backgroundColor: "#fff",
                                                                                                                color: "#16A34A",
                                                                                                                fontWeight: 500,
                                                                                                                fontSize: "12px",
                                                                                                                margin: "0px",
                                                                                                                lineHeight: "1.25em",
                                                                                                            }}
                                                                                                        >
                                                                                                            <LocalOffer style={{ fontSize: "18px" }} /> {service.varSeasonalPromotionMsg}
                                                                                                        </p>
                                                                                                    ) : null}
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                                                                                <Checkbox
                                                                                                    style={{ padding: "0" }}
                                                                                                    key={index}
                                                                                                    checked={matching ? true : false}
                                                                                                    onClick={() => {
                                                                                                        selectAdditionalService(index)
                                                                                                    }}
                                                                                                />
                                                                                                <div
                                                                                                    style={{ backgroundColor: "#F0FDF4", width: "100%" }}
                                                                                                    className={matching ? styles.main_service_accordion_active : styles.main_service_accordion}
                                                                                                    onClick={() => {
                                                                                                        selectAdditionalService(index)
                                                                                                    }}
                                                                                                >
                                                                                                    <div>
                                                                                                        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                                                                                            {service.bitIsAdditional === false ? (
                                                                                                                <Tooltip
                                                                                                                    title={
                                                                                                                        <>
                                                                                                                            <div style={{ padding: "5px" }}>
                                                                                                                                <p
                                                                                                                                    style={{
                                                                                                                                        fontSize: "14px",
                                                                                                                                        color: "white",
                                                                                                                                        fontWeight: "600",
                                                                                                                                        marginBottom: "5px",
                                                                                                                                    }}
                                                                                                                                ></p>
                                                                                                                                <p style={{ fontSize: "14px", color: "white", fontWeight: "600" }}>
                                                                                                                                    {service.varServiceItemName}
                                                                                                                                </p>
                                                                                                                                <div
                                                                                                                                    style={{
                                                                                                                                        display: "flex",
                                                                                                                                        flexDirection: "column",
                                                                                                                                        flexWrap: "wrap",
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    {service.SubServices.length > 0
                                                                                                                                        ? service.SubServices.map((sub, index) => {
                                                                                                                                              return (
                                                                                                                                                  <p
                                                                                                                                                      key={index}
                                                                                                                                                      style={{
                                                                                                                                                          whiteSpace: "pre-wrap",
                                                                                                                                                          fontWeight: "500",
                                                                                                                                                          color: "white",
                                                                                                                                                      }}
                                                                                                                                                  >
                                                                                                                                                      - {sub.SubServiceName}
                                                                                                                                                  </p>
                                                                                                                                              )
                                                                                                                                          })
                                                                                                                                        : null}
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </>
                                                                                                                    }
                                                                                                                >
                                                                                                                    <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>
                                                                                                                        (M)
                                                                                                                    </p>
                                                                                                                </Tooltip>
                                                                                                            ) : (
                                                                                                                <Tooltip
                                                                                                                    style={{ paddingLeft: "5px" }}
                                                                                                                    title={
                                                                                                                        <>
                                                                                                                            <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>
                                                                                                                                Additional Service
                                                                                                                            </p>
                                                                                                                            <p style={{ fontSize: "12px", color: "#fff", fontWeight: "500" }}>
                                                                                                                                {service.varServiceItemName}
                                                                                                                            </p>
                                                                                                                        </>
                                                                                                                    }
                                                                                                                >
                                                                                                                    <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>
                                                                                                                        (A)
                                                                                                                    </p>
                                                                                                                </Tooltip>
                                                                                                            )}
                                                                                                            <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>
                                                                                                                {service.varServiceItemName}
                                                                                                            </p>
                                                                                                            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                                                                                                {service.varMessageToEmployee !== null ? (
                                                                                                                    <Tooltip
                                                                                                                        title={
                                                                                                                            <>
                                                                                                                                <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>
                                                                                                                                    Message to the Employee
                                                                                                                                </p>
                                                                                                                                <p style={{ fontSize: "12px", color: "#fff" }}>
                                                                                                                                    {service.varMessageToEmployee}
                                                                                                                                </p>
                                                                                                                            </>
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <Email style={{ fontSize: "17px", color: "#979797" }} />
                                                                                                                    </Tooltip>
                                                                                                                ) : null}

                                                                                                                {service.varSalesCommissionMessage !== null ? (
                                                                                                                    <Tooltip
                                                                                                                        title={
                                                                                                                            <>
                                                                                                                                <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>
                                                                                                                                    Sales Commission Message
                                                                                                                                </p>
                                                                                                                                <p style={{ fontSize: "12px", color: "#fff" }}>
                                                                                                                                    {service.varSalesCommissionMessage}
                                                                                                                                </p>
                                                                                                                            </>
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <MonetizationOn style={{ fontSize: "17px", color: "#979797" }} />
                                                                                                                    </Tooltip>
                                                                                                                ) : null}

                                                                                                                {service.ChargeEntered !== null ? (
                                                                                                                    <Tooltip
                                                                                                                        title={
                                                                                                                            <>
                                                                                                                                <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>
                                                                                                                                    Charge Entered
                                                                                                                                </p>
                                                                                                                            </>
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <Payments style={{ fontSize: "18px", color: "#979797" }} />
                                                                                                                    </Tooltip>
                                                                                                                ) : null}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <p
                                                                                                            style={{
                                                                                                                color: "#4A5163",
                                                                                                                fontWeight: "400",
                                                                                                                fontSize: "12px",
                                                                                                                margin: "0",
                                                                                                                lineHeight: "1.25em",
                                                                                                            }}
                                                                                                        >
                                                                                                            {service.varServiceItemDescription}
                                                                                                            {service.varPriceChangeComment !== null ? (
                                                                                                                <span> | {service.varPriceChangeComment}</span>
                                                                                                            ) : null}
                                                                                                        </p>
                                                                                                    </div>

                                                                                                    <div style={{ display: "flex", gap: "5px" }}>
                                                                                                        <div style={{ borderRadius: "4px", padding: "0 5px 0 5px", backgroundColor: "#DCFCE7" }}>
                                                                                                            <p
                                                                                                                style={{
                                                                                                                    color: "#DCFCE7",
                                                                                                                    fontSize: "16px",
                                                                                                                    fontWeight: "400",
                                                                                                                    margin: "0",
                                                                                                                    padding: "0 5px",
                                                                                                                    height: "100%",
                                                                                                                    display: "flex",
                                                                                                                    alignItems: "center",
                                                                                                                    whiteSpace: "nowrap",
                                                                                                                }}
                                                                                                            >
                                                                                                                <ArrowDownward style={{ color: "#16A34A", fontSize: "18px" }} />
                                                                                                            </p>
                                                                                                        </div>

                                                                                                        <div
                                                                                                            style={{
                                                                                                                display: "flex",
                                                                                                                alignItems: "center",
                                                                                                                minWidth: "120px",
                                                                                                                border: "1px solid #BBF7D0",
                                                                                                                borderRadius: "4px",
                                                                                                                padding: "0 5px 0 5px",
                                                                                                                backgroundColor: "#DCFCE7",
                                                                                                            }}
                                                                                                        >
                                                                                                            <Tooltip
                                                                                                                style={{ paddingLeft: "5px" }}
                                                                                                                title={
                                                                                                                    <>
                                                                                                                        <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>
                                                                                                                            Price Change Comment
                                                                                                                        </p>
                                                                                                                        <p style={{ fontSize: "12px", color: "#fff" }}>
                                                                                                                            {service.varPriceChangeComment}
                                                                                                                        </p>
                                                                                                                    </>
                                                                                                                }
                                                                                                            >
                                                                                                                <InfoOutlined style={{ fontSize: "18px", color: "#15803D" }} />
                                                                                                            </Tooltip>
                                                                                                            <p
                                                                                                                style={{
                                                                                                                    color: "#15803D",
                                                                                                                    fontSize: "16px",
                                                                                                                    fontWeight: "400",
                                                                                                                    margin: "0",
                                                                                                                    padding: "0 5px",
                                                                                                                    height: "100%",
                                                                                                                    display: "flex",
                                                                                                                    alignItems: "center",
                                                                                                                    whiteSpace: "nowrap",
                                                                                                                    justifyContent: "center",
                                                                                                                }}
                                                                                                            >
                                                                                                                <del>${service.numServiceItemPreviousSalesPrice}</del>&nbsp;/&nbsp;
                                                                                                                <b>${service.numServiceItemSalesPrice}</b>
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                    </>
                                                                                )
                                                                            })
                                                                    ) : (
                                                                        <p style={{ color: "#777E90", fontWeight: "400", fontSize: "13px" }}>No services found</p>
                                                                    )}
                                                                </div>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </div>
                                                </TabPanel>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        style={{
                                            textAlign: "end",
                                            width: "100%",
                                            backgroundColor: "#EFF5FF",
                                            padding: "1rem",
                                            marginBottom: "-20px",
                                            borderBottomLeftRadius: "15px",
                                            borderBottomRightRadius: "15px",
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleHoldingTank()}
                                            loading={isLoading}
                                            className={styles.buttonText}
                                            disabled={isLoading}
                                            text={"Save & Continue"}
                                        />
                                    </div>
                                </div>
                            </Modal>

                            <Modal open={isOpenAddProduct}>
                                <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                        <p style={{ color: "#002867", fontSize: "18px", fontWeight: "600", width: "100%" }}>Select Products</p>
                                        <CloseButton onClick={() => setIsOpenAddProduct(false)} />
                                    </div>
                                    <div style={{ backgroundColor: "#fff" }}>
                                        <div style={{ display: "flex", gap: "20px" }}>
                                            {
                                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                                    <div style={{ overflowY: "scroll", maxHeight: "280px" }}>
                                                        {props.petList.map((pet, index) => {
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    onClick={() => {
                                                                        selectActivePet(index)
                                                                    }}
                                                                    className={activeProductPet === index ? styles.service_pet_card_active : styles.service_pet_card}
                                                                >
                                                                    <div style={{ display: "flex", alignItems: "center", padding: "0.5rem 0" }}>
                                                                        <div>
                                                                            <AvatarLabel
                                                                                isVip={false}
                                                                                img={
                                                                                    pet?.img == "" || pet?.img == null
                                                                                        ? placeholderImage
                                                                                        : `${process.env.REACT_APP_MEDIA_URL}directories/pets/` + pet?.img
                                                                                }
                                                                                name=""
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <p style={{ margin: "0", fontWeight: "600", textTransform: "capitalize" }}>
                                                                                {Truncate(pet?.name, 20)}{" "}
                                                                                <span
                                                                                    style={{
                                                                                        padding: "1px 5px",
                                                                                        color: "#fff",
                                                                                        background: "#2076FF",
                                                                                        margin: "0",
                                                                                        borderRadius: "50px",
                                                                                        fontWeight: "600",
                                                                                    }}
                                                                                >
                                                                                    {pet?.size[0]}
                                                                                </span>
                                                                            </p>
                                                                            <p className={styles.petMiniCardPBreed}>{Truncate(pet?.breed, 29)}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            }

                                            <Box>
                                                <Box>
                                                    <Tabs value={productTabIndex} onChange={handleProductTabChange}>
                                                        <Tab label="Products" />
                                                        <Tab
                                                            label="Cart"
                                                            icon={<span style={{ color: "#2076FF", background: "#F5F5F5", borderRadius: "24px", padding: "2px 10px" }}>{cartItemCount}</span>}
                                                            iconPosition={"end"}
                                                        />
                                                    </Tabs>
                                                </Box>
                                                <Box>
                                                    {productTabIndex === 0 && (
                                                        <div style={{ width: "100%", marginTop: "20px" }}>
                                                            <div style={{ overflowY: "scroll", width: "800px", minHeight: "300px", maxHeight: "550px" }}>
                                                                {isLoading ? (
                                                                    <p>Loading...</p>
                                                                ) : productList.length > 0 ? (
                                                                    productList.map((product, index) => {
                                                                        return (
                                                                            <div
                                                                                key={index}
                                                                                style={{
                                                                                    borderRadius: "8px",
                                                                                    border: "1px solid #EFF3FA",
                                                                                    background: "#FFFFFF",
                                                                                    display: "flex",
                                                                                    justifyContent: "space-between",
                                                                                    padding: "10px",
                                                                                    marginBottom: "10px",
                                                                                }}
                                                                            >
                                                                                <div style={{ display: "flex", gap: "15px" }}>
                                                                                    <div style={{ border: "1px solid #EFF3FA", borderRadius: "6px" }}>
                                                                                        <img
                                                                                            alt=""
                                                                                            src={
                                                                                                product?.varProductBarCode === "" || product?.varProductBarCode === null
                                                                                                    ? undefined
                                                                                                    : `${process.env.REACT_APP_MEDIA_URL}products/${product?.varProductBarCode}.png`
                                                                                            }
                                                                                            style={{
                                                                                                objectFit: "scale-down",
                                                                                                borderRadius: "5px",
                                                                                                width: "160px",
                                                                                                height: "160px",
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                                                        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                                                                            <p style={{ color: "#192252", fontSize: "14px", fontWeight: "600" }}>{product.varProductName}</p>
                                                                                            <Tooltip
                                                                                                style={{ paddingLeft: "5px" }}
                                                                                                title={
                                                                                                    <>
                                                                                                        <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Product Description</p>
                                                                                                        <p style={{ fontSize: "12px", color: "#fff" }}>{product.varProductDescription}</p>
                                                                                                    </>
                                                                                                }
                                                                                            >
                                                                                                <InfoOutlined style={{ fontSize: "18px", color: "#606D93" }} />
                                                                                            </Tooltip>
                                                                                        </div>
                                                                                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                                                            {product?.varPromotionalMessage !== null && product?.varPromotionalMessage !== "" ? (
                                                                                                <Tooltip
                                                                                                    style={{ paddingLeft: "5px" }}
                                                                                                    title={
                                                                                                        <>
                                                                                                            <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Promotional Message</p>
                                                                                                            <p style={{ fontSize: "12px", color: "#fff" }}>{product?.varPromotionalMessage}</p>
                                                                                                        </>
                                                                                                    }
                                                                                                >
                                                                                                    <Campaign style={{ fontSize: "20px", color: "#139c4a" }} />
                                                                                                </Tooltip>
                                                                                            ) : null}

                                                                                            {product?.varMessageToEmployee !== null && product?.varMessageToEmployee !== "" ? (
                                                                                                <Tooltip
                                                                                                    style={{ paddingLeft: "5px" }}
                                                                                                    title={
                                                                                                        <>
                                                                                                            <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Message To Employee</p>
                                                                                                            <p style={{ fontSize: "12px", color: "#fff" }}>{product?.varMessageToEmployee}</p>
                                                                                                        </>
                                                                                                    }
                                                                                                >
                                                                                                    <Email style={{ fontSize: "20px", color: "#2871ca" }} />
                                                                                                </Tooltip>
                                                                                            ) : null}
                                                                                            {(product?.numLocationSpecificAdjustmentPercent !== null &&
                                                                                                product?.numLocationSpecificAdjustmentPercent !== "" &&
                                                                                                product?.numLocationSpecificAdjustmentPercent !== 0) ||
                                                                                            (product?.numLocationSpecificAdjustmentAmount !== null &&
                                                                                                product?.numLocationSpecificAdjustmentAmount !== "" &&
                                                                                                product?.numLocationSpecificAdjustmentAmount !== 0) ? (
                                                                                                <Tooltip
                                                                                                    style={{ paddingLeft: "5px" }}
                                                                                                    title={
                                                                                                        <>
                                                                                                            <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>
                                                                                                                Location Specific Adjustment
                                                                                                            </p>
                                                                                                            <p style={{ fontSize: "12px", color: "#fff" }}>
                                                                                                                Percentage: {product?.numLocationSpecificAdjustmentPercent}%
                                                                                                            </p>
                                                                                                            <p style={{ fontSize: "12px", color: "#fff" }}>
                                                                                                                Amount: {product?.numLocationSpecificAdjustmentAmount}$
                                                                                                            </p>
                                                                                                        </>
                                                                                                    }
                                                                                                >
                                                                                                    <Store style={{ fontSize: "20px", color: "#2871ca" }} />
                                                                                                </Tooltip>
                                                                                            ) : null}
                                                                                            {(product?.numDateSpecificAdjustmentPercent !== null &&
                                                                                                product?.numDateSpecificAdjustmentPercent !== "" &&
                                                                                                product?.numDateSpecificAdjustmentPercent !== 0) ||
                                                                                            (product?.numDateSpecificAdjustmentAmount !== null &&
                                                                                                product?.numDateSpecificAdjustmentAmount !== "" &&
                                                                                                product?.numDateSpecificAdjustmentAmount !== 0) ? (
                                                                                                <Tooltip
                                                                                                    style={{ paddingLeft: "5px" }}
                                                                                                    title={
                                                                                                        <>
                                                                                                            <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>
                                                                                                                Date Specific Adjustment
                                                                                                            </p>
                                                                                                            <p style={{ fontSize: "12px", color: "#fff" }}>
                                                                                                                Percentage: {product?.numDateSpecificAdjustmentPercent}%
                                                                                                            </p>
                                                                                                            <p style={{ fontSize: "12px", color: "#fff" }}>
                                                                                                                Amount: {product?.numDateSpecificAdjustmentAmount}$
                                                                                                            </p>
                                                                                                        </>
                                                                                                    }
                                                                                                >
                                                                                                    <CalendarMonth style={{ fontSize: "19px", color: "#2871ca" }} />
                                                                                                </Tooltip>
                                                                                            ) : null}
                                                                                        </div>
                                                                                        <div style={{ color: "#606D93", fontSize: "12px", fontWeight: "400" }}>
                                                                                            {product.varSalesCommissionMessage} {product.varSeasonalPromotionMsg}
                                                                                        </div>
                                                                                        <div style={{ display: "flex", gap: "10px" }}>
                                                                                            <p style={{ color: "#606D93", fontSize: "12px", fontWeight: "400" }}>
                                                                                                Available Quantity: {product.TempAvailableQuantity}
                                                                                            </p>
                                                                                            <p style={{ color: "#606D93", fontSize: "12px", fontWeight: "400" }}>
                                                                                                Unit Price: {product.UnityItemPrice}$
                                                                                            </p>
                                                                                            {product.isAddedToCart === true ? (
                                                                                                <div style={{ display: "flex", gap: "2px", alignItems: "center" }}>
                                                                                                    <ShoppingCart style={{ color: "#00a340", fontSize: "18px" }} />
                                                                                                    <p style={{ color: "#00a340" }}>Added to cart</p>
                                                                                                </div>
                                                                                            ) : null}
                                                                                        </div>

                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "space-between",
                                                                                                alignItems: "center",
                                                                                                width: "fit-content",
                                                                                                gap: "10px",
                                                                                                marginTop: "15px",
                                                                                            }}
                                                                                        >
                                                                                            <IconButton
                                                                                                onClick={() => {
                                                                                                    decrementQty(product.numLocationProductID, index)
                                                                                                }}
                                                                                            >
                                                                                                <RemoveCircleOutline className={styles.products_add_remove_button} />
                                                                                            </IconButton>
                                                                                            <p style={{ fontSize: "14px", fontWeight: "600", color: "#192252" }}>{product.SelectedQuantity}</p>
                                                                                            <IconButton
                                                                                                onClick={() => {
                                                                                                    incrementQty(product.numLocationProductID, index)
                                                                                                }}
                                                                                            >
                                                                                                <AddCircleOutline className={styles.products_add_remove_button} />
                                                                                            </IconButton>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                                                                    <p style={{ color: "#192252", fontSize: "18px", fontWeight: "700", textAlign: "right" }}>
                                                                                        $
                                                                                        {product.SelectedQuantity > 0
                                                                                            ? Math.round(product.numProductSalesPrice * product.SelectedQuantity * 100) / 100
                                                                                            : product.numProductSalesPrice}
                                                                                    </p>
                                                                                    <MUIButton
                                                                                        variant="outlined"
                                                                                        disabled={product.SelectedQuantity < 1 ? true : false}
                                                                                        children={
                                                                                            <ShoppingCart
                                                                                                style={
                                                                                                    product.SelectedQuantity < 1
                                                                                                        ? { color: "#9097a9", padding: "10px" }
                                                                                                        : { color: "#2076ff", padding: "10px" }
                                                                                                }
                                                                                            />
                                                                                        }
                                                                                        onClick={() => {
                                                                                            addToCart(product.numLocationProductID, index)
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                ) : (
                                                                    <p>No products found</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}

                                                    {productTabIndex === 1 && (
                                                        <div style={{ width: "100%", marginTop: "20px" }}>
                                                            <div style={{ overflowY: "scroll", width: "800px", minHeight: "300px", maxHeight: "550px" }}>
                                                                {isLoading ? (
                                                                    <p>Loading...</p>
                                                                ) : servicePets.length > 0 ? (
                                                                    servicePets[activeProductPet] !== undefined ? (
                                                                        servicePets[activeProductPet].selectedProducts
                                                                            .filter((x) => x.bitActive === true)
                                                                            .map((product, index) => {
                                                                                return (
                                                                                    <div
                                                                                        key={index}
                                                                                        style={{
                                                                                            borderRadius: "8px",
                                                                                            border: "1px solid #EFF3FA",
                                                                                            background: "#FFFFFF",
                                                                                            display: "flex",
                                                                                            justifyContent: "space-between",
                                                                                            padding: "10px",
                                                                                            marginBottom: "10px",
                                                                                        }}
                                                                                    >
                                                                                        <div style={{ display: "flex", gap: "15px" }}>
                                                                                            <div style={{ border: "1px solid #EFF3FA", borderRadius: "6px" }}>
                                                                                                <img
                                                                                                    alt=""
                                                                                                    src={
                                                                                                        product?.varProductBarCode === "" || product?.varProductBarCode === null
                                                                                                            ? undefined
                                                                                                            : `${process.env.REACT_APP_MEDIA_URL}products/${product?.varProductBarCode}.png`
                                                                                                    }
                                                                                                    style={{
                                                                                                        objectFit: "scale-down",
                                                                                                        borderRadius: "5px",
                                                                                                        width: "160px",
                                                                                                        height: "160px",
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                                                                <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                                                                                    <p style={{ color: "#192252", fontSize: "14px", fontWeight: "600" }}>{product.varProductName}</p>
                                                                                                    <Tooltip
                                                                                                        style={{ paddingLeft: "5px" }}
                                                                                                        title={
                                                                                                            <>
                                                                                                                <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>
                                                                                                                    Product Description
                                                                                                                </p>
                                                                                                                <p style={{ fontSize: "12px", color: "#fff" }}>{product.varProductDescription}</p>
                                                                                                            </>
                                                                                                        }
                                                                                                    >
                                                                                                        <InfoOutlined style={{ fontSize: "18px", color: "#606D93" }} />
                                                                                                    </Tooltip>
                                                                                                </div>
                                                                                                <div style={{ color: "#606D93", fontSize: "12px", fontWeight: "400" }}>
                                                                                                    {product.varSalesCommissionMessage} {product.varSeasonalPromotionMsg}
                                                                                                </div>
                                                                                                <div
                                                                                                    style={{
                                                                                                        display: "flex",
                                                                                                        justifyContent: "space-between",
                                                                                                        alignItems: "center",
                                                                                                        width: "fit-content",
                                                                                                        gap: "10px",
                                                                                                        marginTop: "15px",
                                                                                                    }}
                                                                                                >
                                                                                                    <IconButton
                                                                                                        onClick={() => {
                                                                                                            decrementCartQty(product.numLocationProductID, index)
                                                                                                        }}
                                                                                                    >
                                                                                                        <RemoveCircleOutline className={styles.products_add_remove_button} />
                                                                                                    </IconButton>
                                                                                                    <p style={{ fontSize: "14px", fontWeight: "600", color: "#192252" }}>{product.numQuantity}</p>
                                                                                                    <IconButton
                                                                                                        onClick={() => {
                                                                                                            incrementCartQty(product.numLocationProductID, index)
                                                                                                        }}
                                                                                                    >
                                                                                                        <AddCircleOutline className={styles.products_add_remove_button} />
                                                                                                    </IconButton>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                                                                            <p style={{ color: "#192252", fontSize: "18px", fontWeight: "700", textAlign: "right" }}>
                                                                                                ${Math.round(product.numProductSalesPrice * product.numQuantity * 100) / 100}
                                                                                            </p>
                                                                                            <MUIButton
                                                                                                variant="outlined"
                                                                                                children={<Delete style={{ color: "#2076ff", padding: "10px" }} />}
                                                                                                onClick={() => {
                                                                                                    removeFromCart(product.numLocationProductID, index)
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                    ) : (
                                                                        ""
                                                                    )
                                                                ) : (
                                                                    <p>Cart is empty</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </Box>
                                            </Box>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            textAlign: "end",
                                            backgroundColor: "#F9FAFB",
                                            padding: "1rem",
                                            marginBottom: "-15px",
                                            borderBottomLeftRadius: "12px",
                                            borderBottomRightRadius: "12px",
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                            <p style={{ color: "#606D93", fontSize: "22px", fontWeight: "600", margin: "0" }}>Total</p>
                                            <p style={{ color: "#192252", fontSize: "22px", fontWeight: "600", margin: "0" }}>${Math.round(productCartAmount * 100) / 100}</p>
                                        </div>

                                        <Button variant="contained" color="primary" loading={isLoading} onClick={() => handleProductSave()} className={styles.buttonText}>
                                            Save & Continue
                                        </Button>
                                    </div>
                                </div>
                            </Modal>

                            <Modal open={isOpenAddProductNoPet}>
                                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem" }}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                        <p style={{ color: "#002867", fontSize: "18px", fontWeight: "600", width: "100%" }}>Select Products</p>
                                        <CloseButton onClick={() => setIsOpenAddProductNoPet(false)} />
                                    </div>
                                    <div style={{ backgroundColor: "#fff", width: "700px" }}>
                                        <Box>
                                            <Box>
                                                <Tabs value={productTabIndexNoPet} onChange={handleProductTabChangeNoPet}>
                                                    <Tab label="Products" />
                                                    <Tab
                                                        label="Cart"
                                                        icon={<span style={{ color: "#2076FF", background: "#F5F5F5", borderRadius: "24px", padding: "2px 10px" }}>{cartItemCount}</span>}
                                                        iconPosition={"end"}
                                                    />
                                                </Tabs>
                                            </Box>
                                            <Box>
                                                {productTabIndexNoPet === 0 && (
                                                    <div style={{ width: "100%", marginTop: "20px" }}>
                                                        {/* <TextField
                                                                                    style={{ width: '30%' }}
                                                                                    label={
                                                                                        <div style={{ display: 'flex', fontSize: '14px' }}>
                                                                                        Filter products
                                                                                        </div>
                                                                                    }
                                                                                    type={'text'}
                                                                                    size='small'
                                                                                    variant="outlined"
                                                                                    multiline={false}
                                                                                    onChange={(e) => setFilterText(e.target.value)}
                                                                                    value={filterText}
                                                                                    /> */}
                                                        <div style={{ overflowY: "scroll", width: "100%", minHeight: "300px", maxHeight: "550px" }}>
                                                            {isLoading ? (
                                                                <p>Loading...</p>
                                                            ) : productList.length > 0 ? (
                                                                productList.map((product, index) => {
                                                                    return (
                                                                        <div
                                                                            key={index}
                                                                            style={{
                                                                                borderRadius: "8px",
                                                                                border: "1px solid #EFF3FA",
                                                                                background: "#FFFFFF",
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                padding: "10px",
                                                                                marginBottom: "10px",
                                                                            }}
                                                                        >
                                                                            <div style={{ display: "flex", gap: "15px" }}>
                                                                                <div style={{ border: "1px solid #EFF3FA", borderRadius: "6px" }}>
                                                                                    <img
                                                                                        alt=""
                                                                                        src={
                                                                                            product?.varProductBarCode === "" || product?.varProductBarCode === null
                                                                                                ? undefined
                                                                                                : `${process.env.REACT_APP_MEDIA_URL}products/${product?.varProductBarCode}.png`
                                                                                        }
                                                                                        style={{
                                                                                            objectFit: "scale-down",
                                                                                            borderRadius: "5px",
                                                                                            width: "160px",
                                                                                            height: "160px",
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                                                    <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                                                                        <p style={{ color: "#192252", fontSize: "14px", fontWeight: "600" }}>{product.varProductName}</p>
                                                                                        <Tooltip
                                                                                            style={{ paddingLeft: "5px" }}
                                                                                            title={
                                                                                                <>
                                                                                                    <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Product Description</p>
                                                                                                    <p style={{ fontSize: "12px", color: "#fff" }}>{product.varProductDescription}</p>
                                                                                                </>
                                                                                            }
                                                                                        >
                                                                                            <InfoOutlined style={{ fontSize: "18px", color: "#606D93" }} />
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                                                        {product?.varPromotionalMessage !== null && product?.varPromotionalMessage !== "" ? (
                                                                                            <Tooltip
                                                                                                style={{ paddingLeft: "5px" }}
                                                                                                title={
                                                                                                    <>
                                                                                                        <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Promotional Message</p>
                                                                                                        <p style={{ fontSize: "12px", color: "#fff" }}>{product?.varPromotionalMessage}</p>
                                                                                                    </>
                                                                                                }
                                                                                            >
                                                                                                <Campaign style={{ fontSize: "20px", color: "#139c4a" }} />
                                                                                            </Tooltip>
                                                                                        ) : null}

                                                                                        {product?.varMessageToEmployee !== null && product?.varMessageToEmployee !== "" ? (
                                                                                            <Tooltip
                                                                                                style={{ paddingLeft: "5px" }}
                                                                                                title={
                                                                                                    <>
                                                                                                        <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Message To Employee</p>
                                                                                                        <p style={{ fontSize: "12px", color: "#fff" }}>{product?.varMessageToEmployee}</p>
                                                                                                    </>
                                                                                                }
                                                                                            >
                                                                                                <Email style={{ fontSize: "20px", color: "#2871ca" }} />
                                                                                            </Tooltip>
                                                                                        ) : null}
                                                                                        {(product?.numLocationSpecificAdjustmentPercent !== null &&
                                                                                            product?.numLocationSpecificAdjustmentPercent !== "" &&
                                                                                            product?.numLocationSpecificAdjustmentPercent !== 0) ||
                                                                                        (product?.numLocationSpecificAdjustmentAmount !== null &&
                                                                                            product?.numLocationSpecificAdjustmentAmount !== "" &&
                                                                                            product?.numLocationSpecificAdjustmentAmount !== 0) ? (
                                                                                            <Tooltip
                                                                                                style={{ paddingLeft: "5px" }}
                                                                                                title={
                                                                                                    <>
                                                                                                        <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>
                                                                                                            Location Specific Adjustment
                                                                                                        </p>
                                                                                                        <p style={{ fontSize: "12px", color: "#fff" }}>
                                                                                                            Percentage: {product?.numLocationSpecificAdjustmentPercent}%
                                                                                                        </p>
                                                                                                        <p style={{ fontSize: "12px", color: "#fff" }}>
                                                                                                            Amount: {product?.numLocationSpecificAdjustmentAmount}$
                                                                                                        </p>
                                                                                                    </>
                                                                                                }
                                                                                            >
                                                                                                <Store style={{ fontSize: "20px", color: "#2871ca" }} />
                                                                                            </Tooltip>
                                                                                        ) : null}
                                                                                        {(product?.numDateSpecificAdjustmentPercent !== null &&
                                                                                            product?.numDateSpecificAdjustmentPercent !== "" &&
                                                                                            product?.numDateSpecificAdjustmentPercent !== 0) ||
                                                                                        (product?.numDateSpecificAdjustmentAmount !== null &&
                                                                                            product?.numDateSpecificAdjustmentAmount !== "" &&
                                                                                            product?.numDateSpecificAdjustmentAmount !== 0) ? (
                                                                                            <Tooltip
                                                                                                style={{ paddingLeft: "5px" }}
                                                                                                title={
                                                                                                    <>
                                                                                                        <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Date Specific Adjustment</p>
                                                                                                        <p style={{ fontSize: "12px", color: "#fff" }}>
                                                                                                            Percentage: {product?.numDateSpecificAdjustmentPercent}%
                                                                                                        </p>
                                                                                                        <p style={{ fontSize: "12px", color: "#fff" }}>
                                                                                                            Amount: {product?.numDateSpecificAdjustmentAmount}$
                                                                                                        </p>
                                                                                                    </>
                                                                                                }
                                                                                            >
                                                                                                <CalendarMonth style={{ fontSize: "19px", color: "#2871ca" }} />
                                                                                            </Tooltip>
                                                                                        ) : null}
                                                                                    </div>
                                                                                    <div style={{ color: "#606D93", fontSize: "12px", fontWeight: "400" }}>
                                                                                        {product.varSalesCommissionMessage} {product.varSeasonalPromotionMsg}
                                                                                    </div>
                                                                                    <div style={{ display: "flex", gap: "10px" }}>
                                                                                        <p style={{ color: "#606D93", fontSize: "12px", fontWeight: "400" }}>
                                                                                            Available Quantity: {product.TempAvailableQuantity}
                                                                                        </p>
                                                                                        <p style={{ color: "#606D93", fontSize: "12px", fontWeight: "400" }}>Unit Price: {product.UnityItemPrice}$</p>
                                                                                        {product.isAddedToCart === true ? (
                                                                                            <div style={{ display: "flex", gap: "2px", alignItems: "center" }}>
                                                                                                <ShoppingCart style={{ color: "#00a340", fontSize: "18px" }} />
                                                                                                <p style={{ color: "#00a340" }}>Added to cart</p>
                                                                                            </div>
                                                                                        ) : null}
                                                                                    </div>
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            justifyContent: "space-between",
                                                                                            alignItems: "center",
                                                                                            width: "fit-content",
                                                                                            gap: "10px",
                                                                                            marginTop: "15px",
                                                                                        }}
                                                                                    >
                                                                                        <IconButton
                                                                                            onClick={() => {
                                                                                                decrementQty(product.numLocationProductID, index)
                                                                                            }}
                                                                                        >
                                                                                            <RemoveCircleOutline className={styles.products_add_remove_button} />
                                                                                        </IconButton>
                                                                                        <p style={{ fontSize: "14px", fontWeight: "600", color: "#192252" }}>{product.SelectedQuantity}</p>
                                                                                        <IconButton
                                                                                            onClick={() => {
                                                                                                incrementQty(product.numLocationProductID, index)
                                                                                            }}
                                                                                        >
                                                                                            <AddCircleOutline className={styles.products_add_remove_button} />
                                                                                        </IconButton>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                                                                <p style={{ color: "#192252", fontSize: "18px", fontWeight: "700", textAlign: "right" }}>
                                                                                    $
                                                                                    {product.SelectedQuantity > 0
                                                                                        ? Math.round(product.numProductSalesPrice * product.SelectedQuantity * 100) / 100
                                                                                        : product.numProductSalesPrice}
                                                                                </p>
                                                                                <MUIButton
                                                                                    variant="outlined"
                                                                                    disabled={product.SelectedQuantity < 1 ? true : false}
                                                                                    children={
                                                                                        <ShoppingCart
                                                                                            style={
                                                                                                product.SelectedQuantity < 1
                                                                                                    ? { color: "#9097a9", padding: "10px" }
                                                                                                    : { color: "#2076ff", padding: "10px" }
                                                                                            }
                                                                                        />
                                                                                    }
                                                                                    onClick={() => {
                                                                                        addToCartNoPet(product.numLocationProductID, index)
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            ) : (
                                                                <p>No products found</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}

                                                {productTabIndexNoPet === 1 && (
                                                    <div style={{ width: "100%", marginTop: "20px" }}>
                                                        <div style={{ overflowY: "scroll", width: "100%", minHeight: "300px", maxHeight: "550px" }}>
                                                            {isLoading ? (
                                                                <p>Loading...</p>
                                                            ) : productCartNoPetList.length > 0 ? (
                                                                productCartNoPetList
                                                                    .filter((x) => x.bitActive === true)
                                                                    .map((product, index) => {
                                                                        return (
                                                                            <div
                                                                                key={index}
                                                                                style={{
                                                                                    borderRadius: "8px",
                                                                                    border: "1px solid #EFF3FA",
                                                                                    background: "#FFFFFF",
                                                                                    display: "flex",
                                                                                    justifyContent: "space-between",
                                                                                    padding: "10px",
                                                                                    marginBottom: "10px",
                                                                                }}
                                                                            >
                                                                                <div style={{ display: "flex", gap: "15px" }}>
                                                                                    <div style={{ border: "1px solid #EFF3FA", borderRadius: "6px" }}>
                                                                                        <img
                                                                                            alt=""
                                                                                            src={
                                                                                                product?.varProductBarCode === "" || product?.varProductBarCode === null
                                                                                                    ? undefined
                                                                                                    : `${process.env.REACT_APP_MEDIA_URL}products/${product?.varProductBarCode}.png`
                                                                                            }
                                                                                            style={{
                                                                                                objectFit: "scale-down",
                                                                                                borderRadius: "5px",
                                                                                                width: "160px",
                                                                                                height: "160px",
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                                                        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                                                                            <p style={{ color: "#192252", fontSize: "14px", fontWeight: "600" }}>{product.varProductName}</p>
                                                                                            <Tooltip
                                                                                                style={{ paddingLeft: "5px" }}
                                                                                                title={
                                                                                                    <>
                                                                                                        <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Product Description</p>
                                                                                                        <p style={{ fontSize: "12px", color: "#fff" }}>{product.varProductDescription}</p>
                                                                                                    </>
                                                                                                }
                                                                                            >
                                                                                                <InfoOutlined style={{ fontSize: "18px", color: "#606D93" }} />
                                                                                            </Tooltip>
                                                                                        </div>
                                                                                        <div style={{ color: "#606D93", fontSize: "12px", fontWeight: "400" }}>
                                                                                            {product.varSalesCommissionMessage} {product.varSeasonalPromotionMsg}
                                                                                        </div>
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "space-between",
                                                                                                alignItems: "center",
                                                                                                width: "fit-content",
                                                                                                gap: "10px",
                                                                                                marginTop: "15px",
                                                                                            }}
                                                                                        >
                                                                                            <IconButton
                                                                                                onClick={() => {
                                                                                                    decrementCartQtyNoPet(product.numLocationProductID, index)
                                                                                                }}
                                                                                            >
                                                                                                <RemoveCircleOutline className={styles.products_add_remove_button} />
                                                                                            </IconButton>
                                                                                            <p style={{ fontSize: "14px", fontWeight: "600", color: "#192252" }}>{product.numQuantity}</p>
                                                                                            <IconButton
                                                                                                onClick={() => {
                                                                                                    incrementCartQtyNoPet(product.numLocationProductID, index)
                                                                                                }}
                                                                                            >
                                                                                                <AddCircleOutline className={styles.products_add_remove_button} />
                                                                                            </IconButton>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                                                                    <p style={{ color: "#192252", fontSize: "18px", fontWeight: "700", textAlign: "right" }}>
                                                                                        ${Math.round(product.numProductSalesPrice * product.numQuantity * 100) / 100}
                                                                                    </p>
                                                                                    <MUIButton
                                                                                        variant="outlined"
                                                                                        children={<Delete style={{ color: "#2076ff", padding: "10px" }} />}
                                                                                        onClick={() => {
                                                                                            removeFromCartNoPet(product.numLocationProductID, index)
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                            ) : (
                                                                <p>Cart is empty</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </Box>
                                        </Box>
                                    </div>
                                    <div
                                        style={{
                                            textAlign: "end",
                                            width: "100%",
                                            backgroundColor: "#F9FAFB",
                                            padding: "1rem",
                                            marginBottom: "-15px",
                                            borderBottomLeftRadius: "12px",
                                            borderBottomRightRadius: "12px",
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                            <p style={{ color: "#606D93", fontSize: "22px", fontWeight: "600", margin: "0" }}>Total</p>
                                            <p style={{ color: "#192252", fontSize: "22px", fontWeight: "600", margin: "0" }}>${Math.round(productCartAmount * 100) / 100}</p>
                                        </div>

                                        <Button variant="contained" color="primary" loading={isLoading} onClick={() => handleProductSaveNoPet()} className={styles.buttonText}>
                                            Save & Continue
                                        </Button>
                                    </div>
                                </div>
                            </Modal>

                            <Modal open={isOpenAddNotes}>
                                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem" }}>
                                    <div style={{ position: "absolute", bottom: "63%", zIndex: "9999", right: "40%" }}>
                                        <CloseButton onClick={() => setIsOpenAddNotes(false)} />
                                    </div>

                                    <div style={{ backgroundColor: "#fff", width: "100%" }}>
                                        <div style={{ display: "flex" }}>
                                            <div style={{ height: "100%", width: "100%" }}>
                                                <div style={{ height: "100%", width: "100%" }}>
                                                    <MaterialTabs style={{ height: "100%", width: "100%" }} value={0}>
                                                        <Tab style={{ width: "200px" }} label="Add note" />
                                                    </MaterialTabs>
                                                </div>

                                                <TabPanel value={0} index={0}>
                                                    <div style={{ width: "390px", marginBottom: "1rem", marginTop: "1rem" }}>
                                                        <Input
                                                            label={"Related to"}
                                                            items={[
                                                                {
                                                                    text: "Pinky",
                                                                    value: "1",
                                                                },
                                                                {
                                                                    text: "Doggy",
                                                                    value: "2",
                                                                },
                                                            ]}
                                                        />
                                                    </div>

                                                    <div style={{ width: "100%", marginBottom: "1rem" }}>
                                                        <Input
                                                            label={"Send to"}
                                                            items={[
                                                                {
                                                                    text: "All",
                                                                    value: "1",
                                                                },
                                                                {
                                                                    text: "Groomers",
                                                                    value: "2",
                                                                },
                                                                {
                                                                    text: "Bathers",
                                                                    value: "3",
                                                                },
                                                                {
                                                                    text: "Call center",
                                                                    value: "4",
                                                                },
                                                            ]}
                                                        />
                                                    </div>

                                                    <TextField multiline={true} placeholder="Message" style={{ width: "100%", height: "" }} onChange={(e) => {}} variant={"filled"} />
                                                </TabPanel>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ textAlign: "end", width: "100%", backgroundColor: "#EFF5FF", padding: "1rem" }}>
                                        <Button variant="outlined" color="primary" onClick={() => null} className={styles.buttonText}>
                                            Add Note
                                        </Button>
                                    </div>
                                </div>
                            </Modal>

                            <Modal open={isOpenInvoiceModal}>
                                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem" }}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                        <p style={{ color: "#002867", fontSize: "18px", fontWeight: "600", width: "100%" }}>Create Invoice</p>
                                        <CloseButton onClick={() => setIsOpenInvoiceModal(false)} />
                                    </div>

                                    <div style={{ backgroundColor: "#fff", width: "600px", display: "flex", flexDirection: "column", gap: "5px", maxHeight: "600px" }}>
                                        <div>
                                            <p style={{ color: "#192252", fontSize: "14px", fontWeight: "600" }}>Services</p>
                                            <div style={{ overflowY: "scroll", width: "100%", minHeight: "100px", maxHeight: "250px" }}>
                                                {isLoading ? (
                                                    <p>Loading...</p>
                                                ) : invoiceServiceList.length > 0 ? (
                                                    invoiceServiceList
                                                        .filter((x) => x.ServiceType !== "Product")
                                                        .map((service, index) => {
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    style={{
                                                                        borderRadius: "8px",
                                                                        border: "1px solid #EFF3FA",
                                                                        background: "#FFFFFF",
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                        padding: "10px",
                                                                        marginBottom: "10px",
                                                                    }}
                                                                >
                                                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center", gap: "10px" }}>
                                                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                                                            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                                                                <p style={{ color: "#192252", fontSize: "14px", fontWeight: "600" }}>{service.varServiceItemName}</p>
                                                                                <Tooltip
                                                                                    style={{ paddingLeft: "5px" }}
                                                                                    title={
                                                                                        <>
                                                                                            <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Product Description</p>
                                                                                            <p style={{ fontSize: "12px", color: "#fff" }}>{service.varServiceItemDescription}</p>
                                                                                        </>
                                                                                    }
                                                                                >
                                                                                    <InfoOutlined style={{ fontSize: "18px", color: "#606D93" }} />
                                                                                </Tooltip>
                                                                            </div>
                                                                            <div style={{ color: "#606D93", fontSize: "12px", fontWeight: "400" }}>{service.varSalesCommissionMessage}</div>
                                                                            <div style={{ color: "#606D93", fontSize: "12px", fontWeight: "400" }}>Service Type: {service.ServiceType}</div>
                                                                        </div>
                                                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                                                            <p style={{ color: "#192252", fontSize: "18px", fontWeight: "700", textAlign: "right" }}>
                                                                                ${Math.round(service.numServiceItemSalesPrice * 100) / 100}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                ) : (
                                                    <p>No services selected</p>
                                                )}
                                            </div>
                                        </div>
                                        <div>
                                            <p style={{ color: "#192252", fontSize: "14px", fontWeight: "600" }}>Products</p>
                                            <div style={{ overflowY: "scroll", width: "100%", minHeight: "100px", maxHeight: "250px" }}>
                                                {isLoading ? (
                                                    <p>Loading...</p>
                                                ) : invoiceProductList.length > 0 ? (
                                                    invoiceProductList
                                                        .filter((x) => x.bitActive === true)
                                                        .map((product, index) => {
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    style={{
                                                                        borderRadius: "8px",
                                                                        border: "1px solid #EFF3FA",
                                                                        background: "#FFFFFF",
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                        padding: "10px",
                                                                        marginBottom: "10px",
                                                                    }}
                                                                >
                                                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center", gap: "10px" }}>
                                                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                                                            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                                                                <p style={{ color: "#192252", fontSize: "14px", fontWeight: "600" }}>{product.varProductName}</p>
                                                                                <Tooltip
                                                                                    style={{ paddingLeft: "5px" }}
                                                                                    title={
                                                                                        <>
                                                                                            <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Product Description</p>
                                                                                            <p style={{ fontSize: "12px", color: "#fff" }}>{product.varProductDescription}</p>
                                                                                        </>
                                                                                    }
                                                                                >
                                                                                    <InfoOutlined style={{ fontSize: "18px", color: "#606D93" }} />
                                                                                </Tooltip>
                                                                            </div>
                                                                            <div style={{ color: "#606D93", fontSize: "12px", fontWeight: "400" }}>
                                                                                {product.varSalesCommissionMessage} {product.varSeasonalPromotionMsg}
                                                                            </div>
                                                                            <div style={{ color: "#606D93", fontSize: "12px", fontWeight: "400" }}>Quantity: {product.numQuantity}</div>
                                                                        </div>
                                                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                                                            <p style={{ color: "#192252", fontSize: "18px", fontWeight: "700", textAlign: "right" }}>
                                                                                ${Math.round(product.numProductSalesPrice * product.numQuantity * 100) / 100}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                ) : (
                                                    <p>No products selected</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ textAlign: "end", width: "100%", backgroundColor: "#EFF5FF", padding: "1rem" }}>
                                        <Button variant="outlined" color="primary" onClick={() => createInvoice()} className={styles.buttonText}>
                                            Create Invoice
                                        </Button>
                                    </div>
                                </div>
                            </Modal>
                        </div>
                    ) : props?.type === "bookingBlank" ? (
                        <div>
                            <div style={{ width: "100%", borderRadius: "5px" }}>
                                <div style={{ padding: "0 2rem", backgroundColor: "#fff" }}>
                                    <MaterialTabs value={0}>
                                        <ValidatePermission allowedModules={["02.01 - Schedule / New Booking"]}>
                                            <Tab
                                                disabled={true}
                                                value={0}
                                                style={{ textTransform: "capitalize", padding: 0, margin: "0 1rem", fontWeight: 600, color: "#b3b3b3" }}
                                                label="New Booking"
                                            />
                                        </ValidatePermission>
                                        <ValidatePermission allowedModules={["02.02 - Schedule / Customer Profile"]}>
                                            <Tab
                                                disabled={true}
                                                value={1}
                                                style={{ textTransform: "capitalize", padding: 0, margin: "0 1rem", fontWeight: 600, color: "#b3b3b3" }}
                                                label="Customer Profile"
                                            />
                                        </ValidatePermission>
                                        <ValidatePermission allowedModules={["02.03 - Schedule / Pet Profile"]}>
                                            <Tab
                                                disabled={true}
                                                value={2}
                                                style={{ textTransform: "capitalize", padding: 0, margin: "0 1rem", fontWeight: 600, color: "#b3b3b3" }}
                                                label="Pet Profile"
                                            />
                                        </ValidatePermission>
                                    </MaterialTabs>
                                </div>

                                <ValidatePermission allowedModules={["02.01 - Schedule / New Booking"]}>
                                    <TabPanel value={value} index={0}>
                                        <div style={{ padding: "1rem" }}>
                                            <div style={{ display: "flex", gap: "1rem" }}>
                                                {props.petList.map((pet, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            style={{ borderRadius: "8px", padding: "0 .6rem ", boxShadow: "0px 2px 8px rgba(218, 218, 218, 0.1), 0px 1px 4px rgba(97, 97, 97, 0.1)" }}
                                                        >
                                                            <div style={{ display: "flex", alignItems: "center", gap: "1rem", padding: "0.5rem 0" }}>
                                                                <div style={{ padding: "0.5rem 0.8rem", backgroundColor: "#EFF5FF", borderRadius: "5px", fontWeight: "600" }}>{pet?.size}</div>
                                                                <div>
                                                                    <p style={{ margin: "0", fontWeight: "600", textTransform: "capitalize" }}>{Truncate(pet?.name, 10)}</p>
                                                                    <p className={styles.petMiniCardPBreed}>{Truncate(pet?.breed, 10)}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>

                                            <div style={{ overflowX: "scroll", width: "100%", display: "flex", gap: "15px", marginTop: "20px" }}>
                                                {pastAppointmentList.length > 0 ? (
                                                    pastAppointmentList.map((app, index) => {
                                                        return (
                                                            <div
                                                                className={selectedPastAppointment === index ? styles.pre_app_active : styles.pre_app}
                                                                onClick={() => {
                                                                    selectPrevAppointment(index)
                                                                }}
                                                            >
                                                                <div style={{ width: "250px" }}>
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            width: "100%",
                                                                            justifyContent: "space-between",
                                                                            alignItems: "center",
                                                                            backgroundColor: app?.IsPreferred ? "#fffee6" : undefined,
                                                                            borderRadius: "8px",
                                                                            border: app?.IsPreferred ? "1px solid #fffee6" : undefined,
                                                                            padding: "0px 1px 0px 1px",
                                                                        }}
                                                                    >
                                                                        <div style={{ display: "flex", gap: "2px", alignItems: "center" }}>
                                                                            <p style={{ fontWeight: 600, fontSize: "16px", color: "#1976d2" }}>#{app?.numAppointmentID}</p>
                                                                            {app?.IsPreferred ? (
                                                                                <>
                                                                                    <Verified style={{ color: "#edb80b" }} />
                                                                                    <p style={{ color: "#d3a71a", fontWeight: "600" }}>Customer's Pick</p>
                                                                                </>
                                                                            ) : null}
                                                                        </div>
                                                                        <span style={{ color: "black", fontSize: "14px", fontWeight: "500" }}>({formatToLocal(app?.dtApptStartTime)})</span>
                                                                    </div>

                                                                    <p style={{ margin: "0 ", fontSize: "13px" }}>
                                                                        <b>Location: </b>
                                                                        {Truncate(app?.varLocationName, 22)}
                                                                    </p>
                                                                    <p style={{ margin: "0 ", fontSize: "13px" }}>
                                                                        <b>Groomer: </b>
                                                                        {app.ServicesList[0]?.varEmployeeFirstName + " " + app.ServicesList[0]?.varEmployeeLastName}
                                                                    </p>
                                                                    {app.ServicesList[0]?.DealerFirstName !== null ? (
                                                                        <p style={{ margin: "0 ", fontSize: "13px" }}>
                                                                            <b>Bather: </b>
                                                                            {app.ServicesList[0]?.DealerFirstName + " " + app.ServicesList[0]?.DealerLastName}
                                                                        </p>
                                                                    ) : null}
                                                                    <div style={{ overflowX: "hidden", overflowY: "hidden", width: "calc(100%)", display: "flex", gap: "5px", marginTop: "10px" }}>
                                                                        {app.ServicesList.map((service, appIndex) => (
                                                                            <div key={appIndex} style={{ cursor: "pointer" }}>
                                                                                <div
                                                                                    style={{
                                                                                        backgroundColor: "#DCFCE7",
                                                                                        border: "1px solid #86EFAC",
                                                                                        borderRadius: "16px",
                                                                                        color: "#14532D",
                                                                                        fontSize: "13px",
                                                                                        fontWeight: "500",
                                                                                        display: "flex",
                                                                                        justifyContent: "space-between",
                                                                                        alignItems: "center",
                                                                                        padding: "0 10px 0 10px",
                                                                                        gap: ".2rem",
                                                                                        width: "max-content",
                                                                                    }}
                                                                                >
                                                                                    {Truncate(service?.varServiceItemName, 20)}
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>

                                                                    <div style={{ overflowX: "hidden", overflowY: "hidden", width: "calc(100%)", display: "flex", gap: "5px", marginTop: "5px" }}>
                                                                        {app.ProductsList.map((product, proIndex) => (
                                                                            <div key={proIndex} style={{ cursor: "pointer" }}>
                                                                                <div
                                                                                    style={{
                                                                                        backgroundColor: "#DCFCE7",
                                                                                        border: "1px solid #86EFAC",
                                                                                        borderRadius: "16px",
                                                                                        color: "#14532D",
                                                                                        fontSize: "13px",
                                                                                        fontWeight: "500",
                                                                                        display: "flex",
                                                                                        justifyContent: "space-between",
                                                                                        alignItems: "center",
                                                                                        padding: "0 10px 0 10px",
                                                                                        gap: ".2rem",
                                                                                        width: "max-content",
                                                                                    }}
                                                                                >
                                                                                    {Truncate(product?.varProductName, 15)}{" "}
                                                                                    <span style={{ margin: "0", borderRight: "1px solid #86efac" }}>&nbsp;</span> {product?.numQuantity}
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                ) : props.petList.length > 0 ? (
                                                    <p style={{ color: "#777E90", fontWeight: "400", fontSize: "13px" }}>No previous appointments</p>
                                                ) : null}
                                            </div>

                                            <p style={{ color: "#002867", marginBottom: "5px", fontSize: "14px", fontWeight: "600" }}>Appointment Type</p>
                                            <div>
                                                <div style={{ boxShadow: "0px 4px 0px #EDF3F9", width: "100%", borderRadius: ".5rem", border: "1px solid #F3F4F6" }}>
                                                    <div style={{ display: "flex", alignItems: "center", padding: "1rem", gap: "1rem" }}>
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            <Checkbox disabled={true} checked={boarding} color="primary" onChange={(e) => setBoarding(!boarding)} />
                                                            <p>Boarding</p>
                                                        </div>

                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            <Checkbox disabled={true} checked={daycare} color="primary" onChange={(e) => setDaycare(!daycare)} />
                                                            <p>Daycare</p>
                                                        </div>

                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            <Checkbox disabled={true} checked={grooming} color="primary" onChange={(e) => setGrooming(!grooming)} />
                                                            <p>Grooming</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <h3 style={{ color: "#002867", marginBottom: "5px" }}>Location</h3>
                                            <div>
                                                <div style={{ boxShadow: "0px 4px 0px #EDF3F9", width: "100%", borderRadius: ".5rem", border: "1px solid #F3F4F6" }}>
                                                    <div style={{ padding: "1rem" }}>
                                                        <div style={{ display: "flex", gap: "2rem", alignItems: "center" }}>
                                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                                <Checkbox disabled={true} color="primary" />
                                                                <p>Edit</p>
                                                            </div>
                                                            <div style={{ width: "100%" }}>
                                                                <Input
                                                                    disabled={true}
                                                                    label={"Customer location"}
                                                                    items={[
                                                                        {
                                                                            text: "Old Westbury (NY) 11545",
                                                                            value: "1",
                                                                        },
                                                                        {
                                                                            text: "Old Westbury (NY) 11546",
                                                                            value: "2",
                                                                        },
                                                                    ]}
                                                                />
                                                            </div>
                                                            <div style={{ width: "100%" }}>
                                                                <Input
                                                                    disabled={true}
                                                                    label={"Salons"}
                                                                    items={[
                                                                        {
                                                                            text: "Lucky Paws Pet Grooming Syosset",
                                                                            value: "1",
                                                                        },
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                </ValidatePermission>
                            </div>
                        </div>
                    ) : props?.type === "staff" ? (
                        <div>
                            <div style={{ width: "100%", borderRadius: "5px" }}>
                                <div style={{ padding: "0 2rem", backgroundColor: "#fff", border: "1px solid #F3F4F6 !important", height: "45px !important" }}>
                                    <MaterialTabs value={value} onChange={handleChange}>
                                        <Tab style={{ textTransform: "capitalize", padding: 0, margin: "0 1rem", fontWeight: 600, color: "#2076FF" }} label="Employee Profile" />
                                    </MaterialTabs>
                                </div>

                                <TabPanel value={value} index={0}>
                                    {isLoading ? <p>Loading...</p> : list != undefined ? <DetailedView type={"staff"} labels={newHeaders} data={list} /> : null}
                                </TabPanel>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    )
}
